import { Box, Grid, Stack, Typography } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Tag from "../Tag/Tag";
import InputAmbiental from "@jumbo/components/InputAmbiental";

const DetalhesContrato = ({
  contrato,
  modalAlert,
  id,
  handleAtualizarDataAprovacaoContrato,
  dataAprovacao,
}) => {
  const [dataAprovacaoContrato, setDataAprovacaoContrato] = useState("");

  useEffect(() => {
    if (dataAprovacao) {
      setDataAprovacaoContrato(moment.utc(dataAprovacao).format("YYYY-MM-DD"));
    }
  }, [contrato, dataAprovacao]);

  return (
    <>
      <Grid container spacing={1} ml={1} pr={2}>
        <Grid
          item
          xs={12}
          container
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography
            fontWeight={600}
            fontSize={28}
            color={"#212529"}
            marginBottom={4}
          >
            Dados do Contrato
          </Typography>
        </Grid>

        <Grid container item xs={12} spacing={2}>
          {/* Data de Criação */}
          <Grid item xs={6}>
            <Stack direction="row" gap={1}>
              <Typography fontWeight={600} fontSize={16}>
                Data de Criação:
              </Typography>
              <Typography fontSize={16}>
                {moment.utc(contrato?.createdAt).format("DD/MM/YYYY")}
              </Typography>
            </Stack>
          </Grid>

          {/* Elaboradores de orçamento */}
          <Grid item xs={6}>
            <Stack direction="row" gap={1}>
              <Typography fontWeight={600} fontSize={16}>
                Elaboradores de Orçamento:
              </Typography>
              <Typography fontSize={16}>
                {contrato?.orientadores?.length > 0
                  ? contrato.orientadores
                      .map((orientador) => orientador?.usuario?.name)
                      .join(", ")
                  : "N/A"}
              </Typography>
            </Stack>
          </Grid>
        </Grid>

        <Grid container item xs={12} spacing={2}>
          {/* Última Modificação */}
          <Grid item xs={6}>
            <Stack direction="row" gap={1}>
              <Typography fontWeight={600} fontSize={16}>
                Última Modificação:
              </Typography>
              <Typography fontSize={16}>
                {moment.utc(contrato?.updated).format("DD/MM/YYYY")}
              </Typography>
            </Stack>
          </Grid>

          {/* Orientadores */}
          <Grid item xs={6}>
            <Stack direction="row" gap={1}>
              <Typography fontWeight={600} fontSize={16}>
                Orientadores:
              </Typography>
              <Typography fontSize={16}>
                {contrato?.elaboradores_orcamento?.length > 0
                  ? contrato.elaboradores_orcamento
                      .map((elaborador) => elaborador?.usuario?.name)
                      .join(", ")
                  : "N/A"}
              </Typography>
            </Stack>
          </Grid>
        </Grid>

        {/* Colaborador que criou */}
        <Grid item xs={12}>
          <Stack direction="row" gap={1}>
            <Typography fontWeight={600} fontSize={16}>
              Colaborador que Criou:
            </Typography>
            <Typography fontSize={16}>
              {contrato?.colaborador_que_criou?.name || "N/A"}
            </Typography>
          </Stack>
        </Grid>

        {/* Tipo de contrato */}
        <Grid item xs={12}>
          <Stack direction="row" gap={1}>
            <Typography fontWeight={600} fontSize={16}>
              Tipo de Contrato:
            </Typography>
            {contrato.tipocontratocontrato?.map((tipo, index) => (
              <Box key={index}>
                <Typography fontSize={16} fontWeight={600}>
                  {tipo?.tipos_contratos?.descricao}
                </Typography>
              </Box>
            ))}
          </Stack>
        </Grid>

        {/* Status Orçamentário */}
        <Grid item xs={6} marginTop={4}>
          <Stack direction="row" gap={1}>
            <Typography fontWeight={600} fontSize={20}>
              Status Orçamentário:
            </Typography>
            <Tag
              label={contrato?.status_orcamentario?.descricao}
              sx={{ marginBottom: 2 }}
              color={"#868686"}
              fontWeight={400}
            />
          </Stack>
        </Grid>
        {contrato?.data_aprovacao ? (
          <Grid item xs={6} marginTop={4}>
            <Stack direction="row" gap={1} justifyContent={"flex-start"}>
              <Typography fontWeight={600} fontSize={20}>
                Data de Aprovação:
              </Typography>
              <InputAmbiental
                type="date"
                value={dataAprovacaoContrato}
                onChange={(event) => {
                  handleAtualizarDataAprovacaoContrato(event.target.value);
                }}
                width={300}
                fullWidth={false}
                marginTop={0}
              />
            </Stack>
          </Grid>
        ) : null}
      </Grid>
    </>
  );
};

export default DetalhesContrato;
