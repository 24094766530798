import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { useJumboTheme } from "@jumbo/hooks";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  Select,
  TextareaAutosize,
  FormLabel,
  MenuItem,
  InputLabel,
  FormControl,
  FormGroup,
  RadioGroup,
  FormControlLabel,
  Radio,
  Avatar,
  IconButton,
  Breadcrumbs,
  Alert,
  Checkbox,
  Tooltip,
} from "@mui/material";
import HeaderBreadcrumbs from "app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs";
import ApiService from "app/services/config";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import ReactInputMask from "react-input-mask";
import * as yup from "yup";
import { PermissionContext } from "app/contexts/PermissionContext";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import axios from "axios";
import { consultarCEP } from "app/utils/consultarCEP";
import { tiposArquivos } from "app/utils/tiposArquivos";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import BadgeNomeCliente from "@jumbo/components/BadgeNomeCliente/BadgeNomeCliente";
import PageHeader from "app/layouts/shared/headers/PageHeader/PageHeader";
import DescriptionIcon from "@mui/icons-material/Description";
import InputAmbiental from "@jumbo/components/InputAmbiental";
import { ESTADOS_BRASIL } from "app/utils/estados";
import SelectAmbiental from "@jumbo/components/SelectAmbiental";
import ActionButton from "app/components/ActionButton/ActionButton";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ModalUploadDocumentoImovel from "app/components/ModalUploadDocumentoImovel";
import BotaoVoltarBreadCrumbs from "app/components/BotaoVoltarBreadCrumbs";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import Tag from "app/components/Tag/Tag";
import BackNavigation from "app/components/BackNavigation/BackNavigation";

const linkStyle = {
  color: "#302D2E",
  fontSize: 20,
  textDecoration: "none",
  "&:visited": {
    color: "#302D2E",
  },
};

const validationSchema = yup.object().shape({
  descricao: yup
    .string("Insira o nome do cliente")
    .required("Preenchimento obrigatório"),
  cep: yup
    .string()
    .nullable()
    .test("valid-cep-length", "CEP inválido", (value) => {
      if (!value) return true;
      return value.replace(/\D/g, "").length === 8;
    })
    .typeError("CEP Inválido"),
  documento: yup
    .string()
    .max(18)
    .nullable()
    .typeError("O documento deve ter no máximo 18 caracteres"),
  cadastro: yup.string().nullable(),
  tipo_documento: yup.string().required("Campo obrigatório").typeError("Campo obrigatório"),
  cpf: yup
    .string()
    .nullable()
    .when("tipo_documento", {
      is: "fisica",
      then: yup
        .string()
        .required("CPF é obrigatório para pessoa física")
        .test("valid-cpf-length", "CPF inválido", (value) => {
          if (!value) return true;
          return value.replace(/\D/g, "").length === 11;
        }),
    })
    .typeError("CPF Inválido"),
  cnpj: yup
    .string()
    .nullable()
    .when("tipo_documento", {
      is: "juridica",
      then: yup
        .string()
        .required("CNPJ é obrigatório para pessoa jurídica")
        .test("valid-cnpj-length", "CNPJ inválido", (value) => {
          if (!value) return true;
          return value.replace(/\D/g, "").length === 14;
        }),
    })
    .typeError("CNPJ Inválido"),
});

const validationSchemaEdit = yup.object().shape({
  descricao: yup
    .string("Insira o nome do imóvel")
    .required("Preenchimento obrigatório"),
  cep: yup
    .string()
    .nullable()
    .test("valid-cep-length", "CEP inválido", (value) => {
      if (!value) return true;
      return value.replace(/\D/g, "").length === 8;
    })
    .typeError("CEP Inválido"),
  documento: yup
    .string()
    .max("18")
    .nullable()
    .typeError("O documento deve ter no máximo 18 caracteres"),
  cadastro: yup.string().nullable(),
  tipo_documento: yup.string().required("Campo obrigatório").typeError("Campo obrigatório"),
  cpf: yup
    .string()
    .nullable()
    .when("tipo_documento", {
      is: "fisica",
      then: yup
        .string()
        .required("CPF é obrigatório para pessoa física")
        .test("valid-cpf-length", "CPF inválido", (value) => {
          if (!value) return true;
          return value.replace(/\D/g, "").length === 11;
        }),
    })
    .typeError("CPF Inválido"),
  cnpj: yup
    .string()
    .nullable()
    .when("tipo_documento", {
      is: "juridica",
      then: yup
        .string()
        .required("CNPJ é obrigatório para pessoa jurídica")
        .test("valid-cnpj-length", "CNPJ inválido", (value) => {
          if (!value) return true;
          return value.replace(/\D/g, "").length === 14;
        }),
    })
    .typeError("CNPJ Inválido"),
});

const ESTADOS = {
  AC: "Acre",
  AL: "Alagoas",
  AP: "Amapá",
  AM: "Amazonas",
  BA: "Bahia",
  CE: "Ceará",
  DF: "Distrito Federal",
  ES: "Espírito Santo",
  GO: "Goiás",
  MA: "Maranhão",
  MT: "Mato Grosso",
  MS: "Mato Grosso do Sul",
  MG: "Minas Gerais",
  PA: "Pará",
  PB: "Paraíba",
  PR: "Paraná",
  PE: "Pernambuco",
  PI: "Piauí",
  RJ: "Rio de Janeiro",
  RN: "Rio Grande do Norte",
  RS: "Rio Grande do Sul",
  RO: "Rondônia",
  RR: "Roraima",
  SC: "Santa Catarina",
  SP: "São Paulo",
  SE: "Sergipe",
  TO: "Tocantins",
};

const ImovelClienteForm = () => {
  const initialValues = {
    descricao: "",
    cep: "",
    bairro: "",
    logradouro: "",
    cidade: "",
    numero: "",
    complemento: "",
    uf: "",
    documento: "",
    cadastro: "",
    tipo_documento: "fisica",
    cpf: "",
    cnpj: "",
    descricao_documento: "",
  };
  const { id, clienteId, unidadeId } = useParams();
  const [isDeleteMode, setIsDeleteMode] = useState(false);
  const [imovel, setImovel] = useState(initialValues);
  const [breadCrumbsInfo, setBreadCrumbsInfo] = useState(null);
  const [formattedCEP, setFormatedCEP] = useState("");
  const Swal = useSwalWrapper();
  const navigate = useNavigate();
  const [estados, setEstados] = useState([]);
  const [municipios, setMunicipios] = useState([]);
  const formikRef = useRef();
  const [formattedCPF, setFormattedCPF] = useState("");
  const [formattedCNPJ, setFormattedCNPJ] = useState("");
  const [isDocumentoAnexado, setIsDocumentoAnexado] = useState(false);
  const [documentoSelecionado, setDocumentosSelecionado] = useState(null);
  const [isModalUploadDocumentoAberto, setIsModalUploadDocumentoAberto] =
    useState(false);

  const location = useLocation();
  const { theme } = useJumboTheme();
  const lg = useMediaQuery(theme.breakpoints.down("lg"));

  const { hasPermission } = useContext(PermissionContext);
  if (!hasPermission("Clientes", "create")) {
    navigate("/app/listar-clientes");
  }

  const handleOpenModal = () => {
    setIsModalUploadDocumentoAberto(!isModalUploadDocumentoAberto);
  };

  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            zIndex: 5,
            top: 96,
            minHeight: "auto",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: "flex-start",
        },
      },
    }),
    [theme]
  );

  const toast = (variant, message, type = false) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true,
      closeButtonAriaLabel: "Fechar",
      timer: 3000,
    });
  };

  const modalAlert = (id) => {
    Swal.fire({
      title: "Tem certeza que deseja apagar?",
      text: "Não será póssível reverter a ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteImovel(id);
      }
    });
  };

  const deleteImovel = useCallback(async (id) => {
    try {
      ApiService.delete(`/imoveis/${id}`)
        .then((response) => {
          toast("success", "Removido com sucesso");
          navigate(`/app/cliente/${clienteId}/unidade/${unidadeId}/imoveis`);
        })
        .catch((error) => {
          const message = error.response.data.message;
          toast("error", message);
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getImovel = useCallback(async () => {
    try {
      ApiService.get(`/imoveis/${id}`)
        .then((response) => {
          if (response.status === 200) {
            setImovel({
              bairro: response.data?.bairro,
              cep: response.data?.cep,
              cidade: response.data?.cidade,
              complemento: response.data?.complemento,
              descricao: response.data?.descricao,
              logradouro: response.data?.logradouro,
              numero: response.data?.numero,
              uf: response.data?.uf,
              documento: response.data?.documento,
              documento_unidade: response.data?.documento_unidade,
              cadastro: response.data?.cadastro,
              tipo_documento: response.data?.tipo_documento,
              descricao_documento: response.data?.descricao_documento,
            });

            response.data?.tipo_documento === "fisica"
              ? setFormattedCPF(response.data?.documento)
              : setFormattedCNPJ(response.data?.documento);
            setFormatedCEP(response.data?.cep);

            if (response.data?.documento_unidade !== "") {
              setIsDocumentoAnexado(true);
              setDocumentosSelecionado({
                name: response.data?.documento_unidade,
              });
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    if (id) {
      getImovel();
    }
    if (location && String(location.pathname).includes("deletar")) {
      setIsDeleteMode(true);
    }
  }, [id]);

  const getEstados = useCallback(async () => {
    try {
      await axios
        .get("https://servicodados.ibge.gov.br/api/v1/localidades/estados")
        .then((response) => {
          if (response.status === 200) {
            setEstados(response.data);
          }
        });
    } catch (error) {
      console.log(error.message);
    }
  }, []);

  const getMunicipios = useCallback(
    async (sigla) => {
      await axios
        .get(
          `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${sigla}/municipios
  `
        )
        .then((response) => {
          if (response.status === 200) {
            setMunicipios(response.data);
          }
        });
    },
    [estados]
  );

  const handleChangeCEP = async (cep) => {
    formikRef.current.setFieldValue("cep", cep);
    if (cep.length === 9) {
      try {
        const cepConsultado = await consultarCEP(cep);
        if (cepConsultado) {
          formikRef.current.setFieldValue(
            "uf",
            cepConsultado.dados.stateShortname
          );
          await getMunicipios(cepConsultado.dados.stateShortname);
          formikRef.current.setFieldValue("cidade", cepConsultado.dados.city);
          formikRef.current.setFieldValue(
            "logradouro",
            cepConsultado.dados.street
          );
          formikRef.current.setFieldValue(
            "bairro",
            cepConsultado.dados.district
          );
        }
        setFormatedCEP(
          String(cep)
            .slice(0, 5)
            .concat(`-${String(cep).slice(5, 9)}`)
        );
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleDocumentoAnexado = (event) => {
    const documento = event.target.files[0];
    if (documento) {
      setDocumentosSelecionado(documento);
      setIsDocumentoAnexado(true);
    }
  };

  const handleSubmit = async (
    values,
    setSubmitting,
    resetForm,
    setFieldValue
  ) => {
    if (values.descricao === "") {
      toast("warning", "O campo descrição é obrigatório!");
      return;
    }
    if (Object.entries(formikRef.current.errors)?.length > 0) {
      toast("warning", "Corrija os erros no formulário!");
      return;
    }
    const allValuesEdit = {
      ...values,
    };
    delete allValuesEdit.id;

    const chavesValorValuesEdit = Object.entries(allValuesEdit);

    const formData = new FormData();

    for (const values of chavesValorValuesEdit) {
      formData.append(values[0], values[1]);
    }

    formData.append("clienteId", Number(clienteId));
    formData.append("unidadeId", Number(unidadeId));
    // formData.append("documento", String(values.documento));

    if (documentoSelecionado && documentoSelecionado.type) {
      console.log("Documento Selecionado:", documentoSelecionado);
      formData.append("documento_unidade", documentoSelecionado);
    }

    if (id) {
      ApiService.put(`/imoveis/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          toast("success", "Atualizado com sucesso!");
          if (response.status === 200) {
            navigate(`/app/cliente/${clienteId}/unidade/${unidadeId}/imoveis`);
          }
        })
        .catch((error) => {
          let message = error.response.data.message;
          toast("error", message);

          if (error.response.data) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } else {
      await ApiService.post("/imoveis", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          toast("success", "Criado com sucesso");
          resetForm();
          if (response.status === 201) {
            navigate(`/app/cliente/${clienteId}/unidade/${unidadeId}/imoveis`);
          }
        })
        .catch((error) => {
          let message = error.response.data.message;
          toast("error", message);

          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    }
    setSubmitting(false);
  };

  const getBreadCrumbsInfo = async () => {
    try {
      return await ApiService.get(
        `/clientes/breadcrumbs?clienteId=${clienteId}&unidadeId=${unidadeId}`
      ).then((response) => {
        if (response.status === 200) {
          setBreadCrumbsInfo(response.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBreadCrumbsInfo();
    getEstados();
  }, [unidadeId]);

  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      <JumboContentLayout layoutOptions={layoutOptions}>
        {lg && (
          <Stack spacing={2} direction={"row"} sx={{ mb: 3, mt: -2 }}></Stack>
        )}

        {breadCrumbsInfo ? (
          <Box>
            <BackNavigation url={`/app/cliente/unidades/${clienteId}`} />
            <Tag
              label={breadCrumbsInfo?.unidades[0]?.nomeUnidade}
              sx={{ marginBottom: 3 }}
            />
          </Box>
        ) : null}
        <PageHeader
          title={id ? `Editar imóvel` : "Imóvel > Novo imóvel"}
          icon={
            <DescriptionIcon
              sx={{ color: "#4E5526", marginRight: 1, fontSize: 40 }}
            />
          }
        />
        {isDeleteMode && (
          <Stack width={"100%"} p={1}>
            <Alert severity="error">
              Você está prestes a excluir o imóvel abaixo:
            </Alert>
          </Stack>
        )}
        <Box>
          <Formik
            innerRef={formikRef}
            initialValues={imovel}
            validationSchema={id ? validationSchemaEdit : validationSchema}
            enableReinitialize
            validateOnChange={true}
            validateOnBlur
            onSubmit={handleSubmit}
          >
            {({
              values,
              isSubmitting,
              handleChange,
              updateInputValue,
              setFieldValue,
              setSubmitting,
              resetForm,
              errors,
            }) => (
              <Form style={{ width: "100%" }} noValidate autoComplete="off">
                <Grid container width={"100%"}>
                  <Grid
                    container
                    spacing={2}
                    padding={0}
                    alignContent={"center"}
                    justifyContent={"center"}
                    my={1}
                  >
                    <Grid item xs={12}>
                      <InputAmbiental
                        name={"descricao"}
                        label={"Nome / Descrição"}
                        required={true}
                        onChange={handleChange}
                        value={values.descricao}
                        disabled={isDeleteMode}
                        fullWidth={true}
                      />
                      {errors.descricao && (
                        <div style={{ color: "red" }}>{errors.descricao}</div>
                      )}
                    </Grid>
                  </Grid>
                  {console.log(errors)}
                  <Grid
                    container
                    spacing={2}
                    padding={0}
                    alignContent={"center"}
                    justifyContent={"center"}
                    my={1}
                  >
                    <Grid item xs={2}>
                      <FormGroup>
                        <FormLabel
                          id="tipo-documento-label"
                          style={{ color: "#000", fontWeight: "bold" }}
                        >
                          Tipo documento
                        </FormLabel>
                        <FormGroup row>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.tipo_documento === "fisica"}
                                onChange={(event) => {
                                  const value = event.target.value;
                                  setFieldValue("tipo_documento", value);
                                }}
                                name="tipo_documento"
                                value="fisica"
                                color="success"
                                disabled={isDeleteMode}
                              />
                            }
                            label="CPF"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.tipo_documento === "juridica"}
                                onChange={(event) => {
                                  const value = event.target.value;
                                  setFieldValue("tipo_documento", value);
                                }}
                                name="tipo_documento"
                                value="juridica"
                                color="success"
                                disabled={isDeleteMode}
                              />
                            }
                            label="CNPJ"
                          />
                        </FormGroup>
                        {errors && errors.tipo_documento ? (
                          <div style={{ color: "red" }}>
                            {errors.tipo_documento}
                          </div>
                        ) : null}
                      </FormGroup>
                    </Grid>
                    <Grid item xs={5}>
                      <InputAmbiental
                        label={
                          values.tipo_documento === "fisica" ? "CPF" : "CNPJ"
                        }
                        required={false}
                        name={"documento"}
                        onChange={(event) => {
                          setFieldValue("documento", event.target.value);
                          setFieldValue(
                            values.tipo_documento === "fisica" ? "cpf" : "cnpj",
                            event.target.value
                          );
                        }}
                        value={values.documento}
                        fullWidth={true}
                        hasMask={true}
                        type="text"
                        maskType={
                          values.tipo_documento === "fisica" ? "cpf" : "cnpj"
                        }
                      />
                      {errors &&
                      errors[
                        values.tipo_documento === "fisica" ? "cpf" : "cnpj"
                      ] ? (
                        <div style={{ color: "red" }}>
                          {
                            errors[
                              values.tipo_documento === "fisica"
                                ? "cpf"
                                : "cnpj"
                            ]
                          }
                        </div>
                      ) : null}
                    </Grid>
                    <Grid item xs={5}>
                      <InputAmbiental
                        name={"cadastro"}
                        label={"Matrícula"}
                        required={false}
                        onChange={handleChange}
                        value={values.cadastro}
                        disabled={isDeleteMode}
                        fullWidth={true}
                      />
                      {errors.cadastro && (
                        <div style={{ color: "red" }}>{errors.cadastro}</div>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Box my={2} p={0}>
                  <h2>Endereço</h2>
                  <Divider sx={{ my: 2 }} />
                </Box>
                <Grid
                  container
                  spacing={2}
                  padding={0}
                  alignContent={"center"}
                  justifyContent={"center"}
                  display={"flex"}
                  width={"100%"}
                  my={1}
                >
                  <Grid item xs={2} alignItems={"flex-start"}>
                    <InputAmbiental
                      label={"CEP"}
                      required={false}
                      name={"cep"}
                      onChange={handleChange}
                      value={values.cep}
                      fullWidth={true}
                      hasMask={true}
                      maskType={"cep"}
                    />
                    {errors && errors.ceo ? (
                      <div style={{ color: "red" }}>{errors.ceo}</div>
                    ) : null}
                  </Grid>
                  <Grid item xs={6}>
                    <InputAmbiental
                      label={"Logradouro"}
                      required={false}
                      name={"logradouro"}
                      onChange={handleChange}
                      value={values.logradouro}
                      fullWidth={true}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InputAmbiental
                      label={"Número"}
                      required={false}
                      name={"numero"}
                      onChange={handleChange}
                      value={values.numero}
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  padding={0}
                  alignContent={"center"}
                  justifyContent={"center"}
                  display={"flex"}
                  width={"100%"}
                  my={1}
                >
                  <Grid item xs={5} alignItems={"flex-start"}>
                    <InputAmbiental
                      label={"Bairro"}
                      required={false}
                      name={"bairro"}
                      onChange={handleChange}
                      value={values.bairro}
                      fullWidth={true}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <InputAmbiental
                      label={"Cidade"}
                      required={false}
                      name={"cidade"}
                      onChange={handleChange}
                      value={values.cidade}
                      fullWidth={true}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <SelectAmbiental
                      name={"uf"}
                      label={"UF"}
                      value={values.uf}
                      options={ESTADOS_BRASIL}
                      fullWidth={true}
                      nameOptionValue={"sigla"}
                      nameOptionDescription={"nome"}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  padding={0}
                  alignContent={"center"}
                  justifyContent={"center"}
                  display={"flex"}
                  width={"100%"}
                  my={1}
                >
                  <Grid item xs={12} alignItems={"flex-start"}>
                    <InputAmbiental
                      label={"Complemento"}
                      required={false}
                      name={"complemento"}
                      onChange={handleChange}
                      value={values.complemento}
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>
                <Box my={2} p={0}>
                  <h2>Documento de posse</h2>
                  <Divider sx={{ my: 2 }} />
                </Box>
                <Box
                  width="100%"
                  display={"flex"}
                  alignContent={"center"}
                  justifyContent={"center"}
                  p={0}
                  my={1}
                >
                  <ActionButton
                    color={"green"}
                    icon={<CloudUploadIcon />}
                    title={"Fazer upload"}
                    action={handleOpenModal}
                  />
                </Box>
                {isDocumentoAnexado ? (
                  <Stack
                    style={{
                      width: "100%",
                      padding: 2,
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                    }}
                    direction={"row"}
                    my={3}
                    gap={10}
                  >
                    <Box>
                      <Typography sx={{ fontWeight: "bold" }}>
                        Descrição do documento anexado:
                      </Typography>{" "}
                      <Typography>{values.descricao_documento}</Typography>
                    </Box>
                    <Box>
                      <Typography sx={{ fontWeight: "bold" }}>
                        Tipo do arquivo:
                      </Typography>{" "}
                      <Typography>
                        {documentoSelecionado
                          ? String(documentoSelecionado.name)
                              ?.split(".")[1]
                              ?.toLocaleUpperCase()
                          : String(values.documento_unidade)
                              ?.split(".")[1]
                              ?.toLocaleUpperCase()}
                      </Typography>
                    </Box>
                    {id ? (
                      <Link
                        to={`${process.env.REACT_APP_API_KEY}/public/uploads/documentos/imoveis/${values.documento_unidade}`}
                        target="_blank"
                        style={{
                          textDecoration: "none",
                        }}
                      >
                        <VisibilityIcon cursor="pointer" color="info" />
                      </Link>
                    ) : null}
                  </Stack>
                ) : null}
                <ModalUploadDocumentoImovel
                  isOpen={isModalUploadDocumentoAberto}
                  handleClose={handleOpenModal}
                  document={documentoSelecionado}
                  hasDocument={isDocumentoAnexado}
                  handleUpload={handleDocumentoAnexado}
                  setFieldValue={setFieldValue}
                  description={values.descricao_documento}
                />
                <Box
                  width={"100%"}
                  display={"flex"}
                  alignItems={"flex-end"}
                  justifyContent={"flex-end"}
                  my={3}
                  sx={{
                    position: "sticky",
                    right: 0,
                    bottom: 10,
                  }}
                >
                  {isDeleteMode ? (
                    <ActionButton
                      title={"Excluir imóvel"}
                      to={null}
                      color="red"
                      icon={<DeleteIcon />}
                      isSubmitAction={false}
                      action={() => {
                        modalAlert(id);
                      }}
                    />
                  ) : (
                    <ActionButton
                      title={id ? "Atualizar" : "Criar"}
                      to={null}
                      color="blue"
                      icon={<BorderColorIcon />}
                      isSubmitAction={true}
                      action={() => {
                        handleSubmit(
                          values,
                          setSubmitting,
                          resetForm,
                          setFieldValue
                        );
                      }}
                    />
                  )}
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </JumboContentLayout>
    </Box>
  );
};

export default ImovelClienteForm;
