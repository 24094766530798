import { useJumboTheme } from "@jumbo/hooks";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import {
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  Grid,
  Stack,
  TextField,
  Toolbar,
  Tooltip,
  useMediaQuery,
  InputAdornment,
  Card,
  Typography,
  Chip,
} from "@mui/material";
import ApiService from "app/services/config";
import React, { useState, useEffect, useCallback, useContext } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import PageHeader from "app/layouts/shared/headers/PageHeader/PageHeader";
import { LoadingButton } from "@mui/lab";
import MUIDataTable from "mui-datatables";
import { PermissionContext } from "app/contexts/PermissionContext";
import { useNavigate } from "react-router-dom";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import ModalCadastroImovelCliente from "app/components/ModalCadastroImovelCliente/ModalCadastroImovelCliente";
import EditIcon from "@mui/icons-material/Edit";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import TaskIcon from "@mui/icons-material/Task";
import moment from "moment";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import MenuIcon from "@mui/icons-material/Menu";
import BackNavigation from "app/components/BackNavigation/BackNavigation";
import Tag from "app/components/Tag/Tag";
import DescriptionIcon from "@mui/icons-material/Description";
import ActionButton from "app/components/ActionButton/ActionButton";
import QueueIcon from "@mui/icons-material/Queue";
import SearchIcon from "@mui/icons-material/Search";
import Div from "@jumbo/shared/Div";
import PaginationComponent from "app/components/Pagination/Pagination";
import BotoesExportarDados from "app/components/BotoesExportarDados";

const linkStyle = {
  color: "#302D2E",
  fontSize: 20,
  textDecoration: "none",
  "&:visited": {
    color: "#302D2E",
  },
};

const ProcessosContrato = () => {
  const { theme } = useJumboTheme();
  const location = useLocation();
  const { contratoId, clienteId, processoId } = useParams();
  const [processosAdministrativos, setProcessosAdministrativos] = useState([]);
  const [breadCrumbsInfo, setBreadCrumbsInfo] = useState([]);
  const [processoAdministrativo, setProcessoAdministrativo] = useState(null);
  const [selectedProcessosIds, setSelectedProcessosIds] = useState([]);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState("id");
  const [sortOrder, setSortOrder] = useState("asc");
  const [searchTerm, setSearchTerm] = useState("");
  const [count, setCount] = useState("");
  const [clienteSelecionado, setClienteSelecionado] = useState({
    id: null,
    nome: null,
  });
  const [todosProcessosSelecionados, setTodosProcessosSelecionados] =
    useState(false);
  const [mostrarBotaoDeletarMuitos, setMostrarBotaoDeletarMuitos] =
    useState(false);
  const [
    isModalAdicionarImovelClienteAberto,
    setIsModalAdicionarImovelClienteAberto,
  ] = useState(false);

  const navigate = useNavigate();
  const { hasPermission } = useContext(PermissionContext);
  const permission = hasPermission("Contratos", "create");
  if (!hasPermission("Contratos", "read")) {
    navigate("/app");
  }
  const [inputValue, setInputValue] = useState("");

  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            zIndex: 5,
            top: 96,
            minHeight: "auto",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: "flex-start",
        },
      },
    }),
    [theme]
  );

  const Swal = useSwalWrapper();
  const toast = (variant, message) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true, // botão de fechar
      closeButtonAriaLabel: "Fechar",
      timer: 3000,
    });
  };

  const handleSearch = async (newSearchTerm) => {
    if (searchTerm === "" || searchTerm === null || searchTerm === undefined) {
      setPage(1);
    }
    setSearchTerm(newSearchTerm);
    setPage(0);
  };

  const handleSelecionarTodosProcessos = () => {
    const todosIdsProcessos = processosAdministrativos.map(
      (processo) => processo.id
    );
    if (!todosProcessosSelecionados) {
      setSelectedProcessosIds(todosIdsProcessos);
    } else {
      setSelectedProcessosIds([]);
    }
    setTodosProcessosSelecionados(!todosProcessosSelecionados);
  };

  const handleDeleteMuitosProcessos = () => {
    Swal.fire({
      title: "Tem certeza que deseja apagar os processos selecionados?",
      text: "Não será póssível reverter a ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteMuitosProcessos();
      }
    });
  };

  const deleteMuitosProcessos = useCallback(async () => {
    try {
      await ApiService.put(
        "/servicos/delete/many/processos",
        selectedProcessosIds
      ).then((response) => {
        if (response.status === 200) {
          toast("success", "Processos Administrativos removidos com sucesso!");
          getProcessos();
          setSelectedProcessosIds([]);
        }
      });
    } catch (error) {
      toast("error", "Não foi possível deletar os processos selecionados");
      console.log(error);
    }
  }, [selectedProcessosIds]);

  const getProcessos = useCallback(async () => {
    try {
      let apiUrl = `/servicos/contrato/${contratoId}?page=${
        page + 1
      }&perPage=${perPage}&sortBy=${sortBy}&sortOrder=${sortOrder}`;

      if (searchTerm) {
        apiUrl += `&searchTerm=${searchTerm}`;
      }
      ApiService.get(apiUrl)
        .then((response) => {
          const initialData = response.data.servicos.map((processo) => ({
            ...processo,
            isSelected: selectedProcessosIds.includes(processo.id),
          }));
          setProcessosAdministrativos(initialData);
          setCount(response.data.count);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, [page, perPage, sortBy, sortOrder, searchTerm, selectedProcessosIds]);

  useEffect(() => {
    getProcessos();
  }, [getProcessos]);

  const handleCheckboxChange = (processoId) => {
    setProcessosAdministrativos((prevProcessos) =>
      prevProcessos.map((processo) =>
        processo.id === processoId
          ? { ...processo, isSelected: !processo.isSelected }
          : processo
      )
    );

    setSelectedProcessosIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(processoId)) {
        return prevSelectedIds.filter((id) => id !== processoId);
      } else {
        return [...prevSelectedIds, processoId];
      }
    });

    setMostrarBotaoDeletarMuitos(selectedProcessosIds.length > 0);
    setTodosProcessosSelecionados(false);
  };

  const handleOpenModalAdicionarImovelCliente = () => {
    setIsModalAdicionarImovelClienteAberto(
      !isModalAdicionarImovelClienteAberto
    );
  };

  const getBreadCrumbsInfo = async () => {
    try {
      return await ApiService.get(
        `/contratos/breadcrumbs?contratoId=${contratoId}`
      ).then((response) => {
        if (response.status === 200) {
          setBreadCrumbsInfo(response.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (contratoId) {
      getBreadCrumbsInfo();
    }
  }, [contratoId]);

  return (
    <Box>
      <BackNavigation url={"/app/listar-contratos"} />
      <Tag
        label={breadCrumbsInfo?.descricao}
        sx={{ marginBottom: 3 }}
      />

      <JumboContentLayout
        header={
          <PageHeader
            title={`Processos Administrativos`}
            icon="desc"
            btn={
              <ActionButton
                title="Novo Processo"
                color="green"
                icon={<QueueIcon />}
                action={() =>
                  navigate(
                    `/app/contrato/${contratoId}/cliente/${clienteId}/processos/novo`,
                    {
                      state: {
                        descricao:
                          location?.state?.descricao ??
                          processosAdministrativos[0]?.contrato?.descricao,
                      },
                    }
                  )
                }
              />
            }
          />
        }
        layoutOptions={layoutOptions}
      >
        <Div
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            <TextField
              variant="standard"
              placeholder="Pesquisar..."
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSearch(inputValue);
                }
              }}
              InputProps={{
                sx: {
                  borderRadius: 2,
                  border: "1px solid #4E5526",
                  paddingLeft: 2,
                  height: "36px",
                  "&:hover": { borderColor: "#3B4220" },
                  "&.Mui-focused": { borderColor: "#4E5526" },
                  color: "#4E5526",
                  "&::placeholder": { color: "red", opacity: 1 },
                  width: 350,
                },
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      sx={{
                        backgroundColor: "#4E5526",
                        borderRadius: 2,
                        minWidth: "36px",
                        height: "36px",
                        padding: 0,
                        "&:hover": { backgroundColor: "#3B4220" },
                      }}
                      onClick={() => handleSearch(inputValue)}
                    >
                      <SearchIcon sx={{ color: "#fff", fontSize: 20 }} />
                    </Button>
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
            {selectedProcessosIds.length > 0 && (
              <Button
                variant="contained"
                color="error"
                startIcon={<DeleteIcon />}
                onClick={handleDeleteMuitosProcessos}
                sx={{
                  borderRadius: 2,
                  paddingX: 2,
                  backgroundColor: "#C84E4D",
                  textTransform: "none",
                }}
              >
                Excluir {selectedProcessosIds.length} processo(s)
              </Button>
            )}
            <BotoesExportarDados
              titulo={`Processos administrativos do contrato ${breadCrumbsInfo?.descricao}`}
              dados_planilha={processosAdministrativos}
              colunas_planilha={["processo_administrativo.descricao", "imovel.descricao", "data_submissao"]}
              nome_arquivo_planilha={`processos_administrativos_contrato_${breadCrumbsInfo?.descricao}_`}
            />
          </Box>
        </Div>

        <Card
          sx={{
            px: 4,
            width: "100%",
            minHeight: 100,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "none",
            backgroundColor: "transparent",
          }}
        >
          <Grid container justifyContent="space-between" alignItems="center" gap={2}>
            <Grid item lg={0.5}>
              <Checkbox
                sx={{
                  color: "#696969",
                  "&.Mui-checked": {
                    color: "#95A64A",
                  },
                }}
                onChange={handleSelecionarTodosProcessos}
              />
            </Grid>
            <Grid item lg={3.5}>
              <Box>
                <Typography fontSize={24} fontWeight={600}>
                  Processo Administrativo
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={2}>
              <Box>
                <Typography fontSize={24} fontWeight={600}>
                  Imóvel
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={2}>
              <Box>
                <Typography fontSize={24} fontWeight={600}>
                  Data de submissão
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={2}>
              <Box>
                <Typography fontSize={24} fontWeight={600}>
                  Ações
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Card>

        <React.Fragment>
          <Grid container gap={2}>
            {processosAdministrativos.length > 0 ? (
              <>
                {processosAdministrativos.map((p, key) => (
                  <Card
                    sx={{
                      p: 0,
                      width: "100%",
                      minHeight: 100,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    key={key}
                  >
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      gap={2}
                      p={4}
                    >
                      <Grid item lg={0.5}>
                        <Checkbox
                          sx={{
                            color: "#696969",
                            "&.Mui-checked": {
                              color: "#95A64A",
                            },
                          }}
                          key={p.id}
                          checked={p.isSelected}
                          onChange={() => handleCheckboxChange(p.id)}
                        />
                      </Grid>
                      <Grid item lg={3.5}>
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Typography fontSize={18}>
                            {p.processo_administrativo.descricao}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={2}>
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Typography fontSize={18}>
                            {p.imovel.descricao ?? "N/I"}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={2}>
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Typography fontSize={18}>
                            {p?.data_submissao
                              ? moment
                                  .utc(p?.data_submissao)
                                  .format("DD/MM/YYYY")
                              : "N/I"}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={2}>
                        <Box display={"flex"} gap={"1rem"}>
                          <Tooltip title="Serviços">
                            <MenuIcon
                              cursor="pointer"
                              color="success"
                              onClick={() => {
                                navigate(
                                  `/app/contrato/${contratoId}/cliente/${clienteId}/processos/${p.id}/procadmin/${p.processo_administrativo.id}/servicos`,
                                  {
                                    state: {
                                      descricao: location?.state?.descricao,
                                      processoDescricao:
                                        p?.processo_administrativo?.descricao,
                                    },
                                  }
                                );
                              }}
                            />
                          </Tooltip>
                          {/* <Tooltip title="Protocolos">
                            <TaskIcon
                              cursor="pointer"
                              sx={{ color: "#1DA591" }}
                              onClick={() => {
                                navigate(
                                  `/app/contrato/${contratoId}/cliente/${clienteId}/processos/${p.id}/procadmin/${p.processo_administrativo.id}/protocolos`,
                                  {
                                    state: {
                                      descricao: location?.state?.descricao,
                                      processoDescricao:
                                        p?.processo_administrativo?.descricao,
                                    },
                                  }
                                );
                              }}
                            />
                          </Tooltip>
                          <Tooltip title="Licenças">
                            <WorkspacePremiumIcon
                              cursor="pointer"
                              sx={{ color: "#1DA566" }}
                              onClick={() => {
                                navigate(
                                  `/app/contrato/${contratoId}/cliente/${clienteId}/processos/${p.id}/procadmin/${p.processo_administrativo.id}/licencas`,
                                  {
                                    state: {
                                      descricao: location?.state?.descricao,
                                      processoDescricao:
                                        p?.processo_administrativo?.descricao,
                                    },
                                  }
                                );
                              }}
                            />
                          </Tooltip> */}
                          <Tooltip title="Editar Processo">
                            <EditIcon
                              cursor="pointer"
                              color="info"
                              disabled={!permission}
                              onClick={() => {
                                navigate(
                                  `/app/contrato/${contratoId}/cliente/${clienteId}/processos/editar/${p.id}`,
                                  {
                                    state: {
                                      descricao:
                                        location?.state?.descricao ??
                                        processosAdministrativos[0]?.contrato
                                          ?.descricao,
                                      processoDescricao:
                                        p?.processo_administrativo?.descricao,
                                    },
                                  }
                                );
                              }}
                            />
                          </Tooltip>
                          <Tooltip title="Excluir Proc. Administrativo">
                            <DeleteIcon
                              cursor="pointer"
                              color="error"
                              onClick={() => {
                                navigate(
                                  `/app/contrato/${contratoId}/cliente/${clienteId}/processos/${p.id}/deletar`
                                );
                              }}
                            />
                          </Tooltip>
                        </Box>
                      </Grid>
                    </Grid>
                  </Card>
                ))}
              </>
            ) : (
              <Grid container>
                <Grid item style={{ width: "100%" }}>
                  <Box my={5} textAlign={"center"}>
                    <Typography>Nenhum processo cadastrado.</Typography>
                  </Box>
                </Grid>
              </Grid>
            )}

            <PaginationComponent
              page={page}
              setPage={setPage}
              count={count}
              perPage={perPage}
              setPerPage={setPerPage}
            />
          </Grid>
          <ModalCadastroImovelCliente
            aberto={isModalAdicionarImovelClienteAberto}
            handleClose={handleOpenModalAdicionarImovelCliente}
            clienteSelecionado={clienteSelecionado}
          />
        </React.Fragment>
      </JumboContentLayout>
    </Box>
  );
};

export default ProcessosContrato;
