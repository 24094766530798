const getTipoContrato = (tiposDeContrato) => {
  const TIPOS_CONTRATOS = {
    FIXO: "contrato fixo",
    RENOVACAO: "renovação",
    PADRAO: "padrão",
  };

  const estadoContrato = tiposDeContrato?.reduce(
    (acc, tipoContrato) => {
      const descricao =
        tipoContrato?.tipos_contratos?.descricao?.toLowerCase() ||
        tipoContrato?.descricao?.toLowerCase() ||
        "";
      return {
        isContratoFixo:
          acc.isContratoFixo || descricao.includes(TIPOS_CONTRATOS.FIXO),
        isContratoRenovacao:
          acc.isContratoRenovacao ||
          descricao.includes(TIPOS_CONTRATOS.RENOVACAO),
        isContratoPadrao:
          acc.isContratoPadrao || descricao.includes(TIPOS_CONTRATOS.PADRAO),
      };
    },
    {
      isContratoFixo: false,
      isContratoRenovacao: false,
      isContratoPadrao: false,
    }
  );

  return estadoContrato;
};

export default getTipoContrato;