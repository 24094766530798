import {
  Box,
  Chip,
  Divider,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PersonIcon from "@mui/icons-material/Person";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import moment from "moment";
import InputAmbiental from "@jumbo/components/InputAmbiental";
import SelectAmbiental from "@jumbo/components/SelectAmbiental";

const ContratoHeader = ({
  descricao,
  elaboradores,
  dataCriacao,
  dataAprovacao,
  statusOrcamentos,
  statusOrcamentoId,
  handleAtualizarStatusOrcamentario,
  contratoId,
  cliente,
  handleAtualizarDataAprovacaoContrato,
}) => {
  const [dataAprovacaoContrato, setDataAprovacaoContrato] = useState("");

  useEffect(() => {
    if (dataAprovacao) {
      setDataAprovacaoContrato(moment.utc(dataAprovacao).format("YYYY-MM-DD"));
    }
  }, []);

  return (
    <Box style={{ width: "100%" }}>
      <Box display={"inline-flex"} gap={1}>
        <Typography
          fontWeight={600}
          fontSize={28}
          color={"#212529"}
        >
          Dados do contrato
        </Typography>
      </Box>
      <Stack width="100%" direction="row" alignItems={"center"}>
        <Box
          width="50%"
          display={"flex"}
          flexDirection={"column"}
          gap={2}
          my={2}
        >
          <Box width="50%">
            <Stack width="100%" direction={"row"} gap={1}>
              <Typography fontWeight={600} fontSize={16}>
                Elaborador(es):{" "}
              </Typography>
              {elaboradores?.map((elaborador, index) => (
                <Typography fontSize={16}>
                  {elaborador?.usuario?.name}
                  {index < elaboradores.length - 1 && ", "}
                </Typography>
              ))}
            </Stack>
          </Box>
          <Box width="50%">
            <Stack width="100%" direction={"row"} gap={1}>
              <Typography fontWeight={600} fontSize={16}>
                Data de Elaboração:
              </Typography>
              <Typography fontSize={16}>
                {moment.utc(dataCriacao).format("DD/MM/YYYY")}
              </Typography>
            </Stack>
          </Box>
        </Box>
        <Box
          width="50%"
          display={"flex"}
          flexDirection={"column"}
          gap={1}
          my={2}
        >
          <Box width="50%">
            <Stack width="100%" direction={"row"} gap={1} alignItems={"center"}>
              <Box width="max-content">
                <Typography fontWeight={600} fontSize={16} noWrap>
                  Status Orçamentário:
                </Typography>
              </Box>
              <SelectAmbiental
                value={statusOrcamentoId}
                options={statusOrcamentos}
                nameOptionDescription={`descricao`}
                nameOptionValue={`id`}
                width={226}
                onChange={(event) => {
                  const statusId = event.target.value;
                  handleAtualizarStatusOrcamentario(contratoId, statusId);
                }}
              />
            </Stack>
          </Box>
          {dataAprovacaoContrato ? <Box
            width="50%"
            display="flex"
            direction={"row"}
            gap={1}
            alignItems={"center"}
          >
            <Box width="250px">
              <Typography fontWeight={600} fontSize={16} noWrap>
                Data de Aprovação:{" "}
              </Typography>
            </Box>
            <Box>
              <InputAmbiental
                type="date"
                value={dataAprovacaoContrato}
                onChange={(event) => {
                  handleAtualizarDataAprovacaoContrato(event.target.value);
                }}
                width={300}
                fullWidth={false}
              />
            </Box>
          </Box> : null}
          
        </Box>
      </Stack>
    </Box>
  );
};

export default ContratoHeader;
