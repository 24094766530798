import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import { useJumboTheme } from "@jumbo/hooks";
import {
  Box,
  Breadcrumbs,
  Button,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Slider,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ApiService from "app/services/config";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import AddIcon from "@mui/icons-material/Add";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import StopIcon from "@mui/icons-material/Stop";
import DescriptionIcon from "@mui/icons-material/Description";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import ModalUploadDocumentoServico from "app/components/ModalUploadDocumentoServico/ModalUploadDocumentoServico";
import BackNavigation from "app/components/BackNavigation/BackNavigation";
import Tag from "app/components/Tag/Tag";
import PageHeader from "app/layouts/shared/headers/PageHeader/PageHeader";
import ActionButton from "app/components/ActionButton/ActionButton";
import SelectAmbiental from "@jumbo/components/SelectAmbiental";
import QueueIcon from "@mui/icons-material/Queue";
import InputAmbiental from "@jumbo/components/InputAmbiental";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const linkStyle = {
  color: "#302D2E",
  fontSize: 20,
  textDecoration: "none",
  "&:visited": {
    color: "#302D2E",
  },
};

const ATIVIDADES_DEPENDE = {
  ambiental: {
    atividade: "Ambiental",
    cor: "#EC5F6F",
  },
  cliente: {
    atividade: "Cliente",
    cor: "#F5AB3E",
  },
  orgao: {
    atividade: "Órgão",
    cor: "#5FDBB3",
  },
  nao_informado: {
    atividade: "Não informado",
    cor: "#F0F0F0",
  },
};

const ServicoContratoDetalhes = () => {
  const { id, contratoId, processoId, clienteId, servicoId, procadmin } =
    useParams();
  const [breadCrumbsInfo, setBreadCrumbsInfo] = useState([]);
  const [servico, setServico] = useState([]);
  const [isDadosCarregados, setIsDadosCarregados] = useState(false);
  const [porcentagem, setPorcentagem] = useState(0);
  const [responsavelAtividade, setResponsavelAtividade] =
    useState("nao_informado");
  const [novaObservacao, setNovaObservacao] = useState("");
  const [comentarios, setComentarios] = useState([]);
  const [isModalUploadDocumentoAberto, setIsModalUploadDocumentoAberto] =
    useState(false);
  const [prazoServico, setPrazoServico] = useState("");
  const { theme } = useJumboTheme();
  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const userId = Number(localStorage.getItem("id"));
  const roleId = Number(localStorage.getItem("role_id"));
  const [idsPerfisAdmins, setIdsPerfisAdmins] = useState([]);
  const layoutOptions = React.useMemo(
    () => ({
      root: {
        sx: {
          height: "20px"
        }
      },
      sidebar: {
        sx: {
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            zIndex: 5,
            top: 96,
            minHeight: "auto",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: "flex-start",
        },
      },
    }),
    [theme]
  );

  const Swal = useSwalWrapper();
  const toast = (variant, message, type = false) => {
    Swal.fire({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      icon: type ? "success" : "error",
      title: message,
      didOpen: (toast) => {
        toast.style.zIndex = 10000;
      },
      timer: 3000,
    });
  };

  const handleOpenModalUploadDocumento = () => {
    setIsModalUploadDocumentoAberto(!isModalUploadDocumentoAberto);
  };

  const handleIniciarServico = async (id, status_servico) => {
    try {
      if (status_servico === 1) {
        toast("info", "O serviço já foi iniciado!");
        return;
      }

      await ApiService.put(`/servicos/iniciar/${id}`).then((response) => {
        if (response.status === 200) {
          toast(null, "Serviço iniciado!", "success");
          getServico();
        }
      });
    } catch (error) {
      console.log(error);
      toast(null, "Ocorreu um erro", "error");
    }
  };

  const handleParalisarServico = async (id, status_servico) => {
    try {
      if (status_servico === 6) {
        toast("info", "O serviço já foi paralisado!");
        return;
      }
      await ApiService.put(`/servicos/paralisar/${id}`).then((response) => {
        if (response.status === 200) {
          toast(null, "Serviço paralisado!", "success");
          getServico();
        }
      });
    } catch (error) {
      console.log(error);
      toast(null, "Ocorreu um erro", "error");
    }
  };

  const handleConcluirServico = async (id, status_servico, contratoId) => {
    try {
      if (status_servico === 12) {
        toast("info", "O serviço já foi concluído!");
        return;
      }
      await ApiService.put(`/servicos/concluir/${id}/${contratoId}`).then(
        (response) => {
          if (response.status === 200) {
            toast(null, "Serviço concluído!", "success");
            getServico();
          }
        }
      );
    } catch (error) {
      console.log(error);
      toast(null, "Ocorreu um erro", "error");
    }
  };

  const handleAtualizarPrazoServico = async (data) => {
    try {
      await ApiService.put(`/servicos/prazo/${servicoId}?prazo=${data}`).then(
        (response) => {
          if (response.status === 200) {
            getServico();
            toast(null, "Prazo do serviço atualizado!", "success");
          }
        }
      );
    } catch (error) {
      toast(null, "Ocorreu um erro", "error");
      console.log(error);
    }
  };

  const getServico = useCallback(async () => {
    try {
      await ApiService.get(`/servicos/${servicoId}`).then((response) => {
        const { prazo } = response.data;
        if (prazo) {
          setPrazoServico(moment.utc(prazo).format("YYYY-MM-DD"));
        } else {
          setPrazoServico("");
        }
        if (response.status === 200) {
          setServico(response.data);
          setPorcentagem(response?.data?.porcentagem);
          setResponsavelAtividade(response?.data?.responsavel);
        }
      });
    } catch (error) {}
  }, [servicoId]);

  const handleUpdateRealizacaoEResponsavel = async () => {
    try {
      await ApiService.put(`/servicos/acompanhamento/${servicoId}`, {
        realizacao: porcentagem,
        responsavel: responsavelAtividade,
      }).then((response) => {
        if (response.status === 200) {
          toast(null, "Informações atualizadas!", "success");
        }
      });
    } catch (error) {
      console.error(error);
      toast(null, "Ocorreu um erro", "error");
    }
  };

  const getComentariosServico = useCallback(async () => {
    try {
      await ApiService.get(`/servicos/comentarios/${servicoId}`).then(
        (response) => {
          if (response.status === 200) {
            const observacoes = response.data?.observacoes?.map(
              (observacao) => observacao
            );
            setComentarios(observacoes);
          }
        }
      );
    } catch (error) {
      console.error(error);
    }
  }, [servicoId]);

  const handleAdicionarComentarioServico = async (comentario) => {
    try {
      if (novaObservacao !== "") {
        await ApiService.put(`/servicos/comentario/${servicoId}`, {
          descricao: novaObservacao,
          userId: Number(userId),
        }).then((response) => {
          if (response.status === 200) {
            getComentariosServico();
            toast(null, "Comentário adicionado!", "success");
            setNovaObservacao("");
          }
        });
      } else {
        toast("warning", "O comentário não pode ser vazio!");
      }
    } catch (error) {
      console.error(error);
      toast(null, "Ocorreu um erro", "error");
    }
  };

  const handleDeleteDocumentoServico = async (id) => {
    try {
      return await ApiService.delete(`/servicos/documento/${id}`).then(
        (response) => {
          if (response.status === 200) {
            getServico();
            toast(null, "Documento excluído!", "success");
          }
        }
      );
    } catch (error) {
      toast(null, "Ocorreu um erro", "error");
      console.error(error);
    }
  };

  const handleDeleteComentarioServico = async (id) => {
    try {
      return await ApiService.delete(`/servicos/comentario/${id}`).then(
        (response) => {
          if (response.status === 200) {
            getComentariosServico();
            toast(null, "Comentário excluído!", "success");
          }
        }
      );
    } catch (error) {
      toast(null, "Ocorreu um erro", "error");
      console.error(error);
    }
  };

  const getBreadCrumbsInfo = async () => {
    try {
      return await ApiService.get(
        `/contratos/breadcrumbs?contratoId=${contratoId}&processoId=${processoId}&servicoId=${servicoId}`
      ).then((response) => {
        if (response.status === 200) {
          setBreadCrumbsInfo(response.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleAtualizarDetalhesServico = () => {
    toast("success", "Detalhes do serviço atualizados!", "success");
    navigate(-1);
  };

  const modalAlert = (id, tipo) => {
    Swal.fire({
      title: "Tem certeza que deseja apagar?",
      text: "Não será póssível reverter a ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        if (tipo === "comentario") {
          handleDeleteComentarioServico(id);
        }
        if (tipo === "documento") {
          handleDeleteDocumentoServico(id);
        }
      }
    });
  };

  useEffect(async () => {
    if (contratoId && contratoId !== "null") {
      await getBreadCrumbsInfo();
    }
    await getServico();
    await getComentariosServico();
    setIsDadosCarregados(true);
  }, [contratoId, servicoId]);

  const navigate = useNavigate();

  const getPerfisAdministrativos = async () => {
    try {
      await ApiService.get('/roles/admin')
      .then((response) => {
        if(response.status === 200) {
          const IDS = response?.data?.map(perfil => perfil.id);
          console.log(IDS);
          setIdsPerfisAdmins(IDS);
        }
      })
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getPerfisAdministrativos()
  }, [])

  useEffect(() => {
    if (
      servico &&
      servico?.servicocolaborador?.length > 0 &&
      !idsPerfisAdmins.includes(roleId)
    ) {
      const colaboradorFazParteDoServico = servico.servicocolaborador.some(
        (colaborador) => colaborador?.users?.id === userId
      );

      if (!colaboradorFazParteDoServico) {
        navigate("/app/dashboard");
      }
    }
  }, [servico]);
  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      <BackNavigation url={"/app/dashboard"} />
      <Tag label={servico?.Contrato?.descricao} sx={{ marginBottom: 3 }} />

      <Typography variant={"h5"} fontWeight={600} mb={3}>
        {servico?.processo_contrato?.processo_administrativo?.descricao}
      </Typography>

      {isDadosCarregados && (
        <JumboContentLayout
          header={
            <PageHeader
              title={servico?.tarefas_servico[0]?.descricao}
              icon="desc"
            />
          }
          layoutOptions={layoutOptions}
        >
          {/* {lg && <Stack spacing={2} direction={"row"} sx={{ mb: 3, mt: -2 }} />}
          <Stack width={"100%"}>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              <Link to={"/app/dashboard"} color="success" style={linkStyle}>
                Gestão de Serviços
              </Link>
            </Breadcrumbs>
          </Stack> */}
          <Grid
            container
            width={"100%"}
            sx={{ gap: 1, display: "flex", justifyContent: "space-between" }}
          >
            <Grid item lg={5} xs={12}>
              <Stack width={"100%"} direction={"column"} gap={1}>
                <Stack direction={"row"} gap={1}>
                  <Typography fontWeight={600} fontSize={18}>
                    Cliente:
                  </Typography>
                  <Typography fontSize={18}>
                    {servico?.Contrato?.Cliente?.nome}
                  </Typography>
                </Stack>
                <Stack direction={"row"} gap={1}>
                  <Typography fontWeight={600} fontSize={18}>
                    Unidade:
                  </Typography>
                  <Typography fontSize={18}>
                    {servico?.processo_contrato?.imovel?.unidade?.nomeUnidade}
                  </Typography>
                </Stack>
                <Stack direction={"row"} gap={1} mb={5}>
                  <Typography fontWeight={600} fontSize={18}>
                    Imóvel:
                  </Typography>
                  <Typography fontSize={18}>
                    {servico?.processo_contrato?.imovel?.descricao}
                  </Typography>
                </Stack>

                <Stack direction={"row"} gap={1}>
                  <Typography fontWeight={600} fontSize={18}>
                    Setor responsável:
                  </Typography>
                  <Typography fontSize={18}>
                    {servico?.setor_responsavel?.descricao}
                  </Typography>
                </Stack>
                <Stack direction={"row"} gap={1}>
                  <Typography fontWeight={600} fontSize={18}>
                    Orientadores:
                  </Typography>
                  <Stack direction={"row"} flexWrap="wrap" gap={0.5}>
                    {servico?.Contrato?.orientadores?.length > 0
                      ? servico?.Contrato?.orientadores?.map(
                          (orientador, index) => (
                            <Typography fontSize={18} key={index}>
                              {orientador?.usuario?.name}
                              {index <
                              servico?.Contrato?.orientadores?.length - 1
                                ? ","
                                : ""}
                            </Typography>
                          )
                        )
                      : "Nenhum orientador"}
                  </Stack>
                </Stack>
                <Stack direction={"row"} gap={1}>
                  <Typography fontWeight={600} fontSize={18}>
                    Colaboradores:
                  </Typography>
                  <Stack direction={"row"} flexWrap="wrap" gap={0.5}>
                    {servico?.servicocolaborador?.length > 0
                      ? servico?.servicocolaborador?.map(
                          (colaborador, index) => (
                            <Typography fontSize={18} key={index}>
                              {colaborador?.users?.name}
                              {index < servico?.servicocolaborador?.length - 1
                                ? ","
                                : ""}
                            </Typography>
                          )
                        )
                      : "Nenhum colaborador"}
                  </Stack>
                </Stack>
              </Stack>
            </Grid>

            <Grid
              item
              lg={6}
              xs={12}
              sx={{
                background: "#F4F4F4",
                borderRadius: 6,
                pt: 4,
                pb: 2,
                px: 6,
              }}
            >
              <Stack width="100%">
                <Typography variant={"h1"} fontWeight={600} color="#212529">
                  Serviço: {servico?.status_andamento_servico?.descricao}
                </Typography>
              </Stack>
              <Stack width={"100%"} my={2} direction={"column"} gap={1}>
                <Stack direction={"row"} gap={1}>
                  <Typography fontWeight={600} fontSize={18}>
                    Aprovação do contrato:
                  </Typography>
                  <Typography fontSize={18}>
                    {servico?.Contrato?.data_aprovacao
                      ? moment
                          .utc(servico?.Contrato?.data_aprovacao)
                          .format("DD/MM/YYYY")
                      : "N/A"}
                  </Typography>
                </Stack>
                <Stack direction={"row"} gap={1}>
                  <Typography fontWeight={600} fontSize={18}>
                    Data de início:
                  </Typography>
                  <Typography fontSize={18}>
                    {servico?.data_inicio
                      ? moment.utc(servico?.data_inicio).format("DD/MM/YYYY")
                      : "Sem data"}
                  </Typography>
                </Stack>
                <Stack direction={"row"} gap={1}>
                  <Typography fontWeight={600} fontSize={18}>
                    Data de conclusão:
                  </Typography>
                  <Typography fontSize={18}>
                    {servico?.data_conclusao
                      ? moment.utc(servico?.data_conclusao).format("DD/MM/YYYY")
                      : "Sem data"}
                  </Typography>
                </Stack>
                <Stack direction={"row"} alignItems={"center"} gap={1} mt={2}>
                  <Typography fontWeight={600} fontSize={18}>
                    Prazo do serviço:
                  </Typography>
                  <TextField
                    type="date"
                    value={prazoServico}
                    onChange={(event) => {
                      handleAtualizarPrazoServico(event.target.value);
                    }}
                    sx={{
                      width: 150,
                      "& .MuiInputBase-root": {
                        height: 45,
                        borderRadius: 2,
                      },
                      "& input": {
                        height: "100%",
                        paddingx: 10,
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#4E5526",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#4E5526",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#4E5526",
                      },
                    }}
                  />
                  <Box>
                    <Typography fontSize={18} fontWeight={600}>
                      {servico?.prazo
                        ? `(${moment
                            .utc(servico.prazo)
                            .startOf("day")
                            .diff(moment().utc().startOf("day"), "days")} dias)`
                        : "Sem data"}
                    </Typography>
                  </Box>
                </Stack>
                <Stack width="100%" direction="row" gap={2} mt={2}>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={() => {
                      handleIniciarServico(
                        servicoId,
                        servico?.status_andamento_servico?.id
                      );
                    }}
                  >
                    <PlayArrowIcon />
                  </Button>
                  <Button
                    variant="contained"
                    color="warning"
                    onClick={() => {
                      handleParalisarServico(
                        servicoId,
                        servico?.status_andamento_servico?.id
                      );
                    }}
                  >
                    <PauseIcon />
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => {
                      handleConcluirServico(
                        servicoId,
                        servico?.status_andamento_servico?.id,
                        servico?.Contrato?.id
                      );
                    }}
                  >
                    <StopIcon />
                  </Button>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
          <Grid
            container
            width={"100%"}
            sx={{
              my: 1,
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            <Grid item lg={6} py={2}>
              <Typography my={1} fontWeight={600} fontSize={18}>
                Realização
              </Typography>
              <Box
                display="flex"
                gap={1}
                alignItems="flex-end"
                sx={{ height: "50px" }}
              >
                <Slider
                  value={porcentagem}
                  onChange={(event) => {
                    setPorcentagem(event.target.value);
                  }}
                  name="porcentagem"
                  defaultValue={porcentagem}
                  color={"success"}
                  min={0}
                  max={100}
                  sx={{ flex: 4, width: "90%" }}
                />
                <TextField
                  name="porcentagem"
                  type="number"
                  onChange={(event) => {
                    setPorcentagem(event.target.value);
                  }}
                  value={porcentagem}
                  variant="outlined"
                  size="small"
                  InputProps={{
                    endAdornment: "%",
                  }}
                  sx={{
                    flex: 1,
                    width: "1%",
                    "& input": {
                      paddingLeft: 4,
                    },
                    "& .MuiInputBase-root": {
                      borderRadius: 2,
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#4E5526",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#4E5526",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#4E5526",
                    },
                  }}
                />
              </Box>
            </Grid>
            <Grid item lg={5} p={2}>
              <Typography my={1} fontWeight={600} fontSize={18}>
                Entidade responsável pelo serviço
              </Typography>

              <SelectAmbiental
                name="responsavel"
                id="select-responsavel"
                fullWidth
                onChange={(event) => {
                  setResponsavelAtividade(event.target.value);
                }}
                renderValue={(value) => {
                  const atividade = ATIVIDADES_DEPENDE[value];
                  return (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          width: "10px",
                          height: "10px",
                          borderRadius: "50%",
                          backgroundColor: atividade?.cor,
                          marginRight: "8px",
                        }}
                      />
                      <Typography>{atividade?.atividade}</Typography>
                    </Box>
                  );
                }}
                value={responsavelAtividade ?? "nao_informado"}
                sx={{
                  "& .MuiSelect-selectMenu": {
                    display: "flex",
                    alignItems: "center",
                  },
                }}
                nameOptionDescription={"description"}
                nameOptionValue={"value"}
                options={Object.entries(ATIVIDADES_DEPENDE).map(
                  ([key, atividade]) => ({
                    value: key,
                    description: atividade.atividade,
                    color: atividade.cor,
                  })
                )}
              ></SelectAmbiental>
            </Grid>
            <Grid item lg={1} mb={2}>
              <Box>
                <ActionButton
                  title="Salvar"
                  color="green"
                  action={handleUpdateRealizacaoEResponsavel}
                  sx={{ width: "100%" }}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container width={"100%"} py={2}>
            <Stack
              width={"100%"}
              direction="row"
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Box>
                <Typography
                  variant="h2"
                  fontWeight={600}
                  color="#212529"
                  mb={0}
                >
                  Documentos:
                </Typography>
              </Box>
              <Box>
                <ActionButton
                  title="Novo documento"
                  color="dustyTeal"
                  action={handleOpenModalUploadDocumento}
                  icon={<QueueIcon />}
                />
              </Box>
            </Stack>
            <Box width="100%" my={2}>
              {servico && servico?.documentos.length > 0 ? (
                servico?.documentos.map((documento, index) => (
                  <Stack
                    key={index}
                    width="100%"
                    my={1}
                    direction="row"
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <DescriptionIcon color="info" />
                      <Typography fontSize={16}>{documento.nome}</Typography>
                    </Box>
                    <Stack direction="row" gap={1} alignItems={"center"}>
                      <Tooltip title="Download" sx={{ flex: 1 }}>
                        <ActionButton
                          color="olive"
                          action={() => {
                            const link = document.createElement("a");
                            link.href = `${process.env.REACT_APP_API_KEY}/public/uploads/documentos/servicos/${documento.path}`;
                            link.download = documento.nome;
                            link.target = "_blank";
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                          }}
                          icon={<DownloadIcon />}
                          width={40}
                          height={40}
                          maxWidth={"auto"}
                        />
                      </Tooltip>

                      <Tooltip title="Deletar documento" sx={{ flex: 1 }}>
                        <ActionButton
                          color="red"
                          action={() => {
                            modalAlert(documento.id, "documento");
                          }}
                          icon={<DeleteIcon />}
                          width={40}
                          height={40}
                          maxWidth={"auto"}
                        />
                      </Tooltip>
                    </Stack>
                  </Stack>
                ))
              ) : (
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  height={150}
                >
                  <Typography fontSize={16}>
                    Nenhum documento cadastrado
                  </Typography>
                </Box>
              )}
            </Box>
          </Grid>
          <Grid container width={"100%"} py={2}>
            <Typography variant="h2" fontWeight={600} color="#212529">
              Comentários:
            </Typography>
            <Box my={1} width={"100%"}>
              {comentarios && comentarios.length === 0 ? (
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  height={150}
                >
                  <Typography my={1}>Nenhum comentário registrado</Typography>
                </Box>
              ) : (
                <>
                  {comentarios &&
                    comentarios.map((comentario, index) => (
                      <>
                        <Box
                          key={index}
                          my={2}
                          display="flex"
                          flexDirection={"column"}
                          // alignItems="center"
                        >
                          <Typography color="#212529" fontWeight={600} mb={1}>
                            {comentario.usuario?.name ?? "N/I"}
                          </Typography>
                          <Typography color="#212529">
                            {comentario.descricao}
                          </Typography>
                          {/* <TextField
                              name={`comentarios.${index}.descricao`}
                              label={`Comentário ${index + 1}`}
                              variant="outlined"
                              fullWidth
                              multiline
                              defaultValue={comentario.descricao}
                            />
                            <IconButton
                              variant="outlined"
                              color="error"
                              onClick={() => {
                                if (comentario.id) {
                                  modalAlert(comentario.id, "comentario");
                                } else {
                                  setComentarios((prevComentarios) =>
                                    prevComentarios.filter(
                                      (c) =>
                                        c.descricao.toLowerCase() !==
                                        comentario.descricao.toLowerCase()
                                    )
                                  );
                                }
                              }}
                            >
                              <DeleteIcon />
                            </IconButton> */}
                        </Box>
                        {/* {comentario.id && (
                            <>
                              <Stack
                                px={1}
                                direction={"row"}
                                justifyContent={"space-between"}
                              >
                                <Typography color="#909FAE">
                                  Usuário: {comentario.usuario?.name ?? "N/I"}
                                </Typography>
                                <Typography color="#909FAE">
                                  Criado em:{" "}
                                  {moment(comentario.createdAt).format(
                                    "DD/MM/YY HH:MM"
                                  )}
                                </Typography>
                              </Stack>
                              <Divider my={4} />
                            </>
                          )} */}
                      </>
                    ))}
                </>
              )}

              <Grid
                container
                spacing={2}
                mt={2}
                display={"flex"}
                flexDirection={"row"}
                alignItems="center"
                justifyContent={"space-between"}
              >
                <Grid item xs={9}>
                  <InputAmbiental
                    variant="outlined"
                    fullWidth
                    value={novaObservacao}
                    onChange={(e) => setNovaObservacao(e.target.value)}
                    multiline
                    placeholder="Novo comentário"
                  />
                </Grid>
                <Grid item xs={3}>
                  <ActionButton
                    title="Adicionar novo comentário"
                    color="green"
                    action={handleAdicionarComentarioServico}
                    fullWidth
                    marginTop={8}
                  />
                </Grid>
              </Grid>
            </Box>
            <ModalUploadDocumentoServico
              aberto={isModalUploadDocumentoAberto}
              handleClose={handleOpenModalUploadDocumento}
              servicoId={servicoId}
              atualizarServico={getServico}
            />
          </Grid>
        </JumboContentLayout>
      )}
    </Box>
  );
};

export default ServicoContratoDetalhes;
