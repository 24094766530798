import { useJumboTheme } from "@jumbo/hooks";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  Stack,
  Toolbar,
  useMediaQuery,
  TextField,
  InputAdornment,
  Card,
  Typography,
  Tooltip,
} from "@mui/material";
import ApiService from "app/services/config";
import React, { useState, useEffect, useCallback, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import PageHeader from "app/layouts/shared/headers/PageHeader/PageHeader";
import { LoadingButton } from "@mui/lab";
import MUIDataTable from "mui-datatables";
import { PermissionContext } from "app/contexts/PermissionContext";
import ActionButton from "app/components/ActionButton/ActionButton";
import QueueIcon from '@mui/icons-material/Queue';
import Div from "@jumbo/shared/Div";
import SearchIcon from "@mui/icons-material/Search";
import MenuIcon from "@mui/icons-material/Menu";
import PaginationComponent from "app/components/Pagination/Pagination";
import EditIcon from "@mui/icons-material/Edit";

const StatusOrcamentoList = () => {
  const { theme } = useJumboTheme();
  const location = useLocation();
  const [forceUpdate, setForceUpdate] = useState(false);
  const [statusOrcamento, setStatusOrcamento] = useState([]);
  const [selectedStatusOrcamentoIds, setSelectedStatusOrcamentoIds] = useState(
    []
  );
  const [idsSelecionados, setIdsSelecionados] = useState([]);
  const [todosItensSelecionados, setTodosItensSelecionados] = useState(false);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState("id");
  const [sortOrder, setSortOrder] = useState("asc");
  const [searchTerm, setSearchTerm] = useState("");
  const [count, setCount] = useState("");
  const [inputValue, setInputValue] = useState('');
  
  const navigate = useNavigate();
  const { hasPermission } = useContext(PermissionContext);

  if (!hasPermission("Administrativo", "read")) {
    navigate("/app");
  }
  const permissionUpdate = hasPermission("Administrativo", "update");
  const permissionDelete = hasPermission("Administrativo", "delete");
  
  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            zIndex: 5,
            top: 96,
            minHeight: "auto",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: "flex-start",
        },
      },
    }),
    [theme]
  );

  const Swal = useSwalWrapper();
  const toast = (variant, message) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true, // botão de fechar
      closeButtonAriaLabel: "Fechar",
      timer: 3000
    });
  };

  const modalAlert = (id) => {
    Swal.fire({
      title: "Tem certeza que deseja apagar?",
      text: "Não será póssível reverter a ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteStatusOrcamento(id);
      }
    });
  };

  const handleSearch = async (newSearchTerm) => {
    if (searchTerm === "" || searchTerm === null || searchTerm === undefined) {
      setPage(1);
    }
    setSearchTerm(newSearchTerm);
    setPage(0);
  };

  const deleteStatusOrcamento = useCallback(async (id) => {
    try {
      ApiService.delete(`/statusorcamento/${id}`)
        .then((response) => {
          if (response.status === 200) {
            toast("success", "Removido com sucesso");
            setPerPage(10);
            setForceUpdate((prev) => !prev);
            getStatusOrcamento();
          }
        })
        .catch((error) => {
          const message = error.response.data.message;
          toast("error", message);
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getStatusOrcamento = useCallback(async () => {
    try {
      let apiUrl = `statusorcamento?page=${
        page + 1
      }&perPage=${perPage}&sortBy=${sortBy}&sortOrder=${sortOrder}`;

      if (searchTerm) {
        apiUrl += `&searchTerm=${searchTerm}`;
      }
      ApiService.get(apiUrl)
        .then((response) => {
          console.log(response);
          const initialData = response.data?.status?.map((statusOrcamentos) => ({
            ...statusOrcamentos,
            isSelected: selectedStatusOrcamentoIds.includes(statusOrcamento.id),
          }));
          setStatusOrcamento(initialData);
          setCount(response.data.count);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, [
    page,
    perPage,
    sortBy,
    sortOrder,
    searchTerm,
    selectedStatusOrcamentoIds,
  ]);

  useEffect(() => {
    getStatusOrcamento();
  }, [getStatusOrcamento]);


  const handleDeleteMuitosItens = () => {
    Swal.fire({
      title: "Tem certeza que deseja apagar os itens selecionados?",
      text: "Não será póssível reverter a ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteManyItens();
      }
    });
  };

  const deleteManyItens = async () => {
    try {
      await ApiService.put('/statusorcamento/deletar/varios', idsSelecionados)
      .then((response) => {
        if(response.status === 200) {
          toast("success", "Itens deletados com sucesso!", "success");
          getStatusOrcamento();
          setIdsSelecionados([]);
        }
      })
    } catch (error) {
      toast("error", "Ocorreu um erro", "error");
      console.log(error);
    }
  };

  const handleSelecionarTodosItens = () => {
    const novosIdsSelecionados = todosItensSelecionados
      ? []
      : statusOrcamento.map((status) => status.id);
    setIdsSelecionados(novosIdsSelecionados);
    setTodosItensSelecionados(!todosItensSelecionados);
  
    setStatusOrcamento((prevItems) =>
      prevItems.map((item) => ({
        ...item,
        isSelected: !todosItensSelecionados,
      }))
    );
  };


  const handleCheckboxChange = (itemId) => {
    setStatusOrcamento((prevItems) =>
      prevItems.map((item) =>
        item.id === itemId ? { ...item, isSelected: !item.isSelected } : item
      )
    );

    setIdsSelecionados((prevSelectedIds) => {
      if (prevSelectedIds.includes(itemId)) {
        return prevSelectedIds.filter((id) => id !== itemId);
      } else {
        return [...prevSelectedIds, itemId];
      }
    });
  };

  const columns = [
    {
      name: "selecionar",
      label: "Selecionar",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const id = tableMeta.rowData[1];
          const item = statusOrcamento.find((item) => item.id === id);
          const isSelected = item ? item.isSelected : false;
          return (
            <Checkbox
              checked={isSelected}
              onChange={() => handleCheckboxChange(id)}
            />
          );
        },
      },
    },
    {
      name: "id",
      label: "Id",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "descricao",
      label: "Descrição",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "action",
      label: "Ações",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const orcamentoId = tableMeta.rowData[1];
          return (
            <Box display={"flex"} gap={"1rem"}>
              <Link
                to={`/app/editar-status-orcamento/${orcamentoId}`}
                style={{ textDecoration: "none" }}
              >
                <Button
                  color="info"
                  variant="contained"
                  size="small"
                  disableElevation
                >
                  <VisibilityIcon />
                </Button>
              </Link>
              <Button
                color="error"
                variant="contained"
                size="small"
                onClick={() => modalAlert(orcamentoId)}
              >
                <DeleteIcon />
              </Button>
            </Box>
          );
        },
      },
    },
  ];

  const options = {
    changeRowsPerPage: perPage,
    filterType: "dropdown",
    filter: false,
    selectableRows: "none",
    searchAlwaysOpen: true,
    searchable: true,
    serverSide: true,
    page: page,
    count: count,
    selectToolbarPlacement: "above",

    onTableChange: (action, tableState) => {
      console.log(action);
      switch (action) {
        case "changePage":
          setPage(tableState.page);
          break;
        case "sort":
          setSortBy(tableState.sortOrder.name);
          setSortOrder(tableState.sortOrder.direction);
          break;
        case "search":
          handleSearch(tableState.searchText);
          break;
        case "changeRowsPerPage":
          setPerPage(tableState.rowsPerPage);
          break;
        default:
          console.log("action not handled.");
      }
    },
    customToolbar: ({ displayData }) => (
      <>
        {selectedStatusOrcamentoIds.length > 0 ? (
          <Toolbar>
            <Box
              display={"flex"}
              gap={"1rem"}
              justifyContent={"flex-end"}
              width={"100%"}
            ></Box>
          </Toolbar>
        ) : null}
      </>
    ),
    textLabels: {
      body: {
        noMatch: "Nenhum resultado encontrado",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
      },
      pagination: {
        next: "Próxima Página",
        previous: "Página Anterior",
        rowsPerPage: "Itens por Página:",
        displayRows: "of",
      },
    },
  };

  return (
    <JumboContentLayout
      // header={<PageHeader title={"Status de Orçamento"} />}
      header={
        <PageHeader
          title={"Status de Orçamento"}
          icon="desc"
          btn={<ActionButton title="Novo status de orçamento" to="/app/novo-status-orcamento" color="green" icon={<QueueIcon />}/>}
        />}
      layoutOptions={layoutOptions}
    >
      {lg && (
        <Stack spacing={2} direction={"row"} sx={{ mb: 3, mt: -2 }}></Stack>
      )}

      {/* <Grid item xs={12} sx={{ textAlign: "center" }}>
        <Link
          to="/app/novo-status-orcamento"
          state={{ backUrl: location.pathname }}
          style={{ textDecoration: "none" }}
        >
          <LoadingButton
            color="success"
            type="submit"
            variant="contained"
            size="large"
            sx={{
              maxWidth: { md: "450px", mt: "1rem" },
              marginBottom: "2rem",
            }}
          >
            Novo Status de Orçamento
          </LoadingButton>
        </Link>
      </Grid>
      <Stack my={2} direction={"row"} gap={2}>
        {idsSelecionados.length > 0 && (
          <Grid item>
            <span style={{ margin: 10 }}>
              {idsSelecionados.length} Status de Orçamento (s) selecionado(s)
            </span>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<DeleteIcon />}
              onClick={handleDeleteMuitosItens}
            >
              Excluir Selecionados
            </Button>
          </Grid>
        )}
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSelecionarTodosItens}
          >
            {todosItensSelecionados ? "Desmarcar Todos" : "Selecionar Todos"}
          </Button>
        </Grid>
      </Stack>
      <React.Fragment>
        <MUIDataTable
          key={forceUpdate ? "force-update" : "normal"}
          title={"Status de Orçamento"}
          data={statusOrcamento}
          columns={columns}
          options={options}
        />
      </React.Fragment> */}

      <Grid item xs={12} sx={{ textAlign: "center" }}>
        <React.Fragment>
          <Grid
            container
            gap={1}
            style={{ background: "white", minHeight: "120px" }}
          >

          <Div
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
          <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
              }}
            >
              <TextField
                variant="standard"
                placeholder="Pesquisar..."
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch(inputValue);
                  }
                }}
                InputProps={{
                  sx: {
                    borderRadius: 2,
                    border: '1px solid #4E5526',
                    paddingLeft: 2,
                    height: '36px',
                    '&:hover': { borderColor: '#3B4220' },
                    '&.Mui-focused': { borderColor: '#4E5526' },
                    color: '#4E5526',
                    '&::placeholder': { color: 'red', opacity: 1 },
                    width: 350,
                  },
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        sx={{
                          backgroundColor: '#4E5526',
                          borderRadius: 2,
                          minWidth: '36px',
                          height: '36px',
                          padding: 0,
                          '&:hover': { backgroundColor: '#3B4220' },
                        }}
                        onClick={() => handleSearch(inputValue)}
                      >
                        <SearchIcon sx={{ color: '#fff', fontSize: 20 }} />
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
              {idsSelecionados.length > 0 && (
                <Button
                  variant="contained"
                  color="error"
                  startIcon={<DeleteIcon />}
                  onClick={handleDeleteMuitosItens}
                  sx={{
                    borderRadius: 2,
                    paddingX: 2,
                    backgroundColor: '#C84E4D',
                    textTransform: "none",
                  }}
                >
                  Excluir {idsSelecionados.length} setor(es)
                </Button>
              )}
            </Box>
          </Div>

          <Card
            sx={{
              p: 2,
              width: '100%',
              minHeight: 100,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: 'none',
              backgroundColor: 'transparent',
            }}
          >
            
            <Grid
              container
              // justifyContent="space-between"
              alignItems="center"
            >
              <Grid item lg={1}>
                <Checkbox
                  sx={{
                    color: '#696969',
                    '&.Mui-checked': {
                      color: '#95A64A',
                    },
                  }}
                  onChange={handleSelecionarTodosItens}
                />
              </Grid>
              <Grid item lg={9} sx={{ display: 'flex' }}>
                <Box>
                  <Typography fontSize={24} fontWeight={600}>Descrição</Typography>
                </Box>
              </Grid>
              <Grid item lg={2} sx={{ display: 'flex' }}>
                <Box>
                  <Typography fontSize={24} fontWeight={600}>Ações</Typography>
                </Box>
              </Grid>
            </Grid>
          </Card>
      </Grid>

      <React.Fragment>
        <Grid container gap={1}>
            {statusOrcamento.length > 0 ? (
              <>
                {statusOrcamento.map((c, key) => (
                  <Card
                    sx={{
                      p: 2,
                      width: '100%',
                      minHeight: 100,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                    key={key}
                  >
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      // gap={2}
                    >
                      <Grid item lg={1}>
                        <Checkbox
                          sx={{
                            color: '#696969',
                            '&.Mui-checked': {
                              color: '#95A64A',
                            },
                          }}
                          key={c.id}
                          checked={c.isSelected}
                          onChange={() => handleCheckboxChange(c.id)}
                        />
                      </Grid>
                      <Grid item lg={9}>
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Typography fontSize={18} sx={{ display: 'flex' }}>
                            {c.descricao}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={2}>
                        <Box display={"flex"} gap={"1rem"}>
                        {/* <Link
                          to={`/app/editar-status-orcamento/${c.id}`}
                          style={{ textDecoration: "none" }}
                        >
                          <Button
                            color="info"
                            variant="contained"
                            size="small"
                            disableElevation
                          >
                            <VisibilityIcon />
                          </Button>
                        </Link>
                        <Button
                          color="error"
                          variant="contained"
                          size="small"
                          onClick={() => modalAlert(c.id)}
                        >
                          <DeleteIcon />
                        </Button> */}
                          <Tooltip title="Editar">
                            <EditIcon
                              cursor="pointer"
                              color="success"
                              onClick={() => {
                                if (permissionUpdate) {
                                  navigate(`/app/editar-status-orcamento/${c.id}`);
                                }
                              }}
                            />
                          </Tooltip>

                          <Tooltip title="Excluir">
                            <DeleteIcon 
                              cursor={permissionDelete ? "pointer" : "not-allowed"}
                              color="error"
                              style={{ opacity: permissionDelete ? 1 : 0.5 }}
                              onClick={() => {
                                if (permissionDelete) {
                                  modalAlert(c.id);
                                }
                              }}
                            />
                          </Tooltip>

                        </Box>
                      </Grid>
                    </Grid>
                  </Card>
                ))}
              </>
            ) : (
              <Grid container>
                <Grid item style={{ width: "100%" }}>
                  <Box my={5} textAlign={"center"}>
                    <Typography>Nenhum status de orçamento cadastrado.</Typography>
                  </Box>
                </Grid>
              </Grid>
            )}

            <PaginationComponent
              page={page}
              setPage={setPage}
              count={count}
              perPage={perPage}
              setPerPage={setPerPage}
            />
        </Grid>
      </React.Fragment>
      </React.Fragment>
      </Grid>
    </JumboContentLayout>
  );
};

export default StatusOrcamentoList;
