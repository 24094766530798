import React, {
  useState,
  useCallback,
  useEffect,
  useContext,
  useLayoutEffect,
  useRef,
} from "react";
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import {
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  Select,
  Avatar,
  Box,
  Button,
  IconButton,
  Zoom,
  Autocomplete,
  Tooltip,
  FormControl,
  FormGroup,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
  Alert,
  InputAdornment,
  Checkbox,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import * as yup from "yup";
import { ErrorMessage, Form, Formik } from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { ApiService } from "app/services/config";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { updateInputValue } from "../../utils/appHelpers";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import HeaderBreadcrumbs from "app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs";
import makeAnimated from "react-select/animated";
import ReactInputMask from "react-input-mask";
import { Delete, PhotoCamera } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import { PermissionContext } from "app/contexts/PermissionContext";
import CreatableSelect from "react-select/creatable";
import { consultarCEP } from "app/utils/consultarCEP";
import axios from "axios";
import PageHeader from "app/layouts/shared/headers/PageHeader/PageHeader";
import DescriptionIcon from "@mui/icons-material/Description";
import InputAmbiental from "@jumbo/components/InputAmbiental";
import SelectAmbiental from "@jumbo/components/SelectAmbiental";
import ActionButton from "app/components/ActionButton/ActionButton";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import BadgeNomeCliente from "@jumbo/components/BadgeNomeCliente/BadgeNomeCliente";
import BotaoVoltarBreadCrumbs from "app/components/BotaoVoltarBreadCrumbs";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "transparent" : "white",
    border: state.isFocused
      ? "1px solid #005D5F"
      : "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "4px",
    padding: "8px",
    boxShadow: "none",
    "&:hover": {
      borderColor: "black",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#005D5F" : "white",
    color: state.isSelected ? "white" : "black",
    "&:hover": {
      backgroundColor: "lightblue",
      color: "white",
    },
    zIndex: 9999,
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
};

const ESTADOS = [
  { sigla: "AC", nome: "Acre" },
  { sigla: "AL", nome: "Alagoas" },
  { sigla: "AP", nome: "Amapá" },
  { sigla: "AM", nome: "Amazonas" },
  { sigla: "BA", nome: "Bahia" },
  { sigla: "CE", nome: "Ceará" },
  { sigla: "DF", nome: "Distrito Federal" },
  { sigla: "ES", nome: "Espírito Santo" },
  { sigla: "GO", nome: "Goiás" },
  { sigla: "MA", nome: "Maranhão" },
  { sigla: "MT", nome: "Mato Grosso" },
  { sigla: "MS", nome: "Mato Grosso do Sul" },
  { sigla: "MG", nome: "Minas Gerais" },
  { sigla: "PA", nome: "Pará" },
  { sigla: "PB", nome: "Paraíba" },
  { sigla: "PR", nome: "Paraná" },
  { sigla: "PE", nome: "Pernambuco" },
  { sigla: "PI", nome: "Piauí" },
  { sigla: "RJ", nome: "Rio de Janeiro" },
  { sigla: "RN", nome: "Rio Grande do Norte" },
  { sigla: "RS", nome: "Rio Grande do Sul" },
  { sigla: "RO", nome: "Rondônia" },
  { sigla: "RR", nome: "Roraima" },
  { sigla: "SC", nome: "Santa Catarina" },
  { sigla: "SP", nome: "São Paulo" },
  { sigla: "SE", nome: "Sergipe" },
  { sigla: "TO", nome: "Tocantins" },
];

const validationSchema = yup.object().shape({
  nome: yup
    .string("Insira o nome do cliente")
    .required("Preenchimento obrigatório"),
  email: yup
    .string("Preenchimento obrigatório")
    .required("Preenchimento obrigatório"),
  tipo_pessoa: yup.string("Campo obrigatório").required("Campo obrigatório"),
  cpf: yup
    .string()
    .nullable()
    .when("tipo_pessoa", {
      is: "fisica",
      then: yup
        .string()
        .required("CPF é obrigatório para pessoa física")
        .test("valid-cpf-length", "CPF inválido", (value) => {
          if (!value) return true;
          return value.replace(/\D/g, "").length === 11;
        }),
    })
    .typeError("CPF Inválido"),
  cnpj: yup
    .string()
    .nullable()
    .when("tipo_pessoa", {
      is: "juridica",
      then: yup
        .string()
        .required("CNPJ é obrigatório para pessoa jurídica")
        .test("valid-cnpj-length", "CNPJ inválido", (value) => {
          if (!value) return true;
          return value.replace(/\D/g, "").length === 14;
        }),
    })
    .typeError("CNPJ Inválido"),
  telefone: yup
    .string()
    .nullable()
    .test("valid-phone-length", "Telefone inválido", (value) => {
      if (!value) return true;
      const digits = value?.replace(/\D/g, "");
      return digits && (digits.length === 10 || digits.length === 11);
    })
    .typeError("Telefone inválido"),
  cep: yup
    .string()
    .nullable()
    .test("valid-cep-length", "CEP inválido", (value) => {
      if (!value) return true;
      return value.replace(/\D/g, "").length === 8;
    })
    .typeError("CEP Inválido"),
  segmento_cliente: yup
    .number("ID Inválido")
    .required("Selecione um Segmento de Cliente"),
  password: yup.string().required("Preenchimento obrigatório"),
  passwordConfirm: yup
    .string()
    .oneOf([yup.ref("password"), null], "As senhas devem ser iguais")
    .required("Preenchimento obrigatório"),
  avatarUrl: yup.string().nullable(),
});

const validationSchemaEdit = yup.object().shape({
  nome: yup
    .string("Insira o nome do cliente")
    .required("Preenchimento obrigatório"),
  email: yup
    .string("Preenchimento obrigatório")
    .required("Preenchimento obrigatório"),
  telefone: yup
    .string()
    .nullable()
    .test("valid-phone-length", "Telefone inválido", (value) => {
      if (!value) return true;
      const digits = value?.replace(/\D/g, "");
      return digits && (digits.length === 10 || digits.length === 11);
    })
    .typeError("Telefone inválido"),
  cpf: yup
    .string()
    .nullable()
    .test("valid-cpf-length", "CPF inválido", (value) => {
      if (!value) return true;
      return value.replace(/\D/g, "").length === 11;
    }),
  cnpj: yup
    .string()
    .nullable()
    .test("valid-cnpj-length", "CNPJ inválido", (value) => {
      if (!value) return true;
      return value.replace(/\D/g, "").length === 14;
    }),
  cep: yup
    .string()
    .nullable()
    .test("valid-cep-length", "CEP inválido", (value) => {
      if (!value) return true;
      return value.replace(/\D/g, "").length === 8;
    })
    .typeError("CEP Inválido"),
  segmento_cliente: yup
    .number("ID Inválido")
    .required("Selecione um Segmento de Cliente"),
  avatarUrl: yup.string().nullable(),
  password: yup.string().nullable(),
  passwordConfirm: yup.string().when("password", {
    is: (password) => !!password,
    then: yup
      .string()
      .oneOf([yup.ref("password"), null], "As senhas devem ser iguais")
      .required("Preenchimento obrigatório quando a senha é fornecida"),
    otherwise: yup.string().nullable(),
  }),
});

const UsuariosClienteForm = () => {
  const initialValues = {
    nome: "",
    email: "",
    password: "",
    passwordConfirm: "",
    cpf: "",
    cnpj: "",
    bairro: "",
    logradouro: "",
    cidade: "",
    numero: "",
    complemento: "",
    uf: "",
    avatarUrl: "",
    telefone: "",
    tipo_pessoa: "fisica",
    tipo_telefone: "celular",
    user_type: 2,
    segmento_cliente: "",
    cep: "",
  };
  const { id } = useParams();
  const [user, setUser] = useState(initialValues);
  const [previewFotoPerfil, setPreviewFotoPerfil] = useState(null);
  const [erroUploadFotoPerfil, setErroUploadFotoPerfil] = useState({
    status: false,
    mensagem: "",
  });
  const [isDeleteMode, setIsDeleteMode] = useState(false);
  const [roles, setRoles] = useState([]);
  const [activitiesSelected, setActivitiesSelected] = useState(null);
  const [telefoneComMascara, setTelefoneComMascara] = useState("");
  const animatedComponents = makeAnimated();
  const Swal = useSwalWrapper();
  const navigate = useNavigate();
  const [formattedCPF, setFormattedCPF] = useState("");
  const [formattedCNPJ, setFormattedCNPJ] = useState("");
  const [formattedCEP, setFormatedCEP] = useState("");
  const [formattedTelefone, setFormatedTelefone] = useState("");
  const [estados, setEstados] = useState([]);
  const [municipios, setMunicipios] = useState([]);
  const [segmentoSelected, setSegmentoSelected] = useState(null);
  const [segmentosCliente, setSegmentosCliente] = useState([]);
  const ROLE_ID = Number(localStorage.getItem("role_id"));
  const USER_ID = Number(localStorage.getItem("cliente_id"));
  const [mascaraTelefone, setMascaraTelefone] = useState("(99) 99999-9999");
  const [isDadosCarregados, setIsDadosCarregados] = useState(false);
  const [idsPerfisAdmins, setIdsPerfisAdmins] = useState([]);

  const { theme } = useJumboTheme();
  const location = useLocation();
  const formikRef = useRef();

  const { hasPermission } = useContext(PermissionContext);

  if (!hasPermission("Clientes", "create")) {
    navigate("/app/listar-clientes");
  }

  const handleTelefoneChange = (tipo) => {
    if (tipo === "celular") {
      setMascaraTelefone("(99) 99999-9999");
    } else {
      setMascaraTelefone("(99) 9999-9999");
    }
    formikRef.current.setFieldValue("telefone", formattedTelefone);
  };

  const handleRedirectClienteNaoAutorizado = () => {
    if (ROLE_ID === 2 && Number(id) !== USER_ID) {
      navigate("/");
    }
  };

  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            zIndex: 5,
            top: 96,
            minHeight: "auto",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: "flex-start",
        },
      },
    }),
    [theme]
  );

  const toast = (variant, message, type = false) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true, // botão de fechar
      closeButtonAriaLabel: "Fechar",
      timer: 3000,
    });
  };

  const getUsers = useCallback(async () => {
    try {
      ApiService.get(`/clientes/cliente/${id}`)
        .then((response) => {
          const { user, enderecos, cpf, cnpj, telefone, tipo_telefone } =
            response.data;
          const transformedUser = {
            password: "",
            email: user[0]?.user?.email,
            nome: user[0]?.user?.name,
            avatarUrl: user[0]?.user?.avatarUrl,
            telefone,
            cpf: response?.data?.cpf,
            cnpj: response?.data?.cnpj,
            tipo_pessoa: response?.data?.tipo_pessoa,
            tipo_telefone: response?.data?.tipo_telefone,
            bairro: enderecos[0]?.vizinhanca,
            logradouro: enderecos[0]?.rua,
            cidade: enderecos[0]?.cidade,
            numero: enderecos[0]?.numero,
            complemento: enderecos[0]?.complemento,
            cep: enderecos[0]?.cep,
            uf: enderecos[0]?.uf,
            segmento_cliente: response.data?.segmento_cliente?.id,
            user_id: Number(user[0]?.user?.id),
          };

          handleTelefoneChange(tipo_telefone);
          setUser(transformedUser);
          setTelefoneComMascara(telefone);
          setFormattedCPF(cpf);
          setFormattedCNPJ(cnpj);
          setFormatedCEP(enderecos[0]?.cep);
          setSegmentoSelected({
            id: response.data?.segmento_cliente?.id,
            descricao: response?.data.segmento_cliente?.descricao,
          });
          if (response.data.avatarUrl) {
            setPreviewFotoPerfil(
              `${process.env.REACT_APP_API_KEY}/public/uploads/${response.data.avatarUrl}`
            );
          }
          setActivitiesSelected(response.data.roles[0]?.role_id);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getRoles = useCallback(async () => {
    try {
      ApiService.get(`/roles`)
        .then((response) => {
          console.log(response.data);
          setRoles(response.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const optionsRoles = roles?.map((result) => {
    return {
      value: result.id,
      label: result.name,
    };
  });

  const modalAlert = () => {
    Swal.fire({
      title: "Tem certeza que deseja apagar?",
      text: "Não será póssível reverter a ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        handleRemoveFotoPerfil();
      }
    });
  };

  const modalAlertDeleteCliente = (id) => {
    Swal.fire({
      title: "Tem certeza que deseja apagar o cliente?",
      text: "Não será póssível reverter a ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteUser(id);
      }
    });
  };

  const deleteUser = useCallback(async (id) => {
    try {
      ApiService.delete(`/clientes/${id}`)
        .then((response) => {
          toast("success", "Removido com sucesso");
          navigate(`/app/listar-clientes`);
        })
        .catch((error) => {
          const message = error.response.data.message;
          toast("error", message);
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handlePreviewFotoPerfil = async (event) => {
    event.preventDefault();
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const reader = new FileReader();

      if (
        selectedFile.type !== "image/jpeg" &&
        selectedFile.type !== "image/png"
      ) {
        setPreviewFotoPerfil(null);
        setErroUploadFotoPerfil({
          status: true,
          mensagem: "Formato de imagem inválido. Só é permitido .jpeg ou .png",
        });
        console.log("Tipo de imagem inválida", selectedFile.type);
        return;
      } else {
        setErroUploadFotoPerfil({
          status: false,
          mensagem: "",
        });

        reader.onloadend = () => {
          setPreviewFotoPerfil(reader.result);
        };

        reader.readAsDataURL(selectedFile);
      }
    }
  };

  const handleUploadImagemPerfil = async (event) => {
    const fotoPerfil = event.target.files[0];
    const formData = new FormData();
    formData.append("foto", fotoPerfil);
    try {
      await ApiService.put(
        `users/upload/foto/perfil/${user?.user_id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      ).then((response) => {
        if (response.status === 200) {
          toast("success", "Foto de perfil atualizada!");
          setUser((prevUser) => ({
            ...prevUser,
            avatarUrl: response.data.avatarUrl,
          }));
          localStorage.setItem("avatarUrl", response.data.avatarUrl);
        }
      });
    } catch (error) {
      console.error("Erro ao fazer upload da imagem: ", error);
    }
  };

  const handleRemoveFotoPerfil = async () => {
    setPreviewFotoPerfil(null);
    setErroUploadFotoPerfil({
      status: false,
      mensagem: "",
    });
    try {
      await ApiService.put(`/users/foto/remover/${id}`).then((response) => {
        if (response.status === 200) {
          toast("success", "Foto removida com sucesso!");
          setPreviewFotoPerfil(null);
          setUser((prevUser) => ({
            ...prevUser,
            avatarUrl: null,
          }));
        }
      });
    } catch (error) {
      console.log(error);
      toast("error", "Erro ao remover a imagem de perfil");
    }
  };

  const getEstados = useCallback(async () => {
    try {
      await axios
        .get("https://servicodados.ibge.gov.br/api/v1/localidades/estados")
        .then((response) => {
          if (response.status === 200) {
            setEstados(response.data);
          }
        });
    } catch (error) {
      console.log(error.message);
    }
  }, []);

  const getMunicipios = useCallback(
    async (sigla) => {
      await axios
        .get(
          `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${sigla}/municipios
  `
        )
        .then((response) => {
          if (response.status === 200) {
            setMunicipios(response.data);
          }
        });
    },
    [estados]
  );

  const handleChangeCEP = async (cep) => {
    formikRef.current.setFieldValue("cep", cep);
    if (cep.length === 9) {
      try {
        const cepConsultado = await consultarCEP(cep);
        if (cepConsultado) {
          formikRef.current.setFieldValue(
            "uf",
            cepConsultado.dados.stateShortname
          );
          await getMunicipios(cepConsultado.dados.stateShortname);
          formikRef.current.setFieldValue("cidade", cepConsultado.dados.city);
          formikRef.current.setFieldValue(
            "logradouro",
            cepConsultado.dados.street
          );
          formikRef.current.setFieldValue(
            "bairro",
            cepConsultado.dados.district
          );
        }
        setFormatedCEP(
          String(cep)
            .slice(0, 5)
            .concat(`-${String(cep).slice(5, 9)}`)
        );
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleSubmit = async (
    values,
    setSubmitting,
    resetForm,
    setFieldValue
  ) => {
    if (Object.entries(formikRef.current.errors)?.length > 0) {
      toast("warning", "Corrija os erros no formulário!");
      return;
    }
    let allValuesEdit = {
      ...values,
      user_type: 2,
    };
    delete allValuesEdit.servicoId;
    delete allValuesEdit.dados_cliente;
    allValuesEdit.endereco = {
      uf: allValuesEdit.uf,
      cidade: allValuesEdit.cidade,
      numero: allValuesEdit.numero,
      vizinhanca: allValuesEdit.bairro,
      complemento: allValuesEdit.complemento,
      rua: allValuesEdit.logradouro,
      cep: allValuesEdit.cep,
    };
    allValuesEdit.segmento_cliente = Number(values.segmento_cliente);

    delete allValuesEdit.uf;
    delete allValuesEdit.cidade;
    delete allValuesEdit.numero;
    delete allValuesEdit.vizinhanca;
    delete allValuesEdit.complemento;
    delete allValuesEdit.logradouro;
    delete allValuesEdit.cep;
    delete allValuesEdit.bairro;
    delete allValuesEdit.passwordConfirm;
    delete allValuesEdit.avatarUrl;
    if (id) {
      ApiService.put(`/clientes/${id}`, allValuesEdit)
        .then((response) => {
          if (response.status === 200) {
            toast("success", "Atualizado com sucesso");
            resetForm();
            navigate("/app/listar-clientes");
          }
        })
        .catch((error) => {
          let message = error?.response?.data?.message;
          toast("error", message);

          if (error?.response?.data) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } else {
      await ApiService.post("/clientes", allValuesEdit)
        .then((response) => {
          if (response.status === 201) {
            toast("success", "Criado com sucesso");
            resetForm();
            navigate("/app/listar-clientes");
          }
        })
        .catch((error) => {
          let message = error.response.data.message;
          toast("error", message);

          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    }
    setSubmitting(false);
  };

  const getSegmentosCliente = useCallback(async () => {
    try {
      const response = await ApiService.get("/segmentos/all");
      setSegmentosCliente(response.data);
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleCreateSegmentoCliente = useCallback(
    async (descricao) => {
      try {
        const response = await ApiService.post("/segmentos/", { descricao });
        if (response.status === 201) {
          toast("success", "Criado com sucesso!");
          const novoSegmento = {
            id: response.data.id,
            descricao: response.data.descricao,
          };
          setSegmentosCliente([...segmentosCliente, novoSegmento]);
          setSegmentoSelected(novoSegmento);
          return novoSegmento;
        }
        return response;
      } catch (error) {
        console.log(error);
      }
    },
    [segmentosCliente]
  );

  useEffect(() => {
    setErroUploadFotoPerfil(erroUploadFotoPerfil);
  }, [erroUploadFotoPerfil]);

  useEffect(async () => {
    await Promise.all([getRoles(), getSegmentosCliente(), getEstados()]);
    if (id) await getUsers();
    if (location && String(location?.pathname).includes("deletar")) {
      setIsDeleteMode(true);
    }
    setIsDadosCarregados(true);
  }, [id]);

  const optionsSegmentos = segmentosCliente?.map((segmento) => {
    return {
      id: segmento.id,
      descricao: segmento.descricao,
    };
  });

  useLayoutEffect(() => {
    handleRedirectClienteNaoAutorizado();
  }, []);

  useEffect(() => {
    if (id && formikRef.current && user && Object.keys(user).length > 0) {
      formikRef.current.setFieldValue("nome", user?.nome);
      formikRef.current.setFieldValue("email", user?.email);
      formikRef.current.setFieldValue(
        "segmento_cliente",
        user?.segmento_cliente
      );
    }
  }, [isDadosCarregados, user, formikRef.current]);

  const getPerfisAdministrativos = async () => {
      try {
        await ApiService.get('/roles/admin')
        .then((response) => {
          if(response.status === 200) {
            const IDS = response?.data?.map(perfil => perfil.id);
            setIdsPerfisAdmins(IDS);
          }
        })
      } catch (error) {
        console.log(error);
      }
    }
  
    useEffect(() => {
      getPerfisAdministrativos()
    }, [])

  return isDadosCarregados ? (
    <Box sx={{
      position: "relative",
    }}>
      {(idsPerfisAdmins?.includes(ROLE_ID) ||
        (ROLE_ID === 2 && USER_ID === Number(id))) && (
        <JumboContentLayout layoutOptions={layoutOptions}>
          <BotaoVoltarBreadCrumbs
            handleBack={() => {
              navigate(`/app/listar-clientes`);
            }}
          />
          <PageHeader
            title={
              id && user
                ? `${user?.nome} > Editar`
                : "Clientes > Cadastro de Clientes"
            }
            icon={
              <DescriptionIcon
                sx={{ color: "#4E5526", marginRight: 1, fontSize: 40 }}
              />
            }
          />

          {lg && (
            <Stack spacing={2} direction={"row"} sx={{ mb: 3, mt: -2 }}></Stack>
          )}
          {isDeleteMode && (
            <Stack width={"100%"} p={1}>
              <Alert severity="error">
                Você está prestes a excluir o cliente abaixo:
              </Alert>
            </Stack>
          )}
            <Formik
              innerRef={formikRef}
              initialValues={user}
              validationSchema={id ? validationSchemaEdit : validationSchema}
              enableReinitialize={true}
              validateOnChange={false}
              validateOnBlur
              onSubmit={handleSubmit}
            >
              {({
                values,
                isSubmitting,
                setFieldValue,
                handleChange,
                handleBlur,
                errors,
                setSubmitting,
                resetForm,
                setFieldTouched,
                touched,
              }) => (
                <Form style={{ width: "100%" }}>
                  <Grid
                    container
                    spacing={2}
                    padding={0}
                    alignContent={"center"}
                    justifyContent={"center"}
                    display={"flex"}
                    width={"100%"}
                  >
                    {id ? (
                      <Grid item xs={2} marginTop={-3}>
                        <form
                          style={{
                            display: "flex",
                            height: "auto",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <Box
                            width={"max-content"}
                            display={"flex"}
                            flexDirection={"column"}
                            alignItems={"center"}
                          >
                            <Avatar
                              alt="Foto do perfil"
                              src={
                                user.avatarUrl
                                  ? `${process.env.REACT_APP_API_KEY}/public/uploads/${user.avatarUrl}`
                                  : previewFotoPerfil
                              }
                              sx={{
                                width: 140,
                                height: 140,
                                marginTop: 1,
                                bgcolor: "success",
                              }}
                            >
                              {user.name
                                ? user.name.charAt(0).toUpperCase()
                                : "P"}
                            </Avatar>
                          </Box>
                          <Box
                            width={"max-content"}
                            display={"flex"}
                            marginTop={1}
                            justifyContent={"center"}
                            gap={1}
                          >
                            <IconButton
                              color="info"
                              aria-label="upload picture"
                              component="label"
                              disabled={isDeleteMode}
                            >
                              <input
                                name="foto"
                                hidden
                                accept="image/png, image/jpg, image/jpeg"
                                type="file"
                                onChange={(event) => {
                                  handlePreviewFotoPerfil(event);
                                  handleUploadImagemPerfil(event);
                                }}
                              />
                              <PhotoCamera />
                            </IconButton>
                            {(previewFotoPerfil || user?.avatarUrl) && (
                              <>
                                <IconButton onClick={modalAlert}>
                                  <Delete color="error" />
                                </IconButton>
                              </>
                            )}
                          </Box>
                          {erroUploadFotoPerfil.status && (
                            <Box sx={{ textAlign: "center" }}>
                              <Typography color="error">
                                {erroUploadFotoPerfil.mensagem}
                              </Typography>
                            </Box>
                          )}
                        </form>
                      </Grid>
                    ) : null}

                    <Grid item xs={id ? 5 : 6}>
                      <InputAmbiental
                        label={"Nome"}
                        required={true}
                        name="nome"
                        onChange={(event) => {
                          setFieldValue("nome", event.target.value);
                        }}
                        handleBlur={(e) => {
                          handleBlur(e);
                          setFieldTouched("nome", true);
                        }}
                        value={values.nome}
                        fullWidth={true}
                      />
                      {touched.nome && errors.nome && (
                        <div style={{ color: "red" }}>{errors.nome}</div>
                      )}
                    </Grid>
                    <Grid item xs={id ? 5 : 6}>
                      <InputAmbiental
                        label={"Email"}
                        required={true}
                        name="email"
                        onChange={(event) => {
                          setFieldValue("email", event.target.value);
                        }}
                        handleBlur={(e) => {
                          handleBlur(e);
                          setFieldTouched("email", true);
                        }}
                        value={values.email}
                        fullWidth={true}
                        type="email"
                        isRegisteredEmail={id ? true : false}
                      />
                      {touched.email && errors.email && (
                        <div style={{ color: "red" }}>{errors.email}</div>
                      )}
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    padding={0}
                    alignContent={"center"}
                    justifyContent={"center"}
                    display={"flex"}
                    width={"100%"}
                    my={1}
                  >
                    <Grid item xs={3} alignItems={"flex-start"}>
                      <FormGroup>
                        <FormLabel
                          id="tipo-telefone-label"
                          style={{ color: "#000000", fontWeight: "bold" }}
                        >
                          Tipo de telefone
                        </FormLabel>

                        <FormGroup row>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.tipo_telefone.includes(
                                  "celular"
                                )}
                                onChange={(e) => {
                                  const { value, checked } = e.target;
                                  setFieldValue(
                                    "tipo_telefone",
                                    checked ? value : values.tipo_telefone
                                  );
                                }}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  setFieldTouched("tipo_telefone", true);
                                }}
                                name="tipo_telefone"
                                value="celular"
                                color="success"
                                disabled={isDeleteMode}
                              />
                            }
                            label="Celular"
                          />

                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.tipo_telefone.includes(
                                  "telefone"
                                )}
                                onChange={(e) => {
                                  const { value, checked } = e.target;
                                  setFieldValue(
                                    "tipo_telefone",
                                    checked ? value : values.tipo_telefone
                                  );
                                }}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  setFieldTouched("tipo_telefone", true);
                                }}
                                name="tipo_telefone"
                                value="telefone"
                                color="success"
                                disabled={isDeleteMode}
                              />
                            }
                            label="Telefone fixo"
                          />
                        </FormGroup>
                        {touched.tipo_telefone && errors.tipo_telefone && (
                          <div style={{ color: "red" }}>
                            {errors.tipo_telefone}
                          </div>
                        )}
                      </FormGroup>
                    </Grid>
                    <Grid item xs={3}>
                      <InputAmbiental
                        label={"Telefone"}
                        required={false}
                        name="telefone"
                        onChange={handleChange}
                        handleBlur={(e) => {
                          handleBlur(e);
                          setFieldTouched("telefone", true);
                        }}
                        value={values.telefone}
                        fullWidth={true}
                        hasMask={true}
                        maskType={values.tipo_telefone}
                      />
                      {touched.telefone && errors.telefone && (
                        <div style={{ color: "red" }}>{errors.telefone}</div>
                      )}
                    </Grid>
                    <Grid item xs={3}>
                      <InputAmbiental
                        label={"Senha"}
                        required={true}
                        name="password"
                        onChange={handleChange}
                        handleBlur={(e) => {
                          handleBlur(e);
                          setFieldTouched("password", true);
                        }}
                        value={updateInputValue(user, values, "password")}
                        fullWidth={true}
                        type="password"
                      />
                      {touched.password && errors.password && (
                        <div style={{ color: "red" }}>{errors.password}</div>
                      )}
                    </Grid>
                    <Grid item xs={3}>
                      <InputAmbiental
                        label={"Confirmação de senha"}
                        required={true}
                        name="passwordConfirm"
                        onChange={handleChange}
                        handleBlur={(e) => {
                          handleBlur(e);
                          setFieldTouched("passwordConfirm", true);
                        }}
                        value={updateInputValue(
                          user,
                          values,
                          "passwordConfirm"
                        )}
                        fullWidth={true}
                        type="password"
                      />
                      {touched.passwordConfirm && errors.passwordConfirm && (
                        <div style={{ color: "red" }}>
                          {errors.passwordConfirm}
                        </div>
                      )}
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    padding={0}
                    alignContent={"center"}
                    justifyContent={"center"}
                    display={"flex"}
                    width={"100%"}
                    my={1}
                  >
                    <Grid item xs={3} alignItems={"flex-start"}>
                      <FormGroup>
                        <FormLabel
                          id="tipo-pessoa-label"
                          style={{ color: "#000000", fontWeight: "bold" }}
                        >
                          Tipo pessoa
                        </FormLabel>
                        <FormGroup row>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.tipo_pessoa === "fisica"}
                                onChange={(event) => {
                                  const value = event.target.value;
                                  setFieldValue("tipo_pessoa", value);
                                }}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  setFieldTouched("tipo_pessoa", true);
                                }}
                                name="tipo_pessoa"
                                value="fisica"
                                color="success"
                                disabled={isDeleteMode}
                              />
                            }
                            label="Pess. Física"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.tipo_pessoa === "juridica"}
                                onChange={(event) => {
                                  const value = event.target.value;
                                  setFieldValue("tipo_pessoa", value);
                                }}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  setFieldTouched("tipo_pessoa", true);
                                }}
                                name="tipo_pessoa"
                                value="juridica"
                                color="success"
                                disabled={isDeleteMode}
                              />
                            }
                            label="Pess. Jurídica"
                          />
                        </FormGroup>
                        {touched.tipo_pessoa && errors.tipo_pessoa && (
                          <div style={{ color: "red" }}>
                            {errors.tipo_pessoa}
                          </div>
                        )}
                      </FormGroup>
                    </Grid>
                    <Grid item xs={3}>
                      <InputAmbiental
                        label={values.tipo_pessoa === "fisica" ? "CPF" : "CNPJ"}
                        required={false}
                        name={values.tipo_pessoa === "fisica" ? "cpf" : "cnpj"}
                        onChange={handleChange}
                        handleBlur={(e) => {
                          handleBlur(e);
                          setFieldTouched(
                            values.tipo_pessoa === "fisica" ? "cpf" : "cnpj",
                            true
                          );
                        }}
                        value={updateInputValue(
                          user,
                          values,
                          values.tipo_pessoa === "fisica" ? "cpf" : "cnpj"
                        )}
                        fullWidth={true}
                        hasMask={true}
                        maskType={
                          values.tipo_pessoa === "fisica" ? "cpf" : "cnpj"
                        }
                      />
                      {touched[
                        values.tipo_pessoa === "fisica" ? "cpf" : "cnpj"
                      ] &&
                        errors[
                          values.tipo_pessoa === "fisica" ? "cpf" : "cnpj"
                        ] && (
                          <div style={{ color: "red" }}>
                            {
                              errors[
                                values.tipo_pessoa === "fisica" ? "cpf" : "cnpj"
                              ]
                            }
                          </div>
                        )}
                    </Grid>
                    <Grid item xs={6}>
                      <SelectAmbiental
                        label={"Selecione o segmento do cliente"}
                        name={"segmento_cliente"}
                        onChange={(event) => {
                          setFieldValue(
                            "segmento_cliente",
                            Number(event.target.value)
                          );
                        }}
                        onBlur={(e) => {
                          handleBlur(e);
                          setFieldTouched("segmento_cliente", true);
                        }}
                        nameOptionDescription={"descricao"}
                        nameOptionValue={"id"}
                        options={segmentosCliente}
                        value={values.segmento_cliente}
                        fullWidth={true}
                      />
                    </Grid>
                    {touched.segmento_cliente && errors.segmento_cliente && (
                      <div style={{ color: "red", marginLeft: "260px" }}>
                        {errors.segmento_cliente}
                      </div>
                    )}
                  </Grid>
                  <Box my={1}>
                    <h2>Endereço</h2>
                    <Divider sx={{ my: 2 }} />
                  </Box>
                  <Grid
                    container
                    spacing={2}
                    padding={0}
                    alignContent={"center"}
                    justifyContent={"center"}
                    display={"flex"}
                    width={"100%"}
                    my={1}
                  >
                    <Grid item xs={2} alignItems={"flex-start"}>
                      <InputAmbiental
                        label={"CEP"}
                        required={false}
                        name={"cep"}
                        onChange={handleChange}
                        handleBlur={(e) => {
                          handleBlur(e);
                          setFieldTouched("cep", true);
                        }}
                        value={updateInputValue(user, values, "cep")}
                        fullWidth={true}
                        hasMask={true}
                        maskType={"cep"}
                      />
                      {touched.cep && errors.cep && (
                        <div style={{ color: "red" }}>{errors.cep}</div>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <InputAmbiental
                        label={"Logradouro"}
                        required={false}
                        name={"logradouro"}
                        onChange={handleChange}
                        value={updateInputValue(user, values, "logradouro")}
                        fullWidth={true}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <InputAmbiental
                        label={"Número"}
                        required={false}
                        name={"numero"}
                        onChange={handleChange}
                        value={updateInputValue(user, values, "numero")}
                        fullWidth={true}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    padding={0}
                    alignContent={"center"}
                    justifyContent={"center"}
                    display={"flex"}
                    width={"100%"}
                    my={1}
                  >
                    <Grid item xs={5} alignItems={"flex-start"}>
                      <InputAmbiental
                        label={"Bairro"}
                        required={false}
                        name={"bairro"}
                        onChange={handleChange}
                        value={updateInputValue(user, values, "bairro")}
                        fullWidth={true}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <InputAmbiental
                        label={"Cidade"}
                        required={false}
                        name={"cidade"}
                        onChange={handleChange}
                        value={updateInputValue(user, values, "cidade")}
                        fullWidth={true}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <SelectAmbiental
                        name={"uf"}
                        label={"UF"}
                        value={values.uf}
                        options={ESTADOS}
                        fullWidth={true}
                        nameOptionValue={"sigla"}
                        nameOptionDescription={"nome"}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    padding={0}
                    alignContent={"center"}
                    justifyContent={"center"}
                    display={"flex"}
                    width={"100%"}
                    my={1}
                  >
                    <Grid item xs={12} alignItems={"flex-start"}>
                      <InputAmbiental
                        label={"Complemento"}
                        required={false}
                        name={"complemento"}
                        onChange={handleChange}
                        value={updateInputValue(user, values, "complemento")}
                        fullWidth={true}
                      />
                    </Grid>
                  </Grid>
                  <Box
                    width={"100%"}
                    display={"flex"}
                    alignItems={"flex-end"}
                    justifyContent={"flex-end"}
                    my={3}
                    sx={{
                      position: "sticky",
                      right: 0,
                      bottom: 10,
                    }}
                  >
                    {isDeleteMode ? (
                      <Stack direction="row" gap={2} display={"flex"}>
                        <Box>
                          <ActionButton
                            title="Excluir cliente"
                            to={null}
                            action={() => {
                              modalAlertDeleteCliente(id);
                            }}
                            isSubmitAction={false}
                            color="red"
                            icon={<DeleteIcon />}
                          />
                        </Box>
                        <Box>
                          <ActionButton
                            title="Cancelar"
                            to="/app/listar-clientes"
                            color="blue"
                            icon={<ArrowBackIcon />}
                          />
                        </Box>
                      </Stack>
                    ) : (
                      <ActionButton
                        title={id ? "Atualizar" : "Criar"}
                        to={null}
                        color="blue"
                        icon={<BorderColorIcon />}
                        isSubmitAction={true}
                        action={() => {
                          handleSubmit(
                            values,
                            setSubmitting,
                            resetForm,
                            setFieldValue
                          );
                        }}
                      />
                    )}
                  </Box>
                </Form>
              )}
            </Formik>
        </JumboContentLayout>
      )}
    </Box>
  ) : null;
};

export default UsuariosClienteForm;
