import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import { useJumboTheme } from "@jumbo/hooks";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { LoadingButton } from "@mui/lab";
import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Stack,
  TextField,
  Typography,
  IconButton,
  useMediaQuery,
  InputAdornment,
  Card,
  Badge,
  Chip,
  PaginationItem,
  Paper,
  Tooltip,
  darken,
  DialogActions,
  DialogContent,
  Dialog,
  CircularProgress,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import PageHeader from "app/layouts/shared/headers/PageHeader/PageHeader";
import ApiService from "app/services/config";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import moment from "moment";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ReplayIcon from "@mui/icons-material/Replay";
import StarIcon from "@mui/icons-material/Star";
import { gerarPDF } from "app/utils/pdfGenerator";
import { PermissionContext } from "app/contexts/PermissionContext";
import { useNavigate } from "react-router-dom";
import { DateRangePicker } from "react-dates";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import gerarPDFContratosFinanceiro from "app/utils/pdfs/gerarPDFContratosFinanceiro";
import Div from "@jumbo/shared/Div";
import TuneIcon from "@mui/icons-material/Tune";
import SelectPdf from "app/components/SelectPdf/SelectPdf";
import SelectAmbiental from "@jumbo/components/SelectAmbiental";
import ActionButton from "app/components/ActionButton/ActionButton";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import PaginationComponent from "app/components/Pagination/Pagination";
import OrdenacaoLista from "app/components/OrdenacaoLista";

export const FinanceiroList = () => {
  const [contratos, setContratos] = useState([]);
  const [valorTotalContratos, setValorTotalContratos] = useState(0);
  const [selectedContratosIds, setSelectedContratosIds] = useState([]);
  const [page, setPage] = useState(0);
  const location = useLocation();
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState("id");
  const [sortOrder, setSortOrder] = useState("desc");
  const [searchTerm, setSearchTerm] = useState("");
  const [filtroSelecionarContratos, setFiltroSelecionarContratos] =
    useState("todos");
  const [filtroSituacaoContratos, setFiltroSituacaoContratos] =
    useState("padrao");
  const [tipoOrcamentoSelecionado, setTipoOrcamentoSelecionado] =
    useState("selecionar");
  const [count, setCount] = useState("");
  const { theme } = useJumboTheme();
  const [expand, setExpand] = useState(false);
  const [todosContratosSelecionados, setTodosContratosSelecionados] =
    useState(false);
  const [mostrarBotaoDeletarMuitos, setMostrarBotaoDeletarMuitos] =
    useState(false);
  const [exportarParaPDFSelecionado, setExportarParaPDFSelecionado] =
    useState("selecionar");
  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const userId = Number(localStorage.getItem("id"));
  const roleId = Number(localStorage.getItem("role_id"));
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const [statusOrcamento, setStatusOrcamento] = useState([]);
  const [colaboradores, setcolaboradores] = useState([]);
  const [colaboradorSelecionado, setColaboradorSelecionado] =
    useState("selecionar");
  const [statusOrcamentarioSelecionado, setStatusOrcamentarioSelecionado] =
    useState("selecionar");
  const [periodoPesquisa, setPeriodoPesquisa] = useState("selecionar");
  const [inputValue, setInputValue] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [isPDFLoading, setIsPDFLoading] = useState(false);

  const { hasPermission } = useContext(PermissionContext);
  if (!hasPermission("Financeiro Contratos", "read")) {
    navigate("/app");
  }

  let filtros = {};

  if (filtroSelecionarContratos !== "todos") {
    filtros = {
      ...filtros,
      tipoContrato: filtroSelecionarContratos,
    };
  }

  if (filtroSituacaoContratos !== "padrao") {
    filtros = {
      ...filtros,
      situacao: filtroSituacaoContratos,
    };
  }

  if (statusOrcamentarioSelecionado !== "selecionar") {
    filtros = {
      ...filtros,
      statusOrcamento: statusOrcamentarioSelecionado,
    };
  }

  if (colaboradorSelecionado !== "selecionar") {
    filtros = {
      ...filtros,
      elaborador: colaboradorSelecionado,
    };
  }

  if (tipoOrcamentoSelecionado !== "selecionar") {
    filtros = {
      ...filtros,
      tipoOrcamento: tipoOrcamentoSelecionado,
    };
  }

  if (periodoPesquisa !== "selecionar") {
    filtros = {
      ...filtros,
      periodo: periodoPesquisa,
      startDate: moment.utc(startDate).format("YYYY-MM-DD"),
      endDate: moment.utc(endDate).format("YYYY-MM-DD"),
    };
  }

  filtros.searchTerm = searchTerm;

  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            zIndex: 5,
            top: 96,
            minHeight: "auto",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: "flex-start",
        },
      },
    }),
    [theme]
  );

  const Swal = useSwalWrapper();
  const toast = (variant, message) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true, // botão de fechar
      closeButtonAriaLabel: "Fechar",
      timer: 3000,
    });
  };

  const handleSearch = async (newSearchTerm) => {
    if (searchTerm === "" || searchTerm === null || searchTerm === undefined) {
      setPage(1);
    }
    setSearchTerm(newSearchTerm);
    setPage(0);
  };

  const handleDatesChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handleFocusChange = (focusedInput) => {
    setFocusedInput(focusedInput);
  };

  const getStatusOrcamento = async () => {
    try {
      await ApiService.get("/statusorcamento/all").then((response) => {
        if (response.status === 200) {
          setStatusOrcamento(response.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getColaboradores = async () => {
    try {
      await ApiService.get(
        "/users/colaboradores?filtroRoles=financeiro_admin"
      ).then((response) => {
        if (response.status === 200) {
          setcolaboradores(response.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleExportarContratosParaPDF = () => {
    let url = `/pdf/contrato?formatoContrato=financeiro`;
    if (filtroSelecionarContratos === "selecionar") {
      setExportarParaPDFSelecionado("selecionar");
      return;
    }

    if (filtroSelecionarContratos !== "todos") {
      url += `?tipoContrato=${filtroSelecionarContratos}`;
    }

    if (filtroSituacaoContratos !== "padrao") {
      url += `?situacao=${filtroSituacaoContratos}`;
    }

    if (tipoOrcamentoSelecionado !== "selecionar") {
      let tipo = "";
      switch (tipoOrcamentoSelecionado) {
        case 1:
          tipo = "agronegocio";
          break;
        case 2:
          tipo = "mineracao";
          break;
        case 3:
          tipo = "postos";
          break;
        case 4:
          tipo = "servicos";
          break;
        default:
          tipo = "todos";
      }
      url += `?tipoOrcamento=${tipo}`;
    }

    if (periodoPesquisa !== "selecionar" && startDate && endDate) {
      url += `?periodo=${periodoPesquisa}&dataInicio=${startDate}&dataFim=${endDate}`;
    }

    if (statusOrcamentarioSelecionado !== "selecionar") {
      let idStatusOrcamento = null;
      switch (statusOrcamentarioSelecionado) {
        case 1:
          idStatusOrcamento = "aprovado";
          break;
        case 2:
          idStatusOrcamento = "nao_aprovado";
          break;
        case 3:
          idStatusOrcamento = "finalizado";
          break;
        case 5:
          idStatusOrcamento = "aguardando_resposta";
          break;
        case 7:
          idStatusOrcamento = "finalizado_completo";
          break;
        case 8:
          idStatusOrcamento = "cancelado";
          break;
        default:
          idStatusOrcamento = "aprovado";
      }
      url += `?status=${idStatusOrcamento}`;
    }

    if (colaboradorSelecionado !== "selecionar") {
      url += `?elaborador=${colaboradorSelecionado}`;
    }

    url = url.replace(/\?(?=.*=)/g, (match, offset) =>
      offset === url.indexOf("?") ? "?" : "&"
    );

    // navigate(`/pdf/contrato?tipoContrato=${tipoContrato}`);
    window.open(url, "_blank");

    // `/pdf/contrato?tipoContrato=fixoIndividual&contratoId=${contrato.id}`
    // gerarPDF(tipoContrato);
  };

  const handleLimparFiltros = () => {
    setPage(0);
    setPerPage(10);
    setSortBy("id");
    setSortOrder("desc");
    setSearchTerm("");
    setFiltroSelecionarContratos("todos");
    setFiltroSituacaoContratos("padrao");
    setStatusOrcamentarioSelecionado("selecionar");
    setColaboradorSelecionado("selecionar");
    setStartDate(null);
    setEndDate(null);
    setPeriodoPesquisa("selecionar");
    setTipoOrcamentoSelecionado("selecionar");
    setInputValue("");
  };

  const getContratos = useCallback(async () => {
    try {
      let apiUrl = `/contratos/financeiro?page=${
        page + 1
      }&perPage=${perPage}&sortBy=${sortBy}&sortOrder=${sortOrder}&tipoContrato=${filtroSelecionarContratos}&userId=${userId}&roleId=${roleId}`;

      if (searchTerm) {
        apiUrl += `&searchTerm=${searchTerm}`;
      }

      if (filtroSituacaoContratos !== "padrao") {
        apiUrl += `&situacao=${filtroSituacaoContratos}`;
      }

      if (startDate && endDate) {
        console.log(startDate, endDate);
        apiUrl += `&dataInicio=${moment(startDate._d).format(
          "YYYY-MM-DD"
        )}&dataFim=${moment(endDate._d).format("YYYY-MM-DD")}`;
      }

      if (statusOrcamentarioSelecionado !== "selecionar") {
        apiUrl += `&statusOrcamento=${statusOrcamentarioSelecionado}`;
      }

      if (colaboradorSelecionado && colaboradorSelecionado !== "selecionar") {
        apiUrl += `&idElaborador=${colaboradorSelecionado}`;
      }

      if (periodoPesquisa !== "selecionar") {
        apiUrl += `&periodo=${periodoPesquisa}`;
      }

      if (tipoOrcamentoSelecionado !== "selecionar") {
        apiUrl += `&tipoOrcamento=${Number(tipoOrcamentoSelecionado)}`;
      }

      ApiService.get(apiUrl)
        .then((response) => {
          let valorTotalDeTodosContratos = 0;
          const contratosComValorTotal = response.data.servicos.map(
            (contrato) => {
              const valorTotal =
                contrato?.renegociacoescontrato?.length > 0
                  ? Number(contrato.renegociacoescontrato[0].valor)
                  : contrato?.servicos?.reduce(
                      (total, servico) =>
                        total +
                        Number(servico.quantidade) * Number(servico.valor),
                      0
                    );

              const pagamentoRecebido = contrato.pagamentoscontrato.reduce(
                (total, pagamento) => {
                  return total + pagamento.valor;
                },
                0
              );

              return {
                ...contrato,
                valorTotal,
                pagamentoRecebido,
                isChecked: false,
              };
            }
          );

          setValorTotalContratos(response.data?.valorTotalContratos);
          setContratos(contratosComValorTotal);
          setCount(response.data.total);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, [
    page,
    perPage,
    sortBy,
    sortOrder,
    searchTerm,
    filtroSelecionarContratos,
    filtroSituacaoContratos,
    statusOrcamentarioSelecionado,
    startDate,
    endDate,
    colaboradorSelecionado,
    periodoPesquisa,
    tipoOrcamentoSelecionado,
  ]);

  const getValorTotalContratos = async () => {
    try {
      let apiUrl = `/contratos/soma/valor/total?tipoContrato=${filtroSelecionarContratos}&situacao=${filtroSituacaoContratos}&userId=${userId}&roleId=${roleId}`;

      if (searchTerm) {
        apiUrl += `&searchTerm=${searchTerm}`;
      }

      if (startDate && endDate) {
        apiUrl += `&dataInicio=${moment(startDate._d).format(
          "YYYY-MM-DD"
        )}&dataFim=${moment(endDate._d).format("YYYY-MM-DD")}`;
      }

      if (statusOrcamentarioSelecionado !== "selecionar") {
        apiUrl += `&statusOrcamento=${statusOrcamentarioSelecionado}`;
      }

      if (colaboradorSelecionado && colaboradorSelecionado !== "selecionar") {
        apiUrl += `&idElaborador=${colaboradorSelecionado}`;
      }

      if (periodoPesquisa !== "selecionar") {
        apiUrl += `&periodo=${periodoPesquisa}`;
      }

      if (tipoOrcamentoSelecionado !== "selecionar") {
        apiUrl += `&tipoOrcamento=${Number(tipoOrcamentoSelecionado)}`;
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getContratos();
    getStatusOrcamento();
    getColaboradores();
  }, [getContratos]);

  useEffect(() => {
    getValorTotalContratos();
  }, [
    valorTotalContratos,
    startDate,
    endDate,
    statusOrcamentarioSelecionado,
    periodoPesquisa,
    searchTerm,
    tipoOrcamentoSelecionado,
  ]);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleFilter = (clean = false) => {
    console.log("Executando filtro com os valores mais recentes");

    let filtroAtual = {
      filtroSelecionarContratos,
      filtroSituacaoContratos,
      statusOrcamentarioSelecionado,
      startDate,
      endDate,
      colaboradorSelecionado,
      periodoPesquisa,
      tipoOrcamentoSelecionado,
      searchTerm,
    };

    if (clean) {
      filtroAtual = {
        filtroSelecionarContratos: "todos",
        filtroSituacaoContratos: "padrao",
        statusOrcamentarioSelecionado: "selecionar",
        startDate: null,
        endDate: null,
        colaboradorSelecionado: "selecionar",
        periodoPesquisa: "selecionar",
        tipoOrcamentoSelecionado: "selecionar",
        searchTerm: "",
      };
    }

    const getContratosManualFilter = async () => {
      try {
        let apiUrl = `/contratos?page=${
          page + 1
        }&perPage=${perPage}&sortBy=${sortBy}&sortOrder=${sortOrder}&tipoContrato=${
          filtroAtual.filtroSelecionarContratos
        }&situacao=${
          filtroAtual.filtroSituacaoContratos
        }&userId=${userId}&roleId=${roleId}`;

        if (filtroAtual.searchTerm) {
          apiUrl += `&searchTerm=${filtroAtual.searchTerm}`;
        }

        if (filtroAtual.startDate && filtroAtual.endDate) {
          apiUrl += `&dataInicio=${moment(filtroAtual.startDate._d).format(
            "YYYY-MM-DD"
          )}&dataFim=${moment(filtroAtual.endDate._d).format("YYYY-MM-DD")}`;
        }

        if (filtroAtual.statusOrcamentarioSelecionado !== "selecionar") {
          apiUrl += `&statusOrcamento=${filtroAtual.statusOrcamentarioSelecionado}`;
        }

        if (
          filtroAtual.colaboradorSelecionado &&
          filtroAtual.colaboradorSelecionado !== "selecionar"
        ) {
          apiUrl += `&idElaborador=${filtroAtual.colaboradorSelecionado}`;
        }

        if (filtroAtual.periodoPesquisa !== "selecionar") {
          apiUrl += `&periodo=${filtroAtual.periodoPesquisa}`;
        }

        if (filtroAtual.tipoOrcamentoSelecionado !== "selecionar") {
          apiUrl += `&tipoOrcamento=${Number(
            filtroAtual.tipoOrcamentoSelecionado
          )}`;
        }

        const response = await ApiService.get(apiUrl);

        const initialData = response.data.contratos.map((contrato) => ({
          ...contrato,
          isChecked: false,
        }));
        console.log("contratos", initialData);
        setContratos(initialData);
        setCount(response.data.total);
      } catch (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("error", error.message);
        }
      }
    };

    getContratosManualFilter();
  };

  return (
    <JumboContentLayout
      header={<PageHeader title={"Contratos - Financeiro"} />}
      layoutOptions={layoutOptions}
    >
      {lg && (
        <Stack spacing={2} direction={"row"} sx={{ mb: 3, mt: -2 }}></Stack>
      )}
      <Grid item xs={12} sx={{ textAlign: "center" }}>
        <Box sx={{
          position: 'relative'
        }}>
          <Div
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
              }}
            >
              <TextField
                variant="standard"
                placeholder="Pesquisar..."
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch(inputValue);
                  }
                }}
                InputProps={{
                  sx: {
                    borderRadius: 2,
                    border: "1px solid #4E5526",
                    paddingLeft: 2,
                    height: "36px",
                    "&:hover": { borderColor: "#3B4220" },
                    "&.Mui-focused": { borderColor: "#4E5526" },
                    color: "#4E5526",
                    "&::placeholder": { color: "red", opacity: 1 },
                    width: 350,
                  },
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        sx={{
                          backgroundColor: "#4E5526",
                          borderRadius: 2,
                          minWidth: "36px",
                          height: "36px",
                          padding: 0,
                          "&:hover": { backgroundColor: "#3B4220" },
                        }}
                        onClick={() => handleSearch(inputValue)}
                      >
                        <SearchIcon sx={{ color: "#fff", fontSize: 20 }} />
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />

              <Button
                variant="contained"
                sx={{
                  borderRadius: 2,
                  paddingX: 2,
                  textTransform: "none",
                  backgroundColor: "#F0F0F0",
                  gap: 1,
                  "&:hover": { backgroundColor: darken("#F0F0F0", 0.2) },
                }}
                onClick={handleOpenModal}
              >
                <Typography sx={{ color: "#4E5526" }}>Filtros</Typography>
                <TuneIcon sx={{ color: "#4E5526", fontSize: "20px" }} />
              </Button>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
              <ActionButton
                color="red"
                action={() => {
                  gerarPDFContratosFinanceiro(filtros, false, setIsPDFLoading);
                }}
                title={"Exportar para PDF"}
                isSubmitAction={false}
                icon={
                  isPDFLoading ? (
                    <CircularProgress size="20px" style={{ color: "white" }} />
                  ) : (
                    <PictureAsPdfIcon />
                  )
                }
              />
            </Box>
          </Div>

          <Card
            sx={{
              p: 4,
              width: "100%",
              minHeight: 100,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: "none",
              backgroundColor: "transparent",
            }}
          >
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item lg={2}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Typography fontSize={24} fontWeight={600}>
                    Contrato
                  </Typography>
                  <OrdenacaoLista
                    column={"descricao"}
                    sortBy={sortBy}
                    sortOrder={sortOrder}
                    setSortBy={setSortBy}
                    setSortOrder={setSortOrder}
                  />
                </Box>
              </Grid>
              <Grid item lg={2} sx={{ display: "flex" }}>
                <Box>
                  <Typography fontSize={24} fontWeight={600}>
                    Cliente
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={2}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Typography fontSize={24} fontWeight={600}>
                    Criação
                  </Typography>
                  <OrdenacaoLista
                    column={"createdAt"}
                    sortBy={sortBy}
                    sortOrder={sortOrder}
                    setSortBy={setSortBy}
                    setSortOrder={setSortOrder}
                  />
                </Box>
              </Grid>
              <Grid item lg={2}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Typography fontSize={24} fontWeight={600}>
                    Aprovação
                  </Typography>
                  <OrdenacaoLista
                    column={"data_aprovacao"}
                    sortBy={sortBy}
                    sortOrder={sortOrder}
                    setSortBy={setSortBy}
                    setSortOrder={setSortOrder}
                  />
                </Box>
              </Grid>
              <Grid item lg={2} sx={{ display: "flex" }}>
                <Box>
                  <Typography fontSize={24} fontWeight={600}>
                    Valor
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={2} sx={{ display: "flex" }}>
                <Box>
                  <Typography fontSize={24} fontWeight={600}>
                    Recebido
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Card>

          <Div width="100%" style={{ padding: 2 }}>
            {contratos && contratos.length > 0 ? (
              contratos.map((contrato, key) => (
                <Card
                  sx={{
                    p: 2,
                    width: "100%",
                    minHeight: 100,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    my: 1,
                  }}
                  key={key}
                >
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item lg={2} sx={{ display: "flex" }}>
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          fontSize={"18px"}
                          style={{ fontWeight: "600" }}
                        >
                          <Chip
                            color="success"
                            sx={{ borderRadius: 2 }}
                            label={
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Typography fontSize={18} fontWeight={600}>
                                  {contrato.descricao}
                                </Typography>
                                {contrato.tipocontratocontrato.some(
                                  (tipoContrato) =>
                                    String(
                                      tipoContrato.tipos_contratos.descricao
                                    ).toLowerCase() ===
                                    "Renovação".toLowerCase()
                                ) && <ReplayIcon fontSize={"small"} gap={10} />}
                                {contrato.tipocontratocontrato.some(
                                  (tipoContrato) =>
                                    String(
                                      tipoContrato.tipos_contratos.descricao
                                    ).toLowerCase() ===
                                    "Contrato fixo".toLowerCase()
                                ) && <StarIcon fontSize={"small"} gap={10} />}
                              </Box>
                            }
                            my={2}
                          />
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item lg={2} sx={{ display: "flex" }}>
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          fontSize={"16px"}
                          style={{ fontWeight: "600" }}
                          textAlign={"left"}
                        >
                          {contrato.Cliente?.nome ?? "N/I"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item lg={2} sx={{ display: "flex" }}>
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          fontSize={"18px"}
                          style={{ fontWeight: "600" }}
                        >
                          {moment(contrato.createdAt).format("DD/MM/YYYY")}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item lg={2} sx={{ display: "flex" }}>
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          fontSize={"18px"}
                          style={{ fontWeight: "500" }}
                        >
                          {contrato?.tipocontratocontrato.some(
                            (tipoContrato) =>
                              String(
                                tipoContrato.tipos_contratos.descricao
                              ).toLowerCase() === "Contrato fixo".toLowerCase()
                          ) &&
                          contrato?.status_orcamentario?.id !== 1 &&
                          contrato?.status_orcamentario?.id !== 3 &&
                          contrato?.status_orcamentario?.id !== 7 &&
                          contrato?.status_orcamentario?.id !== 8 ? (
                            <Chip
                              label={
                                <Box display="flex" alignItems="center">
                                  Contrato Fixo{" "}
                                  <StarIcon
                                    fontSize="small"
                                    style={{ marginLeft: 4 }}
                                  />
                                </Box>
                              }
                            />
                          ) : contrato?.data_aprovacao ? (
                            moment
                              .utc(contrato?.data_aprovacao)
                              .format("DD/MM/YYYY")
                          ) : (
                            "N/I"
                          )}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item lg={2} sx={{ display: "flex" }}>
                      <Typography
                        fontSize={"18px"}
                        style={{ fontWeight: "500" }}
                      >
                        {contrato?.tipocontratocontrato.some(
                          (tipoContrato) =>
                            String(
                              tipoContrato.tipos_contratos.descricao
                            ).toLowerCase() === "Contrato fixo".toLowerCase()
                        ) ? (
                          <Chip
                            label={
                              <Box display="flex" alignItems="center">
                                Contrato Fixo{" "}
                                <StarIcon
                                  fontSize="small"
                                  style={{ marginLeft: 4 }}
                                />
                              </Box>
                            }
                          />
                        ) : (
                          <>
                            <Grid item lg={2}>
                              <Typography
                                fontSize={"18px"}
                                style={{ fontWeight: "500" }}
                              >
                                {contrato?.tipocontratocontrato.some(
                                  (tipoContrato) =>
                                    String(
                                      tipoContrato.tipos_contratos.descricao
                                    ).toLowerCase() ===
                                    "Contrato fixo".toLowerCase()
                                ) ? (
                                  <Chip
                                    label={
                                      <Box display="flex" alignItems="center">
                                        Contrato Fixo{" "}
                                        <StarIcon
                                          fontSize="small"
                                          style={{ marginLeft: 4 }}
                                        />
                                      </Box>
                                    }
                                  />
                                ) : (
                                  <>
                                    {contrato?.servicos &&
                                    contrato.servicos.length > 0 ? (
                                      contrato.servicos
                                        .reduce(
                                          (total, servico) =>
                                            total +
                                            (servico?.valor || 0) *
                                              (servico?.quantidade || 1),
                                          0
                                        )
                                        .toLocaleString("pt-BR", {
                                          style: "currency",
                                          currency: "BRL",
                                        })
                                    ) : (
                                      <Stack width="100%">
                                        <Typography
                                          style={{
                                            whiteSpace: "nowrap",
                                            fontSize: "18px",
                                          }}
                                        >
                                          R$ 0,00
                                        </Typography>
                                      </Stack>
                                    )}
                                  </>
                                )}
                              </Typography>
                            </Grid>
                          </>
                        )}
                      </Typography>
                    </Grid>
                    <Grid item lg={2} sx={{ display: "flex" }}>
                      <Stack direction="row" gap={1}>
                        {contrato?.tipocontratocontrato.some(
                          (tipoContrato) =>
                            String(
                              tipoContrato.tipos_contratos.descricao
                            ).toLowerCase() === "Contrato fixo".toLowerCase()
                        ) ? (
                          <Chip
                            label={
                              <Box display="flex" alignItems="center">
                                Contrato Fixo{" "}
                                <StarIcon
                                  fontSize="small"
                                  style={{ marginLeft: 4 }}
                                />
                              </Box>
                            }
                          />
                        ) : (
                          <Stack
                            direction="row"
                            gap={1}
                            width="120px"
                            justifyContent={"space-between"}
                          >
                            <Stack
                              direction="column"
                              gap={1}
                              alignItems={"flex-start"}
                              justifyContent={"flex-start"}
                            >
                              <Div>
                                <Typography
                                  fontSize={"18px"}
                                  style={{ fontWeight: "500" }}
                                >
                                  {Number(
                                    contrato?.pagamentoscontrato?.reduce(
                                      (total, pagamento) =>
                                        total + (Number(pagamento?.valor) || 0),
                                      0
                                    )
                                  ).toLocaleString("pt-BR", {
                                    style: "currency",
                                    currency: "BRL",
                                  })}
                                </Typography>
                              </Div>

                              <Div>
                                <Typography fontWeight={"bold"}>
                                  Falta:{" "}
                                </Typography>
                              </Div>
                              <Div>
                                <Typography>
                                  {contrato?.servicos?.length > 0 ? (
                                    Number(
                                      contrato?.valorTotal -
                                        contrato?.pagamentoRecebido
                                    ) > 1 ? (
                                      Number(
                                        contrato?.valorTotal -
                                          contrato?.pagamentoRecebido
                                      ).toLocaleString("pt-BR", {
                                        style: "currency",
                                        currency: "BRL",
                                      })
                                    ) : (
                                      <>R$ 0,00</>
                                    )
                                  ) : (
                                    <>R$ 0,00</>
                                  )}
                                </Typography>
                              </Div>
                            </Stack>
                          </Stack>
                        )}
                        <Box
                          style={{
                            display: "flex",
                            gap: "10px",
                            justifyContent: "center",
                          }}
                        >
                          <Tooltip title="Ver detalhes">
                            <VisibilityIcon
                              cursor="pointer"
                              sx={{ color: "#000" }}
                              onClick={() => {
                                navigate(
                                  `/app/contrato/${contrato?.id}/financeiro`
                                );
                              }}
                            />
                          </Tooltip>
                        </Box>
                      </Stack>
                    </Grid>
                  </Grid>
                </Card>
              ))
            ) : (
              <Card style={{ padding: 20 }}>Nenhum contrato cadastrado</Card>
            )}
          </Div>

          <PaginationComponent
            page={page}
            setPage={setPage}
            count={count}
            perPage={perPage}
            setPerPage={setPerPage}
          />

          <Dialog
            open={openModal}
            onClose={handleCloseModal}
            PaperProps={{
              style: {
                width: "800px", // Define a largura do modal
                maxWidth: "none", // Remove o limite máximo de largura
                padding: "0 80px",
                overflow: "visible",
              },
            }}
          >
            <DialogContent
              sx={{
                overflow: "visible",
              }}
            >
              <Grid
                container
                spacing={2}
                padding={2}
                alignContent={"center"}
                // justifyContent={"center"}
                display={"flex"}
                width={"100%"}
              >
                <Grid item xs={5} alignItems={"flex-start"}>
                  <SelectAmbiental
                    label={"Selecionar:"}
                    name={"tipo_contrato"}
                    value={filtroSelecionarContratos}
                    required={false}
                    onChange={(event) => {
                      const value = event.target.value;
                      setFiltroSelecionarContratos(value);
                    }}
                    nameOptionDescription={"label"}
                    nameOptionValue={"name"}
                    options={[
                      { name: "todos", label: "Todos os Contratos" },
                      { name: "fixo", label: "Contratos Fixos" },
                      { name: "renovacao", label: "Contratos Renovação" },
                    ]}
                    fullWidth={true}
                  />
                </Grid>

                <Grid item xs={5} alignItems={"flex-start"}>
                  <SelectAmbiental
                    label={"Status Orçamentário"}
                    name={"status"}
                    value={statusOrcamentarioSelecionado}
                    required={false}
                    onChange={(event) => {
                      const value = event.target.value;
                      setStatusOrcamentarioSelecionado(value);
                    }}
                    nameOptionDescription={"descricao"}
                    nameOptionValue={"id"}
                    options={[
                      { id: "selecionar", descricao: "Selecionar" },
                      ...statusOrcamento,
                    ]}
                    fullWidth={true}
                  />
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                padding={2}
                alignContent={"center"}
                // justifyContent={"center"}
                display={"flex"}
                width={"100%"}
              >
                <Grid item xs={4} alignItems={"flex-start"}>
                  <SelectAmbiental
                    label={"Filtrar por"}
                    name={"periodo"}
                    value={periodoPesquisa}
                    required={false}
                    onChange={(event) => {
                      const value = event.target.value;
                      setPeriodoPesquisa(value);
                    }}
                    nameOptionDescription={"label"}
                    nameOptionValue={"name"}
                    options={[
                      { name: "selecionar", label: "Selecionar" },
                      { name: "createdAt", label: "Data de Criação" },
                      { name: "data_aprovacao", label: "Data de Aprovação" },
                    ]}
                    fullWidth={true}
                  />
                </Grid>

                <Grid item xs={7} sx={{ marginTop: 4 }}>
                  <DateRangePicker
                    small={true}
                    startDate={startDate}
                    startDatePlaceholderText="Data inicial"
                    startDateId="data_inicio"
                    endDate={endDate}
                    endDateId="data_final"
                    endDatePlaceholderText="Data final"
                    onDatesChange={handleDatesChange}
                    focusedInput={focusedInput}
                    onFocusChange={handleFocusChange}
                    isOutsideRange={() => false}
                  />
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                paddingLeft={2}
                alignContent={"center"}
                // justifyContent={"center"}
                display={"flex"}
                width={"100%"}
                paddingY={2}
              >
                <Grid item xs={5} alignItems={"flex-start"}>
                  <SelectAmbiental
                    label={"Tipo de Orçamento:"}
                    name={"tipo_orcamento"}
                    value={tipoOrcamentoSelecionado}
                    required={false}
                    onChange={(event) => {
                      const value = event.target.value;
                      setTipoOrcamentoSelecionado(value);
                    }}
                    nameOptionDescription={"label"}
                    nameOptionValue={"id"}
                    options={[
                      { name: "selecionar", label: "Selecionar" },
                      { id: 1, label: "Agronegócio" },
                      { id: 2, label: "Mineração" },
                      { id: 3, label: "Postos de Combustíveis" },
                      { id: 4, label: "Prestação de Serviços" },
                    ]}
                    fullWidth={true}
                  />
                </Grid>

                <Grid item xs={7} alignItems={"flex-start"}>
                  <SelectAmbiental
                    label={"Elaborador:"}
                    name={"elaborador"}
                    value={colaboradorSelecionado}
                    required={false}
                    onChange={(event) => {
                      const value = event.target.value;
                      setColaboradorSelecionado(value);
                    }}
                    nameOptionDescription={"name"}
                    nameOptionValue={"id"}
                    options={[
                      { id: "selecionar", name: "Selecionar" },
                      ...colaboradores,
                    ]}
                    fullWidth={true}
                  />
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions>
              {/* <Button onClick={handleCloseModal} color="primary">Limpar filtros</Button>
                <Button onClick={handleCloseModal} color="primary">Filtrar</Button> */}
              <ActionButton
                title={"Filtrar"}
                to={null}
                color="blue"
                icon={<TuneIcon sx={{ fontSize: "16px" }} />}
                isSubmitAction={true}
                action={() => {
                  handleFilter();
                  handleCloseModal();
                }}
              />

              <ActionButton
                title={"Limpar filtros"}
                to={null}
                color="red"
                icon={<DeleteIcon sx={{ fontSize: "16px" }} />}
                isSubmitAction={true}
                action={() => {
                  handleCloseModal();
                  handleLimparFiltros();
                  handleFilter(true);
                }}
              />
            </DialogActions>
          </Dialog>

          <Paper
            style={{ background: "white", width: "100%" }}
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              position: 'sticky',
              left: 0,
              bottom: 10,
              my: 4
            }}
          >
            <Box width="50%">
              <Typography fontSize={20} fontWeight={600}>
                Valor total:
              </Typography>
            </Box>
            <Box width="50%">
              <Typography fontSize={20} fontWeight={600}>
                {Number(valorTotalContratos).toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </Typography>
            </Box>
          </Paper>
        </Box>
      </Grid>
    </JumboContentLayout>
  );
};
