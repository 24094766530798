import React, { useEffect, useState } from "react";

const InputAmbiental = ({
  label,
  name,
  onChange,
  value,
  type = "text",
  fullWidth = true,
  width = 0,
  required,
  isRegisteredEmail,
  hasMask,
  maskType,
  handleBlur,
  disabled = false,
  height = null,
  placeholder = "",
  adornment = null,
  marginTop = null,
}) => {
  const [maskedValue, setMaskedValue] = useState(value);
  const [removedDigit, setRemovedDigit] = useState("");

  const aplicarMascara = (value, maskType) => {
    switch (maskType) {
      case "celular":
        return value
          ?.replace(/\D/g, "")
          ?.replace(/^(\d{2})(\d{5})(\d{4})$/, "($1) $2-$3");
      case "fixo":
      case "telefone":
        return value
          ?.replace(/\D/g, "")
          ?.replace(/^(\d{2})(\d{4})(\d{4})$/, "($1) $2-$3");
      case "cpf":
        return value
          ?.replace(/\D/g, "")
          ?.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, "$1.$2.$3-$4");
      case "cnpj":
        return value
          ?.replace(/\D/g, "")
          ?.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, "$1.$2.$3/$4-$5");
      case "cep":
        return value?.replace(/\D/g, "").replace(/^(\d{5})(\d{3})$/, "$1-$2");
      case "dinheiro":
        return String(value)
          ?.replace(/\D/g, "")
          ?.replace(/(\d)(\d{2})$/, "$1,$2")
          ?.replace(/(?=(\d{3})+(\D))\B/g, ".");
      default:
        return value;
    }
  };

  const handleInputChange = (e) => {
    let newValue = e.target.value;
    const rawValue = newValue.replace(/\D/g, "");

    if (
      hasMask &&
      ((maskType === "celular" && rawValue.length > 11) ||
        (maskType === "fixo" && rawValue.length > 10) ||
        (maskType === "telefone" && rawValue.length > 10) ||
        (maskType === "cnpj" && rawValue.length > 14) ||
        (maskType === "cep" && rawValue.length > 8))
    ) {
      return;
    }

    if (hasMask && maskType === "celular") {
      if (rawValue.length === 10 && !newValue.includes("9")) {
        newValue = rawValue.slice(0, 2) + "9" + rawValue.slice(2);
      }
    }

    newValue = aplicarMascara(newValue, maskType);

    setMaskedValue(newValue);
    onChange(e, newValue);
  };

  useEffect(() => {
    let updatedValue = String(value)?.replace(/\D/g, "");
  
    if (hasMask) {
      if (maskType === "telefone" || maskType === "fixo") {
        if (updatedValue.length === 11 && updatedValue.charAt(2) === "9") {
          updatedValue = updatedValue.slice(0, 2) + updatedValue.slice(3); 
        }
      }
  
      setMaskedValue(aplicarMascara(updatedValue, maskType));
    } else {
      setMaskedValue(value);
    }
  }, [value, hasMask, maskType]);
  

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <label htmlFor={name} style={{ fontWeight: "bold", display: "block" }}>
        {label}
        {required ? "*" : null}
      </label>
      <div
        style={{
          width: fullWidth ? "100%" : `${width}px`,
          position: "relative",
          marginTop: marginTop ? marginTop : "10px",
          display: "flex",
          alignItems: "center",
          border: "1px solid #4E5526",
          borderRadius: "10px",
          overflow: "hidden",
        }}
      >
        {adornment && (
          <span
            style={{
              backgroundColor: "#F8F9FA",
              padding: "0 10px",
              height: "auto",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              top: 0,
              left: 0,
            }}
          >
            {adornment}
          </span>
        )}
        <input
          type={type}
          name={name}
          onChange={handleInputChange}
          onBlur={handleBlur}
          value={maskedValue}
          placeholder={placeholder}
          required
          disabled={disabled}
          style={{
            width: fullWidth ? "100%" : `${width}px`,
            border: "none",
            height:
              height && height === "auto"
                ? "auto"
                : height !== "auto" && height !== null
                ? `${height}px`
                : "40px",
            outline: "none",
            padding: "0 10px",
            fontFamily: "sans-serif",
            fontSize: "16px",
            color: "#212529",
            boxSizing: "border-box",
          }}
        />
        {type === "email" && !isRegisteredEmail && (
          <span
            style={{
              position: "absolute",
              top: "50%",
              right: "10px",
              transform: "translateY(-50%)",
              backgroundColor: "#E5E5E5",
              borderRadius: "15px",
              padding: "5px 10px",
              color: "#888",
              fontSize: "14px",
              pointerEvents: "none",
            }}
          >
            @clienteambiental.com.br
          </span>
        )}
      </div>
    </div>
  );
};

export default InputAmbiental;