import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import { useJumboTheme } from "@jumbo/hooks";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { Paid, PaidOutlined, Topic } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  Chip,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import ContratoHeader from "app/components/ContratoHeader/ContratoHeader";
import ModalRenegociarContrato from "app/components/ModalRenegociarContrato/ModalRenegociarContrato";
import ApiService from "app/services/config";
import { useMediaQuery } from "beautiful-react-hooks";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import ModalPagamentoContrato from "app/components/ModalPagamentoContrato/ModalPagamentoContrato";
import ModalHistoricoPagamentoContrato from "app/components/ModalHistoricoPagamentoContrato/ModalHistoricoPagamentoContrato";
import ModalHistoricoRenegociacaoContrato from "app/components/ModalHistoricoRenegociacaoPagamento/ModalHistoricoRenegociacaoPagamento";
import { v4 } from "uuid";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DescriptionIcon from "@mui/icons-material/Description";
import { ErrorMessage, Field, Form, Formik } from "formik";
import CreatableSelect from "react-select/creatable";
import * as yup from "yup";
import HeaderBreadcrumbs from "app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs";
import { PermissionContext } from "app/contexts/PermissionContext";
import { useNavigate } from "react-router-dom";
import BotaoVoltarBreadCrumbs from "app/components/BotaoVoltarBreadCrumbs";
import PageHeader from "app/layouts/shared/headers/PageHeader/PageHeader";
import Tag from "app/components/Tag/Tag";
import BadgeNomeCliente from "@jumbo/components/BadgeNomeCliente/BadgeNomeCliente";
import DetalhesClienteContrato from "app/components/DetalhesClienteContrato/DetalhesClienteContrato";
import ContratoDetalhesServicos from "app/components/ContratoDetalhesServicos/ContratoDetalhesServicos";
import MoneyBox from "app/components/MoneyBox/MoneyBox";
import ActionButton from "app/components/ActionButton/ActionButton";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const validationSchema = yup.array().of(
  yup.object().shape({
    servicoTarefaId: yup
      .number("Campo obrigatório")
      .required("Campo obrigatório"),
    status_orcamento_id: yup
      .number("Campo obrigatório")
      .required("Campo obrigatório"),
  })
);

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "transparent" : "white",
    border: state.isFocused
      ? "1px solid #005D5F"
      : "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "4px",
    padding: "8px",
    boxShadow: "none",
    minHeight: "auto",
    "&:hover": {
      borderColor: "black",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#005D5F" : "white",
    color: state.isSelected ? "white" : "black",
    "&:hover": {
      backgroundColor: "lightblue",
      color: "white",
    },
  }),
};

export const ServicoFinanceiro = () => {
  const initialValues = [];
  const userId = Number(localStorage.getItem("id"));
  const { contratoId } = useParams();
  const [contrato, setContrato] = useState([]);
  const [statusOrcamento, setStatusOrcamentos] = useState([]);
  const [statusOrcamentoSelecionado, setStatusOrcamentoSelecionado] = useState(
    []
  );
  const [isModalRenegociacaoAberto, setIsModalRenegociacaoAberto] =
    useState(false);
  const [valorPago, setValorPago] = useState(0);
  const [valorBase, setValorBase] = useState(0);
  const [valorTotalContrato, setValorTotalContrato] = useState(0);

  const [valorAtualRenegociacao, setValorAtualRenegociacao] = useState(0);
  const [numeroParcelasRenegociacao, setNumeroParcelasRenegociacao] =
    useState(1);
  const [isModalPagamentoAberto, setIsModalPagamentoAberto] = useState(false);
  const [isModalHistoricoPagamentoAberto, setIsModalHistoricoPagamentoAberto] =
    useState(false);
  const [
    isModalHistoricoRenegociacaoAberto,
    setIsModalHistoricoRenegociacaoAberto,
  ] = useState(false);
  const [isDadosCarregados, setIsDadosCarregados] = useState(false);
  const { theme } = useJumboTheme();
  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const layoutOptions = React.useMemo(
    () => ({
      root: {
        sx: {
          height: "20px"
        }
      },
      sidebar: {
        sx: {
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            zIndex: 5,
            top: 96,
            minHeight: "auto",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: "flex-start",
        },
      },
    }),
    [theme]
  );
  const Swal = useSwalWrapper();

  const navigate = useNavigate();

  const { hasPermission } = useContext(PermissionContext);

  const toast = (variant, message) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true, // botão de fechar
      closeButtonAriaLabel: "Fechar",
      timer: 3000,
    });
  };

  const handleCreateStatusOrcamentario = useCallback(async (descricao) => {
    const response = await ApiService.post("/statusorcamento", { descricao })
      .then((response) => {
        if (response.status === 201) {
          toast("success", "Criado com sucesso!");
          getStatusOrcamento();
          return response.data;
        }
      })
      .catch((error) => {
        toast("error", error.message);
      });
    return response;
  });

  const handleOpenModalRenegociacao = () => {
    setIsModalRenegociacaoAberto(!isModalRenegociacaoAberto);
  };

  const handleOpenModalPagamento = () => {
    setIsModalPagamentoAberto(!isModalPagamentoAberto);
  };

  const handleOpenModalHistoricoPagamento = () => {
    setIsModalHistoricoPagamentoAberto(!isModalHistoricoPagamentoAberto);
  };

  const handleOpenModalHistoricoRenegociacao = () => {
    setIsModalHistoricoRenegociacaoAberto(!isModalHistoricoRenegociacaoAberto);
  };

  const handleAtualizarValorPago = (pagamento, acao) => {
    const { valor, id } = pagamento;

    if (acao === "remocao") {
      const novoPagamentosContrato = contrato.pagamentoscontrato.filter(
        (pag) => pag.id !== id
      );
      const novoContrato = {
        ...contrato,
        pagamentoscontrato: novoPagamentosContrato,
      };
      setContrato(novoContrato);
      setValorPago((prev) => prev - valor);
    } else {
      const novoContrato = {
        ...contrato,
        pagamentoscontrato: [...contrato.pagamentoscontrato, pagamento],
      };
      setContrato(novoContrato);
      setValorPago((prev) => prev + valor);
    }
  };

  const handleAtualizarValorBase = (valor) => {
    setValorBase(valor);
  };

  const handleAtualizarValorAtualComRenegociacao = (dados, acao) => {
    switch (acao) {
      case "adicao":
        setContrato((prevContrato) => ({
          ...prevContrato,
          renegociacoescontrato: [
            dados,
            ...(prevContrato.renegociacoescontrato || []),
          ],
        }));
        break;
      case "remocao":
        setContrato((prevContrato) => ({
          ...prevContrato,
          renegociacoescontrato: dados,
        }));
        break;
    }
  };

  const handleAtualizarValorPagoAposEdicao = (idPagamento, novoValor) => {
    if (contrato && contrato.pagamentoscontrato) {
      const novoContrato = {
        ...contrato,
        pagamentoscontrato: contrato.pagamentoscontrato.map((pagamento) =>
          pagamento.id === idPagamento
            ? { ...pagamento, valor: novoValor }
            : pagamento
        ),
      };
      setContrato(novoContrato);
    }
  };

  const handleAtualizarRenegociacaoAposEdicao = (
    idRenegociacao,
    valor,
    parcelas
  ) => {
    if (contrato && contrato.renegociacoescontrato) {
      const novoContrato = {
        ...contrato,
        renegociacoescontrato: contrato.renegociacoescontrato.map(
          (renegociacao) =>
            renegociacao.id === idRenegociacao
              ? {
                  ...renegociacao,
                  valor,
                  numeroParcelas: parcelas,
                }
              : renegociacao
        ),
      };
      setContrato(novoContrato);
    }
  };

  const handleAtualizarStatusOrcamentario = async (contratoId, statusId) => {
    try {
      await ApiService.put(
        `/contratos/status/${contratoId}/${statusId}/${userId}`
      ).then((response) => {
        if (response.status === 200) {
          toast("success", "Status Orçamentário atualizado!");
          getStatusOrcamento();
          getContrato();
        }
      });
    } catch (error) {
      console.log(error);
      toast("error", "Ocorreu um erro ao atualizar o Status Orçamentário");
    }
  };

  const getStatusOrcamento = useCallback(async () => {
    try {
      await ApiService.get("/statusorcamento/all").then((response) => {
        setStatusOrcamentos(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  });

  const getContrato = async () => {
    setIsDadosCarregados(false);
    setContrato([]);
    try {
      const response = await ApiService.get(`/contratos/${contratoId}`);
      setContrato(response.data);
      setIsDadosCarregados(true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleAtualizarDataAprovacaoContrato = async (dataAprovacao) => {
    try {
      await ApiService.put(`/contratos/dataAprovacao/${contratoId}`, {
        dataAprovacao,
        statusContratoId: contrato?.status_orcamento_id,
      }).then((response) => {
        if (response.status === 200) {
          getContrato();
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleCalcularValorTotalContrato = () => {
    let valorTotal = 0;

    if (contrato?.renegociacoescontrato?.length > 0) {
      const ultimaRenegociacao = contrato?.renegociacoescontrato[0];
      valorTotal = ultimaRenegociacao?.valor ?? 0;
    } else {
      contrato?.servicos?.forEach((servico) => {
        const quantidade = servico?.quantidade ?? 0;
        const valor = servico?.valor ?? 0;
        valorTotal += quantidade * valor;
      });
    }

    setValorTotalContrato(
      Number(valorTotal).toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      })
    );
  };

  useEffect(async () => {
    const fetchData = async () => {
      await getContrato();
      await getStatusOrcamento();
      setIsDadosCarregados(true);
    };
    await fetchData();
  }, []);

  useEffect(() => {
    if (contrato && contrato?.pagamentoscontrato) {
      const novoValorPago = contrato?.pagamentoscontrato?.reduce(
        (total, pagamento) => total + pagamento.valor,
        0
      );
      setValorPago(novoValorPago);
    } else {
      setValorPago(0);
    }
  }, [contrato]);

  useEffect(() => {
    if (contrato && contrato.servicos && contrato.servicos.length > 0) {
      let somaValorBase = 0;
      contrato.servicos.forEach((servico) => {
        somaValorBase += servico.valor * servico.quantidade;
        handleAtualizarValorBase(somaValorBase);
      });
    }
  }, [contrato]);

  useEffect(() => {
    if (
      contrato &&
      contrato.renegociacoescontrato &&
      contrato.renegociacoescontrato.length > 0
    ) {
      const valorRenegociacao = contrato.renegociacoescontrato[0].valor;
      setValorAtualRenegociacao(valorRenegociacao);
    }
  }, [contrato]);

  useEffect(() => {
    if (contrato) {
      handleCalcularValorTotalContrato();
    }
  }, [contrato]);

  useEffect(() => {
    if (isDadosCarregados && contrato) {
      const temPermissaoDeAcessarAPagina = hasPermission("Financeiro", "read");
      if (!temPermissaoDeAcessarAPagina) {
        navigate("/app");
      }
    }
  }, [isDadosCarregados, contrato]);

  return (
    <Box sx={{ position: "relative" }}>
      <JumboContentLayout layoutOptions={layoutOptions}>
        <BotaoVoltarBreadCrumbs
          handleBack={() => {
            navigate(-1);
          }}
        />
        <BadgeNomeCliente
          nome={contrato?.descricao}
          linkTo={`/app/contrato/${contrato?.id}/detalhes`}
        />
        <PageHeader
          title={"Financeiro > Contrato"}
          icon={
            <DescriptionIcon
              sx={{ color: "#4E5526", marginRight: 1, fontSize: 40 }}
            />
          }
        />
        {lg && <Stack spacing={2} direction={"row"} sx={{ mb: 3, mt: -2 }} />}
        {isDadosCarregados && (
          <>
            <ContratoHeader
              descricao={contrato.descricao}
              elaboradores={contrato.elaboradores_orcamento}
              dataCriacao={contrato.createdAt}
              dataAprovacao={contrato?.data_aprovacao}
              statusOrcamentos={statusOrcamento}
              statusOrcamentoId={contrato.status_orcamento_id}
              contratoId={contrato.id}
              cliente={contrato?.Cliente}
              handleAtualizarStatusOrcamentario={
                handleAtualizarStatusOrcamentario
              }
              handleAtualizarDataAprovacaoContrato={
                handleAtualizarDataAprovacaoContrato
              }
            />
            <Divider sx={{ my: 2 }} />
            <DetalhesClienteContrato
              contrato={contrato}
              mostrarUnidades={true}
            />

            {!contrato?.tipocontratocontrato?.some(
              (tipo) =>
                String(tipo?.tipos_contratos?.descricao).toLowerCase() ===
                "contrato fixo"
            ) ? (
              <>
                <Grid item xs={12} marginY={3}>
                  <Paper
                    width="100%"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      p: 1,
                      gap: 1,
                      backgroundColor: "#4E5526",
                    }}
                  >
                    <Typography
                      variant={"h2"}
                      fontWeight={400}
                      color="#FFF"
                      margin={0}
                    >
                      Valor Total do Contrato:
                    </Typography>
                    <Typography
                      variant={"h2"}
                      fontWeight={600}
                      color="#FFF"
                      margin={0}
                    >
                      {valorTotalContrato}
                    </Typography>
                  </Paper>
                </Grid>

                <Grid container paddingLeft={2} spacing={4}>
                  {/* Itens esquerda */}
                  <Grid
                    container
                    item
                    xs={6}
                    alignItems="center"
                    justifyContent="center"
                    gap={2}
                  >
                    <Grid
                      item
                      xs={12}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Typography
                        fontSize={24}
                        fontWeight={800}
                        display="flex"
                        alignItems="center"
                      >
                        <AttachMoneyIcon
                          sx={{ fontSize: 26, marginRight: 1 }}
                        />
                        Valor atual
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <MoneyBox
                        contrato={contrato}
                        valorAtualRenegociacao={valorAtualRenegociacao}
                        valorBase={valorBase}
                      />
                    </Grid>
                    <ActionButton
                      title={"Renegociar"}
                      color={"green"}
                      icon={<MonetizationOnIcon />}
                      action={handleOpenModalRenegociacao}
                      isSubmitAction={true}
                    />
                    <ActionButton
                      title={"Ver histórico"}
                      color={"gray"}
                      action={handleOpenModalHistoricoRenegociacao}
                    />
                  </Grid>
                  <ModalRenegociarContrato
                    aberto={isModalRenegociacaoAberto}
                    handleClose={handleOpenModalRenegociacao}
                    contratoId={contrato.id}
                    atualizarValorRenegociacao={
                      handleAtualizarValorAtualComRenegociacao
                    }
                    valorAtualContrato={valorBase}
                  />
                  <ModalHistoricoRenegociacaoContrato
                    aberto={isModalHistoricoRenegociacaoAberto}
                    handleClose={handleOpenModalHistoricoRenegociacao}
                    contratoId={contratoId}
                    atualizarValorRenegociacao={
                      handleAtualizarValorAtualComRenegociacao
                    }
                    valorBase={valorBase}
                    atualizarValorAposEdicao={
                      handleAtualizarRenegociacaoAposEdicao
                    }
                  />
                  <ModalPagamentoContrato
                    aberto={isModalPagamentoAberto}
                    handleClose={handleOpenModalPagamento}
                    contratoId={contrato.id}
                    atualizarValorPago={handleAtualizarValorPago}
                  />
                  <ModalHistoricoPagamentoContrato
                    aberto={isModalHistoricoPagamentoAberto}
                    handleClose={handleOpenModalHistoricoPagamento}
                    contratoId={contrato.id}
                    atualizarValorPago={handleAtualizarValorPago}
                    atualizarValorAposEdicao={
                      handleAtualizarValorPagoAposEdicao
                    }
                    isReadOnly={false}
                  />

                  {/* Itens direita */}
                  <Grid
                    container
                    item
                    xs={6}
                    alignItems="center"
                    justifyContent="center"
                    gap={2}
                  >
                    <Grid
                      item
                      xs={12}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Typography
                        fontSize={24}
                        fontWeight={800}
                        display="flex"
                        alignItems="center"
                      >
                        <AttachMoneyIcon
                          sx={{ fontSize: 26, marginRight: 1 }}
                        />
                        Valor pago
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <MoneyBox valorPago={valorPago} />
                    </Grid>
                    <ActionButton
                      title={"Pagamento"}
                      color={"green"}
                      icon={<MonetizationOnIcon />}
                      action={handleOpenModalPagamento}
                    />
                    <ActionButton
                      title={"Ver histórico"}
                      color={"gray"}
                      action={handleOpenModalHistoricoPagamento}
                    />
                  </Grid>
                </Grid>
              </>
            ) : (
              <Box width="100%" p={2} textAlign={"center"}>
                Dados financeiros ocultos, pois o contrato é fixo.
              </Box>
            )}
            <Grid container spacing={2} flexDirection={"column"}>
              <ContratoDetalhesServicos
                contrato={contrato}
                mostrarSomenteTarefaEStatus={true}
              />
            </Grid>
          </>
        )}
      </JumboContentLayout>
    </Box>
  );
};
