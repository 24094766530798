import { useJumboTheme } from "@jumbo/hooks";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  Stack,
  Toolbar,
  useMediaQuery,
  Chip,
  TextField,
  Typography,
  Tooltip,
} from "@mui/material";
import ApiService from "app/services/config";
import React, { useState, useEffect, useCallback, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import PageHeader from "app/layouts/shared/headers/PageHeader/PageHeader";
import MUIDataTable from "mui-datatables";
import InfoIcon from "@mui/icons-material/Info";

import * as dayjs from "dayjs";
import pt from "dayjs/locale/pt-br";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { ptBR } from "@mui/x-date-pickers/locales";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LoadingButton } from "@mui/lab";
import MetricCard from "app/components/MetricCard/MetricCard";
import AccordionCreateLancamento from "app/components/AccordionCreateLancamento/AccordionCreateLancamento";
import BasicModal from "app/components/Modal/Modal";
import { PermissionContext } from "app/contexts/PermissionContext";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import RegimeCompetencia from "app/components/RegimeCompetencia/RegimeCompetencia";
import { DateRangePicker } from "react-dates";
import moment from "moment";

const ControleCaixaList = () => {
  const { theme } = useJumboTheme();
  const location = useLocation();
  const [controleCaixa, setControleCaixa] = useState([]);
  const [indicators, setIndicators] = useState([]);
  const [selectedTiposServicoIds, setSelectedTiposServicoIds] = useState([]);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState("data");
  const [sortOrder, setSortOrder] = useState("asc");

  const [sortByFluxo, setSortByFluxo] = useState("codigo");
  const [sortOrderFluxo, setSortOrderFluxo] = useState("asc");

  const [sortByRegime, setSortByRegime] = useState("codigo");
  const [sortOrderRegime, setSortOrderRegime] = useState("asc");

  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermRegime, setSearchTermRegime] = useState("");
  const [searchTermControleCaixa, setSearchTermControleCaixa] = useState("");

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const [filtrosPesquisa, setFiltrosPesquisa] = useState({
    data_inicio: null,
    data_final: null,
  });

  const [count, setCount] = useState("");
  const [countFluxo, setCountFluxo] = useState("");
  const [categories, setCategories] = useState([]);
  let [date, setDate] = useState(dayjs().startOf("month"));
  const [fluxoCaixa, setFluxoCaixa] = useState([]);
  const [regimeCompetencia, setRegimeCompetencia] = useState([]);
  const [indicatorsFluxo, setIndicatorsFluxo] = useState([]);
  const [dataQuery, setDataQuery] = useState(moment.utc(dayjs().startOf("month")).format("YYYY-MM-DD"));

  const [indicatorsRegime, setIndicatorsRegime] = useState([]);

  const [observation, setObservation] = useState("");
  const [showObservationModal, setShowObservationModal] = useState(false);

  const navigate = useNavigate();

  const { hasPermission } = useContext(PermissionContext);

  if (!hasPermission("Financeiro", "read")) {
    navigate("/app");
  }
  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            zIndex: 5,
            top: 96,
            minHeight: "auto",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: "flex-start",
        },
      },
    }),
    [theme]
  );

  const Swal = useSwalWrapper();
  const toast = (variant, message) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true, // botão de fechar
      closeButtonAriaLabel: "Fechar",
    });
  };

  const modalAlert = async (id) => {
    Swal.fire({
      title: "Tem certeza que deseja apagar?",
      text: "Não será póssível reverter a ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.value) {
        await deletecontroleCaixa(id);
      }
    });
  };

  const handleFocusChange = (focusedInput) => {
    setFocusedInput(focusedInput);
  };

  const handleDatesChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
    if (startDate && endDate) {
      setFiltrosPesquisa((prevFiltros) => ({
        ...prevFiltros,
        data_inicio: moment(startDate._d).format("YYYY-MM-DD"),
        data_final: moment(endDate._d).format("YYYY-MM-DD"),
      }));
    }
  };

  const handleSearch = async (newSearchTerm) => {
    console.log("CHAMOUU");
    if (searchTerm === "" || searchTerm === null || searchTerm === undefined) {
      setPage(1);
    }
    setSearchTerm(newSearchTerm);

    setPage(0);
  };

  const handleSearchLancamentos = async (newSearchTerm) => {
    if (
      searchTermControleCaixa === "" ||
      searchTermControleCaixa === null ||
      searchTermControleCaixa === undefined
    ) {
      setPage(1);
    }
    setSearchTermControleCaixa(newSearchTerm);

    setPage(0);
  };

  const handleSearchFluxo = async (newSearchTerm) => {
    if (
      searchTermRegime === "" ||
      searchTermRegime === null ||
      searchTermRegime === undefined
    ) {
      setPage(1);
    }
    setSearchTermRegime(newSearchTerm);

    setPage(0);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getFluxoCaixa();

      getCategories();
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getRegimeCompetencia();

      getCategories();
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTermRegime]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getControleCaixa();

      getCategories();
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTermControleCaixa]);

  const deletecontroleCaixa = useCallback(async (id) => {
    try {
      ApiService.delete(`/controlecaixa/${id}`)
        .then((response) => {
          toast("success", "Removido com sucesso");
          getControleCaixa();
        })
        .catch((error) => {
          const message = error.response.data.message;
          toast("error", message);
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, [date]);

  const getControleCaixa = useCallback(async () => {
    try {
      let apiUrl = `/controlecaixa`;

      apiUrl += `?page=${
        page + 1
      }&perPage=${perPage}&sortBy=${sortBy}&sortOrder=${sortOrder}&date=${dataQuery}`;

      if (searchTermControleCaixa) {
        console.log("LANCAMENTO");
        apiUrl += `&searchTerm=${searchTermControleCaixa}`;
      }

      if (filtrosPesquisa.data_inicio && filtrosPesquisa.data_final) {
        apiUrl += `&dataInicio=${filtrosPesquisa.data_inicio}&dataFinal=${filtrosPesquisa.data_final}`;
      }

      ApiService.get(apiUrl)
        .then((response) => {
          const initialData = response.data.controleCaixa?.map((data) => ({
            ...data,
          }));
          setControleCaixa(initialData);
          console.log('setControleCaixa', initialData)
          console.log('response.data.total', response.data.total)
          setIndicators(response.data.indicators);
          setCount(response.data.total);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, [
    page,
    perPage,
    sortBy,
    sortOrder,
    date,
    searchTermControleCaixa,
    startDate,
    endDate,
  ]);

  const getFluxoCaixa = useCallback(async () => {
    console.log('data fluxo caixa', date)
    try {
      let apiUrl = `controlecaixa/fluxo`;

      apiUrl += `?page=${
        page + 1
      }&perPage=${perPage}&sortBy=${sortByFluxo}&sortOrder=${sortOrderFluxo}&date=${date}`;

      if (searchTerm) {
        apiUrl += `&searchTerm=${searchTerm}`;
      }
      ApiService.get(apiUrl)
        .then((response) => {
          const initialData = response.data.fluxoCaixa?.map((data) => ({
            ...data,
          }));

          setFluxoCaixa(initialData);
          setIndicatorsFluxo(response.data.indicatorsFluxo);
          setCountFluxo(response.data.total);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, [sortByFluxo, sortOrderFluxo, date, searchTerm]);

  const getRegimeCompetencia = useCallback(async () => {
    try {
      let apiUrl = `controlecaixa/regimecompetencia`;

      apiUrl += `?page=${
        page + 1
      }&perPage=${perPage}&sortBy=${sortByRegime}&sortOrder=${sortOrderRegime}&date=${date}`;

      if (searchTermRegime) {
        console.log("TEM TERMO DE BUSCA");
        apiUrl += `&searchTerm=${searchTermRegime}`;
      }
      ApiService.get(apiUrl)
        .then((response) => {
          const initialData = response.data.regimeCompetencia?.map((data) => ({
            ...data,
          }));

          setRegimeCompetencia(initialData);
          setIndicatorsRegime(response.data.indicatorsRegime);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, [sortByRegime, sortOrderRegime, date, searchTermRegime]);

  const getCategories = useCallback(async () => {
    try {
      ApiService.get(`/planocontas/categories`)
        .then((response) => {
          console.log('categories', response.data)
          setCategories(response.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    getControleCaixa();
    getFluxoCaixa();
    getRegimeCompetencia();

    getCategories();
  }, [sortByFluxo, sortOrderFluxo, endDate, startDate, page, perPage, date, searchTerm, sortOrder]);

  // useEffect(() => {
  //   getControleCaixa();
  // }, [endDate, startDate, page, perPage, date]);

  const columns = [
    {
      name: "id",
      label: "Id",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "data",
      label: "Data",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const date = dayjs(value).add(3, "hour").format("DD/MM/YYYY");

          return date;
        },
      },
    },
    {
      name: "plano_conta_id",
      label: "Descrição",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { minWidth: "400px", maxWidth: "400px" },
        }),
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const category_id = tableMeta.rowData[2];
          const observation = tableMeta.rowData[3];

          const row = categories.find(
            (category) => category.id === category_id
          );

          return (
            <Tooltip title={row?.descricao}>
              <span>{`${row?.codigo || ""} - ${observation || "Sem observação"}`}</span>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "observacao",
      label: "Observação",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "entrada_caixa",
      label: "Entrada Caixa",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const formatter = new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          });

          return formatter.format(value);
        },
      },
    },
    {
      name: "entrada_51387_3",
      label: "Entrada BB 51387-3",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const formatter = new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          });

          return formatter.format(value);
        },
      },
    },
    {
      name: "entrada_51403_9",
      label: "Entrada BB 51403-9",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const formatter = new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          });

          return formatter.format(value);
        },
      },
    },
    {
      name: "saida_caixa",
      label: "Saída Caixa",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const formatter = new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          });

          return formatter.format(value);
        },
      },
    },
    {
      name: "saida_51387_3",
      label: "Saída BB 51387-3",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const formatter = new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          });

          return formatter.format(value);
        },
      },
    },
    {
      name: "saida_51403_9",
      label: "Saída BB 51403-9",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const formatter = new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          });

          return formatter.format(value);
        },
      },
    },
    {
      name: "action",
      label: "Ações",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const controleCaixaId = tableMeta.rowData[0];
          const observation = tableMeta.rowData[3];

          return (
            <Box display={"flex"} gap={"1rem"}>
              {/* <Button
                color="secondary"
                variant="contained"
                size="small"
                disabled={!observation}
                onClick={() => {
                  setObservation(observation);
                  setShowObservationModal(true);
                }}
              >
                <InfoIcon />
              </Button> */}

              <Link
                to={`/app/editar-lancamento/${controleCaixaId}`}
                style={{ textDecoration: "none" }}
              >
                <Button
                  color="info"
                  variant="contained"
                  size="small"
                  disableElevation
                >
                  <VisibilityIcon />
                </Button>
              </Link>
              <Button
                color="error"
                variant="contained"
                size="small"
                onClick={async () => {
                  await modalAlert(controleCaixaId);
                  getFluxoCaixa();
                }}
              >
                <DeleteIcon />
              </Button>
            </Box>
          );
        },
      },
    },
  ];

  const columnsFluxoCaixa = [
    {
      name: "id",
      label: "Id",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "codigo",
      label: "Código",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "descricao",
      label: "Descrição",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "entrada",
      label: "Total de Entrada",
      options: {
        filter: true,
        sort: true,
        display: false,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const formatter = new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          });

          return formatter.format(value);
        },
      },
    },
    {
      name: "saida",
      label: "Total saída",
      options: {
        filter: true,
        sort: true,
        display: false,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const formatter = new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          });

          return formatter.format(value);
        },
      },
    },
    {
      name: "tipo",
      label: "Tipo",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "entrada",
      label: "Valor",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const isInput = tableMeta.rowData[5] == "Entrada";

          let monthValue = 0;
          if (isInput) {
            monthValue = tableMeta.rowData[3];
          } else {
            monthValue = tableMeta.rowData[4];
          }

          const formatter = new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          });

          return formatter.format(monthValue);
        },
      },
    },
    {
      name: "porcentagem_total_tipo",
      label: "100%",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const totalValue = value ?? 0;
          return totalValue.toFixed(2) + "%";
        },
      },
    },
    {
      name: "porcentagem_op",
      label: "AV% OP.",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const avgValue = value ?? 0;
          return avgValue.toFixed(2) + "%";
        },
      },
    },
  ];

// console.log('page', page)
// console.log('count', count)

const options = {
  changeRowsPerPage: perPage,
  filterType: "dropdown",
  filter: false,
  selectableRows: "none",
  searchAlwaysOpen: true,
  searchable: true,
  serverSide: true,
  page: page,
  count: count,
  selectToolbarPlacement: "above",

  onTableChange: (action, tableState) => {
    console.log(action);
    switch (action) {
      case "changePage":
        setPage(tableState.page);
        break;
      case "sort":
        setSortBy(tableState.sortOrder.name);
        setSortOrder(tableState.sortOrder.direction);
        break;
      case "search":
        handleSearchLancamentos(tableState.searchText);
        break;
      case "changeRowsPerPage":
        setPerPage(tableState.rowsPerPage);
        break;
      default:
        console.log("action not handled.");
    }
  },
  textLabels: {
    body: {
      noMatch: "Nenhum resultado encontrado",
      toolTip: "Sort",
      columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
    },
    pagination: {
      next: "Próxima Página",
      previous: "Página Anterior",
      rowsPerPage: "Itens por Página:",
      displayRows: "of",
    },
  },
};
  // console.log('fluxoCaixa', fluxoCaixa)
  const optionsFluxo = {
    filterType: "dropdown",
    filter: false,
    selectableRows: "none",
    searchable: true,
    serverSide: true,
    selectToolbarPlacement: "above",
    pagination: false,

    onTableChange: (action, tableState) => {
      // console.log(action);
      // console.log('tableState.sortOrder.name 2', tableState.sortOrder.name);
      // console.log('tableState.sortOrder.direction 2', tableState.sortOrder.direction);
      switch (action) {
        case "sort":
          setSortByFluxo(tableState.sortOrder.name);
          setSortOrderFluxo(tableState.sortOrder.direction);
          break;
        case "search":
          handleSearch(tableState.searchText);
          break;
        default:
          // console.log("action not handled.");
      }
    },
    textLabels: {
      body: {
        noMatch: "Nenhum resultado encontrado",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
      },
    },
    setRowProps: (row) => {
      var bgColor = "#fff";
      const length = row[1].length;

      switch (length) {
        case 1:
          bgColor = "#d1d1d1";
          break;
        case 2:
          bgColor = "#e8e8e8";
          break;
        default:
          bgColor = "#fff";
      }

      if (row[1] == "30") {
        bgColor = "#d9d9d9";
      }

      return {
        style: { background: bgColor },
      };
    },
  };

  // useEffect(() => {
  //   getControleCaixa();
  // }, [date, perPage]);

  console.log('perPage fora return', perPage)

  return (
    <JumboContentLayout
      header={<PageHeader title={"Controle de caixa"} />}
      layoutOptions={layoutOptions}
    >
      {lg && (
        <Stack spacing={2} direction={"row"} sx={{ mb: 3, mt: -2 }}></Stack>
      )}

      {showObservationModal && (
        <BasicModal
          title="Observação"
          description={observation}
          isOpen={showObservationModal}
          showModal={setShowObservationModal}
        />
      )}
      <React.Fragment>
        <Grid container spacing={3} mb={2}>
          <Grid item xs={6} md={4} lg={3}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale={pt}
              localeText={
                ptBR.components.MuiLocalizationProvider.defaultProps.localeText
              }
            >
              <Stack>
                <DesktopDatePicker
                  sx={{ background: "#ffffff" }}
                  views={["month", "year"]}
                  label="Data de lançamento"
                  value={date}
                  onChange={(event) => {
                    const newValue = dayjs(event.$d).startOf("month");
                    const dataFormatoIngles = moment.utc(event.$d).format('YYYY-MM-DD')
                    setDataQuery(dataFormatoIngles);
                    setDate(newValue);
                  }}
                  // renderInput={(params) => <TextField {...params} />}
                />
              </Stack>
            </LocalizationProvider>
          </Grid>
        </Grid>

        <Grid container mb={2}>
          <Grid item xs={12}>
            <AccordionCreateLancamento
              updateState={getControleCaixa}
              updateFluxoIndicators={getFluxoCaixa}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3} mb={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MetricCard title={"Caixa total"} value={indicators.caixa} />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MetricCard title={"BB-51387-3"} value={indicators.bb_51387_3} />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MetricCard title={"BB-51403-9"} value={indicators.bb_51403_9} />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MetricCard title={"Total"} value={indicators.total} />
          </Grid>
        </Grid>

        <Accordion disableGutters sx={{ marginBottom: "15px" }}>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography>Lançamentos</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Stack direction={"row"} gap={2} my={2} alignItems={"center"}>
              <Box>
                <Typography>Filtrar por período</Typography>
                <DateRangePicker
                  small={true}
                  startDate={startDate}
                  startDatePlaceholderText="Data inicial"
                  startDateId="data_inicio"
                  endDate={endDate}
                  endDateId="data_final"
                  endDatePlaceholderText="Data final"
                  onDatesChange={handleDatesChange}
                  focusedInput={focusedInput}
                  onFocusChange={handleFocusChange}
                  isOutsideRange={() => false}
                />
              </Box>
              <Box>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setFiltrosPesquisa({
                      data_inicio: null,
                      data_final: null,
                    });
                    setStartDate(null);
                    setEndDate(null);
                  }}
                  my={1}
                >
                  Limpar datas
                </Button>
              </Box>
            </Stack>
            <MUIDataTable
              title={""}
              data={controleCaixa}
              columns={columns}
              options={options}
            />
          </AccordionDetails>
        </Accordion>

        <Accordion disableGutters sx={{ marginBottom: "15px" }}>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography>Regime de Caixa</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={3} mb={3}>
              <Grid item xs={12} md={6} lg={3}>
                <MetricCard
                  title={"Saldo inicial"}
                  value={indicatorsFluxo.saldoInicial}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <MetricCard
                  title={"Resultado total de caixa"}
                  value={indicatorsFluxo.resultadoTotalCaixa}
                  secondaryValue={indicatorsFluxo.resultadoTotalCaixaAvg}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <MetricCard
                  title={"Saldo final"}
                  value={indicatorsFluxo.saldoFinal}
                  secondaryValue={indicatorsFluxo.saldoFinalAvg}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <MetricCard
                  title={"Resultado operacional de caixa"}
                  value={indicatorsFluxo.resultadoOperacionalCaixa}
                  secondaryValue={indicatorsFluxo.resultadoOperacionalCaixaAvg}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <MetricCard
                  title={"Resultado fluxo investimento"}
                  value={indicatorsFluxo.resultadoFluxoInvestimento}
                  secondaryValue={indicatorsFluxo.resultadoFluxoInvestimentoAvg}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <MetricCard
                  title={"Resultado fluxo financiamento"}
                  value={indicatorsFluxo.resultadoFluxoFinanciamento}
                  secondaryValue={
                    indicatorsFluxo.resultadoFluxoFinanciamentoAvg
                  }
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <MetricCard
                  title={"Variação líquida de caixa"}
                  value={indicatorsFluxo.variacaoLiquidaCaixa}
                  secondaryValue={indicatorsFluxo.variacaoLiquidaCaixaAvg}
                />
              </Grid>
            </Grid>
            <MUIDataTable
              title={""}
              data={fluxoCaixa}
              columns={columnsFluxoCaixa}
              options={optionsFluxo}
            />
          </AccordionDetails>
        </Accordion>

        <RegimeCompetencia
          data={regimeCompetencia}
          indicators={indicatorsRegime}
          setSortByFluxo={setSortByFluxo}
          setSortOrderFluxo={setSortOrderFluxo}
          handleSearchFluxo={handleSearchFluxo}
        />
      </React.Fragment>
    </JumboContentLayout>
  );
};

export default ControleCaixaList;
