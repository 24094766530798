import { useJumboTheme } from "@jumbo/hooks";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  Checkbox,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ApiService from "app/services/config";
import React, { useState, useEffect, useCallback, useContext } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import PageHeader from "app/layouts/shared/headers/PageHeader/PageHeader";
import { LoadingButton } from "@mui/lab";
import MUIDataTable from "mui-datatables";
import { PermissionContext } from "app/contexts/PermissionContext";
import { useNavigate } from "react-router-dom";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import EditIcon from "@mui/icons-material/Edit";
import BackNavigation from "app/components/BackNavigation/BackNavigation";
import Tag from "app/components/Tag/Tag";
import DescriptionIcon from "@mui/icons-material/Description";
import ActionButton from "app/components/ActionButton/ActionButton";
import QueueIcon from "@mui/icons-material/Queue";
import SearchIcon from "@mui/icons-material/Search";
import Div from "@jumbo/shared/Div";
import PaginationComponent from "app/components/Pagination/Pagination";
import BotoesExportarDados from "app/components/BotoesExportarDados";

const linkStyle = {
  color: "#302D2E",
  fontSize: 20,
  textDecoration: "none",
  "&:visited": {
    color: "#302D2E",
  },
};

const ImoveisCliente = () => {
  const { theme } = useJumboTheme();
  const location = useLocation();
  const { clienteId, unidadeId } = useParams();
  const [imoveis, setImoveis] = useState([]);
  const [clienteNome, setClienteNome] = useState(null);
  const [breadCrumbsInfo, setBreadCrumbsInfo] = useState(null);
  const [selectedImoveisIds, setSelectedImoveisIds] = useState([]);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState("id");
  const [sortOrder, setSortOrder] = useState("asc");
  const [searchTerm, setSearchTerm] = useState("");
  const [count, setCount] = useState("");
  const [inputValue, setInputValue] = useState("");

  const [todosImoveisSelecionados, setTodosImoveisSelecionados] =
    useState(false);
  const [mostrarBotaoDeletarMuitos, setMostrarBotaoDeletarMuitos] =
    useState(false);

  const navigate = useNavigate();
  const { hasPermission } = useContext(PermissionContext);
  const permission = hasPermission("Clientes", "create");
  if (!hasPermission("Clientes", "read")) {
    navigate("/app");
  }

  const permissionUpdate = hasPermission("Unidades", "update");
  const permissionDelete = hasPermission("Unidades", "delete");
  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            zIndex: 5,
            top: 96,
            minHeight: "auto",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: "flex-start",
        },
      },
    }),
    [theme]
  );

  const Swal = useSwalWrapper();
  const toast = (variant, message) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true, // botão de fechar
      closeButtonAriaLabel: "Fechar",
      timer: 3000,
    });
  };

  const handleSearch = async (newSearchTerm) => {
    if (searchTerm === "" || searchTerm === null || searchTerm === undefined) {
      setPage(1);
    }
    setSearchTerm(newSearchTerm);
    setPage(0);
  };

  const handleSelecionarTodosImoveis = () => {
    const updatedImoveis = imoveis.map((imovel) => ({
      ...imovel,
      isSelected: !todosImoveisSelecionados,
    }));

    setImoveis(updatedImoveis);

    if (!todosImoveisSelecionados) {
      setSelectedImoveisIds(imoveis.map((imovel) => imovel.id));
    } else {
      setSelectedImoveisIds([]);
    }

    setTodosImoveisSelecionados(!todosImoveisSelecionados);
  };

  const handleDeleteMuitosImoveis = () => {
    Swal.fire({
      title: "Tem certeza que deseja apagar os imóveis selecionados?",
      text: "Não será póssível reverter a ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteMuitosImoveis();
      }
    });
  };

  const deleteMuitosImoveis = useCallback(async () => {
    try {
      await ApiService.put("/imoveis/delete/many", selectedImoveisIds).then(
        (response) => {
          if (response.status === 200) {
            toast("success", "Imóveis removidos com sucesso!");
            getImoveis();
            setSelectedImoveisIds([]);
          }
        }
      );
    } catch (error) {
      toast("error", "Não foi possível deletar os imóveis selecionados");
      console.log(error);
    }
  }, [selectedImoveisIds]);

  const getImoveis = useCallback(async () => {
    try {
      let filtros = {};
      filtros.page = page;
      filtros.perPage = perPage;
      filtros.sortBy = sortBy;
      filtros.sortOrder = sortOrder;
      filtros.searchTerm = searchTerm;
      await ApiService.post(
        `/imoveis/cliente/${clienteId}/unidade/${unidadeId}`,
        filtros
      )
        .then((response) => {
          if (response.status === 201) {
            const initialData = response.data.imoveis.map((imovel) => ({
              ...imovel,
              isSelected: false,
            }));

            setImoveis(initialData);
            setCount(response.data.count);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error(error, error.message);
    }
  }, [page, perPage, searchTerm, sortBy, sortOrder]);

  const getClienteNome = async () => {
    try {
      await ApiService.get(`/clientes/cliente/${clienteId}`).then(
        (response) => {
          if (response.status === 200) {
            const { nome } = response.data;
            setClienteNome(nome);
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getImoveis();
    if (!location?.state?.nomeCliente) {
      getClienteNome();
    }
  }, [clienteId, unidadeId, page, perPage, searchTerm, sortBy, sortOrder]);

  const handleCheckboxChange = (imovelId) => {
    const updatedImoveis = imoveis.map((imovel) =>
      imovel.id === imovelId
        ? { ...imovel, isSelected: !imovel.isSelected }
        : imovel
    );

    setImoveis(updatedImoveis);

    setSelectedImoveisIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(imovelId)) {
        return prevSelectedIds.filter((id) => id !== imovelId);
      } else {
        return [...prevSelectedIds, imovelId];
      }
    });

    setMostrarBotaoDeletarMuitos(selectedImoveisIds.length > 0);
    setTodosImoveisSelecionados(false);
  };

  const columns = [
    {
      name: "selecionar",
      label: "Selecionar",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const imovelId = tableMeta.rowData[1];
          const imovel = imoveis.find((imovel) => imovel.id === imovelId);
          console.log(imovel);
          return (
            <Checkbox
              checked={imovel.isSelected}
              onChange={() => handleCheckboxChange(imovelId)}
            />
          );
        },
      },
    },
    {
      name: "id",
      label: "Id",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "descricao",
      label: "Nome / Descrição",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "action",
      label: "Ações",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const imovelId = tableMeta.rowData[1];
          return (
            <Box display={"flex"} gap={"1rem"}>
              <Tooltip title="Editar Imóvel">
                <EditIcon
                  cursor={permissionUpdate ? "pointer" : "not-allowed"}
                  color="info"
                  style={{ opacity: permissionUpdate ? 1 : 0.5 }}
                  disabled={!permission}
                  onClick={() => {
                    navigate(
                      `/app/cliente/${clienteId}/unidade/${unidadeId}/imovel/${imovelId}/editar`,
                      {
                        state: {
                          nomeUnidade:
                            location?.state?.nomeUnidade ??
                            imoveis[0]?.unidade?.nomeUnidade,
                          clienteNome: clienteNome,
                        },
                      }
                    );
                  }}
                />
              </Tooltip>
              <Tooltip title="Excluir Imóvel">
                <DeleteIcon
                  cursor={permissionDelete ? "pointer" : "not-allowed"}
                  style={{ opacity: permissionDelete ? 1 : 0.5 }}
                  color="error"
                  onClick={() => {
                    navigate(
                      `/app/cliente/${clienteId}/unidade/${unidadeId}/imovel/${imovelId}/deletar`,
                      {
                        clienteNome: clienteNome,
                      }
                    );
                  }}
                />
              </Tooltip>
            </Box>
          );
        },
      },
    },
  ];

  const options = {
    changeRowsPerPage: perPage,
    filterType: "dropdown",
    filter: false,
    selectableRows: "none",
    searchAlwaysOpen: true,
    searchable: true,
    serverSide: true,
    page: page,
    count: count,
    selectToolbarPlacement: "above",

    onTableChange: (action, tableState) => {
      console.log(action);
      switch (action) {
        case "changePage":
          setPage(tableState.page);
          break;
        case "sort":
          setSortBy(tableState.sortOrder.name);
          setSortOrder(tableState.sortOrder.direction);
          break;
        case "search":
          handleSearch(tableState.searchText);
          break;
        case "changeRowsPerPage":
          setPerPage(tableState.rowsPerPage);
          break;
        default:
          console.log("action not handled.");
      }
    },
    customToolbar: ({ displayData }) => (
      <>
        {selectedImoveisIds.length > 0 ? (
          <Toolbar>
            <Box
              display={"flex"}
              gap={"1rem"}
              justifyContent={"flex-end"}
              width={"100%"}
            ></Box>
          </Toolbar>
        ) : null}
      </>
    ),
    textLabels: {
      body: {
        noMatch: "Nenhum resultado encontrado",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
      },
      pagination: {
        next: "Próxima Página",
        previous: "Página Anterior",
        rowsPerPage: "Itens por Página:",
        displayRows: "of",
      },
    },
  };

  const getBreadCrumbsInfo = async () => {
    try {
      return await ApiService.get(
        `/clientes/breadcrumbs?clienteId=${clienteId}&unidadeId=${unidadeId}`
      ).then((response) => {
        if (response.status === 200) {
          setBreadCrumbsInfo(response.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBreadCrumbsInfo();
  }, [unidadeId]);

  return (
    <Box>
      {breadCrumbsInfo ? (
        <>
          <BackNavigation
            url={`/app/cliente/unidades/${clienteId}`}
          />
          <Tag
            label={breadCrumbsInfo?.unidades[0]?.nomeUnidade}
            sx={{ marginBottom: 3 }}
          />
        </>
      ) : null}

      <JumboContentLayout
        header={
          <PageHeader
            title={"Imóveis"}
            icon="desc"
            btn={
              <ActionButton
                to={
                  permission
                    ? `/app/cliente/${clienteId}/unidade/${unidadeId}/imoveis/novo`
                    : null
                }
                disabled={!permission}
                onClick={() => {
                  navigate(
                    `/app/cliente/${clienteId}/unidade/${unidadeId}/imoveis/novo`,
                    {
                      state: {
                        nomeCliente:
                          location?.state?.nomeCliente ??
                          imoveis[0]?.cliente?.nome,
                        nomeUnidade:
                          location?.state?.nomeUnidade ??
                          imoveis[0]?.unidade?.nomeUnidade,
                      },
                    }
                  );
                }}
                title="Novo imóvel"
                color="green"
                icon={<QueueIcon />}
              />
            }
          />
        }
        layoutOptions={layoutOptions}
      >
        <Div
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            <TextField
              variant="standard"
              placeholder="Pesquisar..."
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSearch(inputValue);
                }
              }}
              InputProps={{
                sx: {
                  borderRadius: 2,
                  border: "1px solid #4E5526",
                  paddingLeft: 2,
                  height: "36px",
                  "&:hover": { borderColor: "#3B4220" },
                  "&.Mui-focused": { borderColor: "#4E5526" },
                  color: "#4E5526",
                  "&::placeholder": { color: "red", opacity: 1 },
                  width: 350,
                },
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      sx={{
                        backgroundColor: "#4E5526",
                        borderRadius: 2,
                        minWidth: "36px",
                        height: "36px",
                        padding: 0,
                        "&:hover": { backgroundColor: "#3B4220" },
                      }}
                      onClick={() => handleSearch(inputValue)}
                    >
                      <SearchIcon sx={{ color: "#fff", fontSize: 20 }} />
                    </Button>
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
            {selectedImoveisIds.length > 0 && permissionDelete && (
              <Button
                variant="contained"
                color="error"
                startIcon={<DeleteIcon />}
                onClick={handleDeleteMuitosImoveis}
                sx={{
                  borderRadius: 2,
                  paddingX: 2,
                  backgroundColor: "#C84E4D",
                  textTransform: "none",
                }}
              >
                Excluir {selectedImoveisIds.length}{" "}
                {selectedImoveisIds.length > 1 ? "imóveis" : "imóvel"}
              </Button>
            )}
            <BotoesExportarDados
              dados_planilha={imoveis}
              nome_arquivo_planilha={`imoveis_unidade_${breadCrumbsInfo?.nome}_`}
              titulo={`Imóveis do Cliente ${breadCrumbsInfo?.nome}`}
              colunas_planilha={["id", "descricao", "createdAt"]}
            />
          </Box>
        </Div>

        <Card
          sx={{
            p: 2,
            width: "100%",
            minHeight: 100,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "none",
            backgroundColor: "transparent",
          }}
        >
          <Grid container justifyContent="space-between" alignItems="center" p={2}>
            <Grid item lg={0.5}>
              <Checkbox
                sx={{
                  color: "#696969",
                  "&.Mui-checked": {
                    color: "#95A64A",
                  },
                }}
                onChange={handleSelecionarTodosImoveis}
              />
            </Grid>
            <Grid item lg={10}>
              <Box>
                <Typography fontSize={24} fontWeight={600}>
                  Nome/Descrição
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={1}>
              <Box>
                <Typography fontSize={24} fontWeight={600}>
                  Ações
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Card>

        <React.Fragment>
          <Grid container gap={1}>
            {imoveis.length > 0 ? (
              <>
                {imoveis.map((i, key) => (
                  <Card
                    sx={{
                      p: 4,
                      width: "100%",
                      minHeight: 100,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    key={key}
                  >
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      gap={2}
                    >
                      <Grid item lg={0.5}>
                        <Checkbox
                          sx={{
                            color: "#696969",
                            "&.Mui-checked": {
                              color: "#95A64A",
                            },
                          }}
                          key={i.id}
                          checked={i.isSelected}
                          onChange={() => handleCheckboxChange(i.id)}
                        />
                      </Grid>
                      <Grid item lg={10}>
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                            textAlign: "left",
                          }}
                        >
                          <Typography fontSize={18}>{i.descricao}</Typography>
                        </Box>
                      </Grid>

                      <Grid item lg={1}>
                        <Box display={"flex"} gap={1}>
                          <Tooltip title="Editar Imóvel">
                            <EditIcon
                              cursor={
                                permissionUpdate ? "pointer" : "not-allowed"
                              }
                              // color="info"
                              style={{
                                opacity: permissionUpdate ? 1 : 0.5,
                                color: "#525252",
                              }}
                              disabled={!permission}
                              onClick={() => {
                                navigate(
                                  `/app/cliente/${clienteId}/unidade/${unidadeId}/imovel/${i.id}/editar`,
                                  {
                                    state: {
                                      nomeUnidade:
                                        location?.state?.nomeUnidade ??
                                        imoveis[0]?.unidade?.nomeUnidade,
                                      clienteNome: clienteNome,
                                    },
                                  }
                                );
                              }}
                            />
                          </Tooltip>
                          <Tooltip title="Excluir Imóvel">
                            <DeleteIcon
                              cursor={
                                permissionDelete ? "pointer" : "not-allowed"
                              }
                              style={{
                                opacity: permissionDelete ? 1 : 0.5,
                                color: "#C84E4D",
                              }}
                              // color="error"
                              onClick={() => {
                                navigate(
                                  `/app/cliente/${clienteId}/unidade/${unidadeId}/imovel/${i.id}/deletar`,
                                  {
                                    clienteNome: clienteNome,
                                  }
                                );
                              }}
                            />
                          </Tooltip>
                        </Box>
                      </Grid>
                    </Grid>
                  </Card>
                ))}
              </>
            ) : (
              <Grid container>
                <Grid item style={{ width: "100%" }}>
                  <Box my={5} textAlign={"center"}>
                    <Typography>Nenhum imóvel cadastrado.</Typography>
                  </Box>
                </Grid>
              </Grid>
            )}

            <PaginationComponent
              page={page}
              setPage={setPage}
              count={count}
              perPage={perPage}
              setPerPage={setPerPage}
            />
          </Grid>
        </React.Fragment>
      </JumboContentLayout>
    </Box>
  );
};

export default ImoveisCliente;
