import React, {
  useState,
  useCallback,
  useEffect,
  useContext,
  useRef,
} from "react";
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import {
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  Select,
  Avatar,
  Box,
  Button,
  IconButton,
  FormGroup,
  FormLabel,
  FormControlLabel,
  Checkbox
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import * as yup from "yup";
import { ErrorMessage, Form, Formik } from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { ApiService } from "app/services/config";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { updateInputValue } from "../../utils/appHelpers";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import HeaderBreadcrumbs from "app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs";
import makeAnimated from "react-select/animated";
import ReactInputMask from "react-input-mask";
import { Delete, PhotoCamera } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import { PermissionContext } from "app/contexts/PermissionContext";
import { users } from "app/shared/widgets/ProjectsListCard/data";

import DescriptionIcon from "@mui/icons-material/Description";
import InputAmbiental from "@jumbo/components/InputAmbiental";
import ActionButton from "app/components/ActionButton/ActionButton";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import PageHeader from "app/layouts/shared/headers/PageHeader/PageHeader";
import BotaoVoltarBreadCrumbs from "app/components/BotaoVoltarBreadCrumbs";
import SelectAmbiental from "@jumbo/components/SelectAmbiental";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "transparent" : "white",
    border: state.isFocused
      ? "1px solid #005D5F"
      : "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "4px",
    padding: "8px",
    boxShadow: "none",
    "&:hover": {
      borderColor: "black",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#005D5F" : "white",
    color: state.isSelected ? "white" : "black",
    "&:hover": {
      backgroundColor: "lightblue",
      color: "white",
    },
  }),
};

const validationSchema = yup.object().shape({
  name: yup
    .string("Insira o nome da área")
    .required("Preenchimento obrigatório"),
  email: yup
    .string("Insira um email válido")
    .email("Email inválido")
    .required("Preenchimento obrigatório"),
  telephone: yup.string("Insira um número de telefone válido").nullable(),
  password: yup.string().required("Preenchimento obrigatório"),
  passwordConfirm: yup
    .string()
    .oneOf([yup.ref("password"), null], "As senhas devem ser iguais")
    .required("Preenchimento obrigatório"),
  avatarUrl: yup.string().nullable(),
  userType: yup
    .number("Campo obrigatório")
    .required("Campo obrigatório")
    .typeError("Selecione um tipo de usuário"),
});

const validationSchemaEdit = yup.object().shape({
  name: yup
    .string("Insira o nome da área")
    .required("Preenchimento obrigatório"),
  email: yup
    .string("Insira um email válido")
    .email("Email inválido")
    .required("Preenchimento obrigatório"),
  telephone: yup.string("Insira um número de telefone válido").nullable(),
  avatarUrl: yup.string().nullable(),
  password: yup.string().nullable(),
  passwordConfirm: yup.string().when("password", {
    is: (password) => !!password,
    then: yup
      .string()
      .oneOf([yup.ref("password"), null], "As senhas devem ser iguais")
      .required("Preenchimento obrigatório quando a senha é fornecida"),
    otherwise: yup.string().nullable(),
  }),
});

const UserForm = () => {
  const initialValues = {
    name: "",
    email: "",
    password: "",
    passwordConfirm: "",
    telephone: "",
    tipo_telefone: "",
    userType: "",
  };
  const { id } = useParams();
  const USER_ID = Number(localStorage.getItem("id"));
  const ROLE_ID = Number(localStorage.getItem("role_id"));
  const [user, setUser] = useState(initialValues);
  const [previewFotoPerfil, setPreviewFotoPerfil] = useState(null);
  const [erroUploadFotoPerfil, setErroUploadFotoPerfil] = useState({
    status: false,
    mensagem: "",
  });
  const [roles, setRoles] = useState([]);
  const [hideRole, setHideRole] = useState([]);
  const [activitiesSelected, setActivitiesSelected] = useState("selecionar");
  const [telefoneComMascara, setTelefoneComMascara] = useState("");
  const [
    isUsuarioComPermissaoParaEditarSenha,
    setIsUsuarioComPermissaoParaEditarSenha,
  ] = useState(false);
  const [perfisQueOUsuarioPodeAdministrar, setPerfisQueOUsuarioPodeAdministrar] = useState([]);
  const animatedComponents = makeAnimated();
  const Swal = useSwalWrapper();
  const navigate = useNavigate();

  const { theme } = useJumboTheme();

  const { hasPermission } = useContext(PermissionContext);

  const isUsuarioDonoDoPerfil = Number(id) === USER_ID;

  const formikRef = useRef();

  if (id) {
    if (!hasPermission("Colaboradores", "update") && Number(id) !== USER_ID) {
      navigate("/app/listar-colaboradores");
    }
  } else {
    if (!hasPermission("Colaboradores", "create")) {
      navigate("/app/listar-colaboradores");
    }
  }

  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            zIndex: 5,
            top: 96,
            minHeight: "auto",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: "flex-start",
        },
      },
    }),
    [theme]
  );

  const toast = (variant, message, type = false) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true, // botão de fechar
      closeButtonAriaLabel: "Fechar",
      timer: 3000,
    });
  };

  const getUsers = useCallback(async () => {
    try {
      await ApiService.get(`/users/${id}`)
        .then((response) => {
          const transformedUser = {
            ...response.data,
            password: undefined,
          };

          setUser(transformedUser);
          setTelefoneComMascara(response.data.telephone);
          if (response.data.avatarUrl) {
            setPreviewFotoPerfil(
              `${process.env.REACT_APP_API_KEY}/public/uploads/${response.data.avatarUrl}`
            );
          }

          setActivitiesSelected(response.data.roles[0].role_id);

          if(perfisQueOUsuarioPodeAdministrar?.length > 0 && roles?.length > 0) {
            setHideRole(roles?.filter(role => !perfisQueOUsuarioPodeAdministrar.includes(role.id)))
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, [roles]);

  const getRoles = useCallback(async () => {
    try {
      await ApiService.get(`/roles/perfis-administrados`)
        .then((response) => {
          const roles = response.data;
          setRoles(roles?.perfis_que_administra);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);



  const modalAlert = () => {
    Swal.fire({
      title: "Tem certeza que deseja apagar?",
      text: "Não será póssível reverter a ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        handleRemoveFotoPerfil();
      }
    });
  };

  const handlePreviewFotoPerfil = async (event) => {
    event.preventDefault();
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const reader = new FileReader();

      if (
        selectedFile.type !== "image/jpeg" &&
        selectedFile.type !== "image/png"
      ) {
        setPreviewFotoPerfil(null);
        setErroUploadFotoPerfil({
          status: true,
          mensagem: "Formato de imagem inválido. Só é permitido .jpeg ou .png",
        });
        console.log("Tipo de imagem inválida", selectedFile.type);
        return;
      } else {
        setErroUploadFotoPerfil({
          status: false,
          mensagem: "",
        });

        reader.onloadend = () => {
          setPreviewFotoPerfil(reader.result);
        };

        reader.readAsDataURL(selectedFile);
      }
    }
  };

  const handleUploadImagemPerfil = async (event) => {
    const fotoPerfil = event.target.files[0];
    const formData = new FormData();
    formData.append("foto", fotoPerfil);
    try {
      await ApiService.put(`users/upload/foto/perfil/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        if (response.status === 200) {
          toast("success", "Foto de perfil atualizada!");
          setUser((prevUser) => ({
            ...prevUser,
            avatarUrl: response.data.avatarUrl,
          }));
          localStorage.setItem("avatarUrl", response.data.avatarUrl);
        }
      });
    } catch (error) {
      console.error("Erro ao fazer upload da imagem: ", error);
    }
  };

  const handleRemoveFotoPerfil = async () => {
    setPreviewFotoPerfil(null);
    setErroUploadFotoPerfil({
      status: false,
      mensagem: "",
    });
    try {
      await ApiService.put(`/users/foto/remover/${id}`).then((response) => {
        if (response.status === 200) {
          toast("success", "Foto removida com sucesso!");
          setPreviewFotoPerfil(null);
          setUser((prevUser) => ({
            ...prevUser,
            avatarUrl: null,
          }));
        }
      });
    } catch (error) {
      console.log(error);
      toast("error", "Erro ao remover a imagem de perfil");
    }
  };

  useEffect(() => {
    getRoles();
  }, []); // Chamado apenas uma vez quando o componente for montado

  useEffect(() => {
    if (roles.length > 0 && id) {
      // Verifica se roles foi atualizado
      getUsers();
    }
  }, [roles, id]);


  useEffect(() => {
    if (user && user?.roles?.length > 0) {
      if (isUsuarioDonoDoPerfil || perfisQueOUsuarioPodeAdministrar.includes(ROLE_ID)) {
        setIsUsuarioComPermissaoParaEditarSenha(true);
      } 
    }
  }, [user, perfisQueOUsuarioPodeAdministrar]);

  const getPerfisQueOUsuarioPodeAdministrar = async () => {
    try {
      await ApiService.get('/roles/perfis-administrados').then((response => {
        if(response.status === 200) {
          const { perfis_que_administra } = response.data;
          if(perfis_que_administra.length > 0) {
            const ids = perfis_que_administra?.map(perfil => perfil.id);
            setPerfisQueOUsuarioPodeAdministrar(ids);
          }
        }
      }))
    } catch (error) {
      console.lg(error);
    }
  }

  const handleSubmit = async (
    values,
    setSubmitting,
    resetForm,
    setFieldValue
  ) => {
    const allValuesEdit = { ...values, userType: activitiesSelected };
    delete allValuesEdit.servicoId;
    delete allValuesEdit.dados_cliente;
    if (Object.entries(formikRef.current.errors)?.length > 0) {
      toast("warning", "Corrija os erros no formulário!");
      return;
    }
    if (values.userType === "selecionar") {
      toast("warning", "Selecione um tipo de usuário!");
      return;
    }
    if (id) {
      ApiService.put(`/users/${id}`, { values: allValuesEdit })
        .then((response) => {
          if (response.status === 200) {
            toast("success", "Atualizado com sucesso");
            resetForm();
            navigate("/app/listar-colaboradores");
          }
        })
        .catch((error) => {
          let message = error.response.data.message;
          toast("error", message);

          if (error.response.data) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } else {
      await ApiService.post("/users", { values: allValuesEdit })
        .then((response) => {
          if (response.status === 201) {
            toast("success", "Criado com sucesso");
            resetForm();
            navigate("/app/listar-colaboradores");
          }
        })
        .catch((error) => {
          let message = error.response.data.message;
          toast("error", message);

          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    }

    setSubmitting(false);
  };

  useEffect(() => {
    setErroUploadFotoPerfil(erroUploadFotoPerfil);
  }, [erroUploadFotoPerfil]);

  useEffect(() => {
    getPerfisQueOUsuarioPodeAdministrar();
  }, [])

  return (
    <Box sx={{display: "relative"}}>
      <JumboContentLayout layoutOptions={layoutOptions}>
        <BotaoVoltarBreadCrumbs
          handleBack={() => {
            navigate(`/app/listar-colaboradores`);
          }}
        />
        <PageHeader
          title={
            id && user
              ? `${user?.name} > Editar`
              : "Colaboradores > Cadastro de Colaboradores"
          }
          icon={
            <DescriptionIcon
              sx={{ color: "#4E5526", marginRight: 1, fontSize: 40 }}
            />
          }
        />
        {lg && (
          <Stack spacing={2} direction={"row"} sx={{ mb: 3, mt: -2 }}></Stack>
        )}
        <Formik
          initialValues={user}
          validationSchema={id ? validationSchemaEdit : validationSchema}
          enableReinitialize
          validateOnChange={false}
          validateOnBlur
          onSubmit={handleSubmit}
          innerRef={formikRef}
        >
          {({
            values,
            isSubmitting,
            setFieldValue,
            setSubmitting,
            errors,
            resetForm,
            handleBlur,
            handleChange,
            setFieldTouched,
            touched,
          }) => (
            <Form
              style={{
                width: "100%",
                minHeight: "60vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
              noValidate
              autoComplete="off"
            >
              <Box>
                <Grid
                  container
                  spacing={2}
                  padding={0}
                  alignContent={"center"}
                  // justifyContent={"center"}
                  display={"flex"}
                  width={"100%"}
                >
                  {id ? (
                    <Grid item xs={2} marginTop={-3}>
                      <form
                        style={{
                          display: "flex",
                          height: "auto",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        <Box
                          width={"max-content"}
                          display={"flex"}
                          flexDirection={"column"}
                          alignItems={"center"}
                        >
                          <Avatar
                            alt="Foto do perfil"
                            src={
                              user.avatarUrl
                                ? `${process.env.REACT_APP_API_KEY}/public/uploads/${user.avatarUrl}`
                                : previewFotoPerfil
                            }
                            sx={{
                              width: 140,
                              height: 140,
                              marginTop: 1,
                              bgcolor: "success",
                            }}
                          >
                            {user.name
                              ? user.name.charAt(0).toUpperCase()
                              : "P"}
                          </Avatar>
                        </Box>
                        <Box
                          width={"max-content"}
                          display={"flex"}
                          marginTop={1}
                          justifyContent={"center"}
                          gap={1}
                        >
                          <IconButton
                            color="info"
                            aria-label="upload picture"
                            component="label"
                          >
                            <input
                              name="foto"
                              hidden
                              accept="image/png, image/jpg, image/jpeg"
                              type="file"
                              onChange={(event) => {
                                handlePreviewFotoPerfil(event);
                                handleUploadImagemPerfil(event);
                              }}
                            />
                            <PhotoCamera />
                          </IconButton>
                          {(previewFotoPerfil || user?.avatarUrl) && (
                            <>
                              <IconButton onClick={modalAlert}>
                                <Delete color="error" />
                              </IconButton>
                            </>
                          )}
                        </Box>
                        {erroUploadFotoPerfil.status && (
                          <Box sx={{ textAlign: "center" }}>
                            <Typography color="error">
                              {erroUploadFotoPerfil.mensagem}
                            </Typography>
                          </Box>
                        )}
                      </form>
                    </Grid>
                  ) : null}

                  <Grid item xs={id ? 4 : 6}>
                    <InputAmbiental
                      label={"Nome"}
                      required={true}
                      name="name"
                      onChange={handleChange}
                      handleBlur={(e) => {
                        handleBlur(e);
                        setFieldTouched("name", true);
                      }}
                      value={values.name}
                      fullWidth={true}
                    />
                    {touched.name && errors.name && (
                      <div style={{ color: "red" }}>{errors.name}</div>
                    )}
                  </Grid>
                  {(
                    perfisQueOUsuarioPodeAdministrar?.includes(user?.roles?.[0]?.role_id)) && (
                    <Grid item xs={6}>
                      <SelectAmbiental
                        value={activitiesSelected ? activitiesSelected : 1}
                        onChange={(event) => {
                          setActivitiesSelected(Number(event.target.value));
                          setFieldValue("userType", event.target.value);
                        }}
                        onBlur={(e) => {
                          handleBlur(e);
                          setFieldTouched("name", true);
                        }}
                        fullWidth={true}
                        name={"userType"}
                        label={"Tipo de usuário"}
                        options={[
                          ...roles,
                          {
                            id: "selecionar",
                            name: "Selecionar",
                          },
                        ]}
                        nameOptionDescription={"name"}
                        nameOptionValue={"id"}
                      />
                      {touched.userType && errors.userType && (
                        <div style={{ color: "red" }}>{errors.userType}</div>
                      )}
                    </Grid>
                  )}
                  <Grid item xs={6}>
                    <InputAmbiental
                      label={"Email"}
                      required={true}
                      name="email"
                      onChange={handleChange}
                      handleBlur={(e) => {
                        handleBlur(e);
                        setFieldTouched("email", true);
                      }}
                      value={updateInputValue(user, values, "email")}
                      fullWidth={true}
                      type="email"
                      isRegisteredEmail={true}
                    />
                    {touched.email && errors.email && (
                      <div style={{ color: "red" }}>{errors.email}</div>
                    )}
                  </Grid> 
                  {(isUsuarioComPermissaoParaEditarSenha || !id) && (
                    <>
                      <Grid item xs={3}>
                        <InputAmbiental
                          name={"password"}
                          label={"Senha"}
                          type="password"
                          handleBlur={(e) => {
                            handleBlur(e);
                            setFieldTouched("password", true);
                          }}
                          onChange={handleChange}
                          value={values.password}
                          fullWidth={true}
                        />
                        {touched.password && errors.password && (
                          <div style={{ color: "red" }}>{errors.password}</div>
                        )}
                      </Grid>
                      <Grid item xs={3}>
                        <InputAmbiental
                          name={"passwordConfirm"}
                          label={"Confirmação de senha"}
                          type="password"
                          handleBlur={(e) => {
                            handleBlur(e);
                            setFieldTouched("passwordConfirm", true);
                          }}
                          onChange={handleChange}
                          value={values.passwordConfirm}
                          fullWidth={true}
                        />
                        {touched.passwordConfirm && errors.passwordConfirm && (
                          <div style={{ color: "red" }}>
                            {errors.passwordConfirm}
                          </div>
                        )}
                      </Grid>
                    </>
                  )}

                  <Grid item xs={3}>
                    <FormGroup>
                      <FormLabel
                        id="tipo-telefone-label"
                        style={{ color: "#000000", fontWeight: "bold" }}
                      >
                        Tipo de telefone
                      </FormLabel>
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.tipo_telefone?.includes(
                                "celular"
                              )}
                              onChange={(e) => {
                                const { value, checked } = e.target;
                                setFieldValue(
                                  "tipo_telefone",
                                  checked ? value : values.tipo_telefone
                                );
                              }}
                              onBlur={(e) => {
                                handleBlur(e);
                                setFieldTouched("tipo_telefone", true);
                              }}
                              name="tipo_telefone"
                              value="celular"
                              color="success"
                              // disabled={isDeleteMode}
                            />
                          }
                          label="Celular"
                        />

                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.tipo_telefone?.includes(
                                "fixo"
                              )}
                              onChange={(e) => {
                                const { value, checked } = e.target;
                                setFieldValue(
                                  "tipo_telefone",
                                  checked ? value : values.tipo_telefone
                                );
                              }}
                              onBlur={(e) => {
                                handleBlur(e);
                                setFieldTouched("tipo_telefone", true);
                              }}
                              name="tipo_telefone"
                              value="fixo"
                              color="success"
                              // disabled={isDeleteMode}
                            />
                          }
                          label="Telefone fixo"
                        />
                      </FormGroup>
                      {touched.tipo_telefone && errors.tipo_telefone && (
                        <div style={{ color: "red" }}>
                          {errors.tipo_telefone}
                        </div>
                      )}
                    </FormGroup>
                  </Grid>

                  <Grid item xs={3}>
                    <InputAmbiental
                      name={"telephone"}
                      label={"Telefone"}
                      hasMask={true}
                      maskType={values.tipo_telefone}
                      handleBlur={(e) => {
                        handleBlur(e);
                        setFieldTouched("telephone", true);
                      }}
                      onChange={handleChange}
                      value={values.telephone}
                      fullWidth={true}
                    />
                    {touched.telephone && errors.telephone && (
                      <div style={{ color: "red" }}>{errors.telephone}</div>
                    )}
                  </Grid>
                </Grid>
                  
              </Box>
              <Box
                width={"100%"}
                display={"flex"}
                alignItems={"flex-end"}
                justifyContent={"flex-end"}
                my={2}
                sx={{
                  position: "sticky",
                  right: 0,
                  bottom: 10,
                }}
              >
                <ActionButton
                  title={id ? "Atualizar" : "Criar"}
                  to={null}
                  color="blue"
                  icon={<BorderColorIcon />}
                  isSubmitAction={true}
                  action={() => {
                    handleSubmit(
                      values,
                      setSubmitting,
                      resetForm,
                      setFieldValue
                    );
                  }}
                />
              </Box>
            </Form>
          )}
        </Formik>
      </JumboContentLayout>
    </Box>
  );
};
     
export default UserForm;
