import { Button, CircularProgress } from "@mui/material";
import React, { useState } from "react";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const SelectPdf = ({ handleExportarContratosParaPDF, isLoading }) => {
  const [showOptions, setShowOptions] = useState(false);

  const pdfFiles = [
    { id: 1, name: "Todos os contratos", label: "todos" },
    { id: 2, name: "Contratos Padrão", label: "padrao" },
    { id: 3, name: "Contratos Fixos", label: "fixo" },
    { id: 4, name: "Contratos de Renovação", label: "renovacao" },
  ];

  const toggleOptions = () => {
    setShowOptions(!showOptions);
  };

  const handleSelectOption = (file) => {
    setShowOptions(false);
    handleExportarContratosParaPDF(file.label);
  };

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        justifyContent: "flex-end",
      }}
    >
      <Button
        variant="contained"
        color="error"
        startIcon={<PictureAsPdfIcon />}
        onClick={toggleOptions}
        sx={{
          borderRadius: 2,
          paddingX: 2,
          backgroundColor: "#CA0606",
        }}
      >
        {isLoading ? (
          <CircularProgress size="20px" sx={{ color: "white" }} />
        ) : (
          <ArrowDropDownIcon sx={{ color: "#FFFFFF" }} />
        )}
      </Button>

      {showOptions && (
        <ul
          style={{
            position: "absolute",
            top: "100%",
            left: -95,
            width: "max-content",
            textAlign: "left",
            margin: "0",
            padding: "10px 0",
            listStyle: "none",
            backgroundColor: "#fff",
            border: "1px solid #ccc",
            borderRadius: "5px",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            zIndex: 10,
          }}
        >
          {pdfFiles.map((file) => (
            <li
              key={file.id}
              onClick={() => handleSelectOption(file)}
              style={{
                padding: "10px",
                cursor: "pointer",
                color: "#CA0606",
                transition: "background-color 0.2s",
              }}
              onMouseEnter={(e) => (e.target.style.backgroundColor = "#f1f1f1")}
              onMouseLeave={(e) =>
                (e.target.style.backgroundColor = "transparent")
              }
            >
              {file.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SelectPdf;
